import React, { PureComponent } from 'react';
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    AreaChart,
    Area,
    ResponsiveContainer,
} from 'recharts';

export const AreaCharts = ({ dataKeyXaxis, dataKeyArea, chartData, customToolTip }) => {

    return (
        <div style={{ width: '100%' }}>
            <ResponsiveContainer width="100%" height={293}>
                <AreaChart
                    width={500}
                    height={200}
                    data={chartData}
                    syncId="anyId"
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={dataKeyXaxis} />
                    <YAxis tickFormatter={(value) => value.toLocaleString('en-IN')} />
                    <Tooltip content={customToolTip} />
                    <Area type="monotone" dataKey={dataKeyArea.value} stroke="#4169E1" fill="#4169E1" name={dataKeyArea.label} />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
}

