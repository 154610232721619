import axios from "axios";
import API_CONFIG from "../../Configurations/ApiDeclaration";

export function GetUnprocessedReportList(params, token) {
    const URL = API_CONFIG.GET_UNPROCESSED_REPORTS + params;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetConsolidatedReportList(params, token) {
    const URL = API_CONFIG.GET_CONSOLIDATED_REPORTS + params;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function DownloadConsolidatedReportList(params, token) {
    const URL = API_CONFIG.DOWNLOAD_COSOLIDATED_REPORTS + params;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function DownloadUnprocssedReportList(params, token) {
    const URL = API_CONFIG.DOWNLOAD_UNPROCESSED_REPORT + params;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetCustomReportList(params, token) {
    const URL = API_CONFIG.GET_CUSTOM_REPORTS + params;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function CreateCustomReport(data, token) {
    const URL = API_CONFIG.CREATE_CUSTOM_REPORTS;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, data, headers);
}
export function CreateCustomReconReport(data, token) {
    const URL = API_CONFIG.CREATE_CUSTOM_RECON_REPORTS;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, data, headers);
}
export function RefreshUnprocssedReportList(params, token) {
    const URL = API_CONFIG.REFRESH_COSOLIDATED_REPORTS + params;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetStoreSales(params, token) {
    const URL = API_CONFIG.GET_STORE_SALES + params;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function DownloadStockReports(params, token) {
    const URL = API_CONFIG.DOWNLAOD_STOCK_COSOLIDATED_REPORTS + params;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function RefreshReconReports(params, token) {
    const URL = API_CONFIG.REFRESH_RECON_REPORTS + params;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetSalesYear(token) {
    const URL = API_CONFIG.GET_SALES_YEAR;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetSalesDashboardData(param, token) {
    const URL = API_CONFIG.GET_SALES_DASHBOARD_DATA + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function GetSalesReconData(param, token) {
    const URL = API_CONFIG.DOWNLOAD_SALES_RECON_DATA + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function DownloadSalesDashboardReport(param, token) {
    const URL = API_CONFIG.DOWNLOAD_SALES_DASHBOARD_DATA + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function getSalesFilterValue(param, token) {
    const URL = API_CONFIG.GET_FILTER_DATA_FOR_SALES + param;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function RunAllApis(token) {
    const URL = API_CONFIG.RUN_BACKGROUND_JOBS;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(URL, headers);
}
export function UploadSalesAndStockReport(requestBody, token) {
    const URL = API_CONFIG.UPLOAD_SALES_STOCK_REPORT;
    const headers = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(URL, requestBody, headers);
}