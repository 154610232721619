import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

const CustomBarChart = (props) => {
    const { chartData, width, height } = props;
    const maxDataValue = Math.max(...chartData.datasets[0].data) | 10;
    const stepSize = Math.ceil(maxDataValue / 5);

    const barChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed !== null) {
                            label += Math.round((context.parsed.y / context.dataset.data.reduce((a, b) => a + b, 0)) * 100) + '%';
                        }
                        return label;
                    },
                },
                backgroundColor: '#D0D5EA',
                titleColor: '#676767',
                bodyColor: '#676767',
            },
            datalabels: {
                display: true,
                color: '#000',
                font: {
                    size: 12,
                    weight: 'bold'
                },
                rotation: -90,
                anchor: 'start',
                align: 'top',
                position: 'top',
                formatter: function (value, context) {
                    if (value != 0) {
                        return Math.round((value / context.dataset.data.reduce((a, b) => a + b, 0)) * 100) + " " + '%';
                    } else {
                        return ""
                    }
                }
            },
        },
        scales: {
            x: {
                grid: {
                    display: false
                }
            },
            y: {
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                },
                ticks: {
                    stepSize: stepSize
                }
            }
        },

    }

    return (
        <div>
            <Bar data={chartData} width={width} height={height}
                options={barChartOptions} />
        </div>
    );
}

export default CustomBarChart