import React, { useState, useRef, useEffect } from 'react';
import CustomTableWithFilter from '../../../ReUsableComponent/CustomTableWithFilter';
import { salesConsolidatedTableHeader, salesCustomTableHeader, salesRecConsolidatedTableHeader, salesUnprocessedTableHeader } from '../../TableHeaders';
import '../SalesOverview.css';
import CustomFilterDropDown from '../../../ReUsableComponent/CustomFilterDropdown';
import dropDownIcon from '../../../assets/svg/dropDownIcon.svg';
import { CreateCustomReport, DownloadConsolidatedReportList, DownloadStockReports, DownloadUnprocssedReportList, GetConsolidatedReportList, GetCustomReportList, GetUnprocessedReportList, RefreshUnprocssedReportList, RunAllApis, UploadSalesAndStockReport } from '../SalesOverview.service';
import Loader from '../../../ReUsableComponent/Loader';
import moment from 'moment';
import timeMoment from 'moment-timezone';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ImportIcon from '../../../assets/svg/uploadIcon.svg';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import CustomDropzone from '../../../ReUsableComponent/CustomDropzone';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import ProgressBarForFileUpload from '../../../ReUsableComponent/ProgressBarForFileUpload';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import CloseIcon from '../../../assets/svg/closeIconofmodal.svg';
import { event } from 'jquery';
import CustomFilterSearchDropdown from '../../../ReUsableComponent/CustomFilterSearchDropdown';
import { GetBrandNameList, GetStoreCustomerNameList } from '../../RuleManagement/RuleManagement.service';
import CustomDatePicker from '../../../ReUsableComponent/DatePickerComponent';
import dayjs from 'dayjs';
import PdfIcon from '../../../assets/svg/pdf.svg';
import JPEG from '../../../assets/svg/jpeg.svg';
import DownloadIcon from '../../../assets/svg/reportsDownload.svg';
import ExcellIcon from '../../../assets/png/excellIcon.png';
import { salesActions } from '../../../store/reducers/SalesOverviewReducer';
import CustomMonthYearPicker from '../../../ReUsableComponent/CustomMonthYearPicker';
import { responseMessages } from '../../../Configurations/ResponseMessages';
import MultiSelectCheckBox from '../../../ReUsableComponent/MultiSelectCheckBox';
import InputTextField from '../../../ReUsableComponent/InputTextField';
import CustomDateRangePicker from '../../../ReUsableComponent/CustomDaterangePicker';
import { differenceInDays, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';
import { InfoOutlined } from '@mui/icons-material';

const MonthList = [{ label: 'January', value: 'January' }, { label: 'February', value: 'February' }, { label: 'March', value: 'March' }, { label: 'April', value: 'April' }, { label: 'May', value: 'May' },
{ label: 'June', value: 'June' }, { label: 'July', value: 'July' }, { label: 'August', value: 'August' }, { label: 'September', value: 'September' },
{ label: 'October', value: 'October' }, { label: 'November', value: 'November' }, { label: 'December', value: 'December' },
];

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 835,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const viewReportFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const SalesOverview = () => {
    const gridRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();

    const today = new Date();
    const [tableFilterValue, setTableFilterValue] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [anchorElForYear, setAnchorElForMonth] = React.useState(null);
    const openMonthFilter = Boolean(anchorElForYear);
    const [selectedMonth, setSelectedMonth] = React.useState({ label: 'January', value: 'January' });
    const [consolidatedReport, setConsolidatedReport] = useState([]);
    const [unprocessedReports, setUnprocessedReports] = useState([]);
    const [loading, setLoading] = useState(false);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [openUploadFileModal, setOpenUploadFileModal] = useState(false);
    const [uploadedFileSales, setUploadedFileSales] = useState([]);
    const [uploadedFileStock, setUploadedFileStock] = useState([]);
    const [selectedItem, setSelectedItem] = useState({ description: '', id: '' });
    const [viewAllInfo, setViewAllInfo] = useState(false);
    const [progress, setProgress] = useState(25);
    const [uploadSpeed, setUploadSpeed] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [brandList, setBrandList] = useState([]);
    const [anchorElForBrand, setAnchorElForBrand] = useState(null);
    const openBrandFilter = Boolean(anchorElForBrand);
    const [dropSearchValue, setdropSearchValue] = React.useState('');
    const [anchorElForStore, setAnchorElForStore] = useState(null);
    const openStoreFilter = Boolean(anchorElForStore);
    const [dropSearchValueStore, setdropSearchValueStore] = React.useState('');
    const [selectedBrand, setSelectedBrand] = useState({ label: '', value: '' });
    const [storeNameList, setStoreNameList] = useState([]);
    const [storeNameListForCustom, setStoreNameListForCustom] = useState([]);
    const [selectedDate, setSelectedDate] = useState(today);
    const [openUnprocessedReport, setOpenUnprocessedReport] = useState(false);
    const [openCustomReport, setOpenCustomReport] = useState(false);
    const [customReportData, setCustomReportData] = useState([]);
    const [inputValue, setInputValue] = useState({
        brand: [],
        store: [],
        report: 'Sales',
        service: 'good_sales',
        from_date: null,
        to_date: null
    });
    const [startDate, setStartDate] = useState(inputValue?.from_date ? new Date(inputValue?.from_date) : null);
    const [endDate, setEndDate] = useState(inputValue?.to_date ? new Date(inputValue?.to_date) : null);
    const [selectedRange, setSelectedRange] = useState([
        inputValue?.from_date ? new Date(inputValue?.from_date) : today,
        inputValue?.to_date ? new Date(inputValue?.to_date) : null,
    ]);
    const [brandNameList, setBrandNameList] = useState([]);
    const [brandNameQueryParm, setBrandNameQueryParm] = useState([]);
    const [selectedStoreName, setSelectedStoreName] = useState({ label: '', value: '' });
    const USER_ID = useSelector((state) => state.userAuthentication.userId);
    const convertDateIntoMonth = (dateString) => {
        let date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' })
        return `${month}-${year}`;
    }

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 300,
                width: 'max-content',
            },
        },
    };
    const [calendarOpen, setCalendarOpen] = useState(false);

    const [selectedMonthYear, setSelectedMonthYear] = useState(convertDateIntoMonth(new Date()));

    useEffect(() => {
        const date = new Date();
        const currentMonthName = MonthList[date.getMonth()];
        setSelectedMonth(currentMonthName);
        dispatch(salesActions.setDefaultMonthForRecon({ type: convertDateIntoMonth(new Date()) }));
        setLoading(true);
        getAllBrand();
        getStoreList('');
        getBrandList();
        getStoreListForCustom();
        customReportList();
        getUnprocessedReportList(1, convertDateIntoMonth(new Date()));
        getConsolidatedReportList(1, convertDateIntoMonth(new Date()));
    }, [])

    const fileTypeIcon = (file) => {
        if (file === 'pdf') {
            return PdfIcon
        } else if (file === 'jpeg' || file === 'jpg') {
            return JPEG
        } else {
            return ExcellIcon
        }
    }

    const getUnprocessedReportList = (page, currentMonth) => {
        let params = `?reportType=Sales&month=${currentMonth}&page=${page}&limit=25`
        let failedRecordCheck = (item) => {
            if (item.unprocessed_file_url) {
                return true;
            } else if (item.file_url) {
                return true;
            } else {
                return false;
            }
        }
        GetUnprocessedReportList(params, USER_TOKEN).then(response => {
            let dataForTable = [];
            if (response.data.statusCode === 200) {
                response.data.data.forEach(item => {
                    let row = {
                        date: convertDate(item.created_at),
                        emailId: item.sender_email,
                        failedRecord: failedRecordCheck(item),
                        failedRecordFile: item.unprocessed_file_url,
                        comment: item.comment
                    }
                    dataForTable.push(row);
                })
                setUnprocessedReports(dataForTable)
            }
        }).catch(error => {
            // if (error.response.status === 401) {
            //     history.push('/')
            // } 
            // ToastErrorService(error.response.data.message);

        }).finally(() => {
            setLoading(false);
        })
    }

    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };
    const handleStoreSearchChange = (event) => {
        setdropSearchValueStore(event.target.value);
    };
    const onClickUnprocessedReport = () => {
        setOpenUnprocessedReport(true)
    };
    const openCalendar = () => {
        setCalendarOpen(prev => !prev)
    }

    const handleDateRangeChange = (key, value, optionsList, index) => {

        const ONE_DAY = 1;
        const TWO_MONTHS = 60

        const dayDifference = startDate ? differenceInDays(value, startDate) : null;
        if (!startDate || (startDate && endDate)) {
            setStartDate(value);
            setEndDate(null);
            setSelectedRange([value, null]);
        } else if (startDate && !endDate && value >= startDate) {
            if (dayDifference >= ONE_DAY && dayDifference <= TWO_MONTHS) {
                setEndDate(value);
                setSelectedRange([startDate, value]);
                setInputValue(prevState => ({
                    ...prevState,
                    from_date: startDate,
                    to_date: value
                }));
            } else {
                ToastErrorService('Please select the date up to 60 days')
            }
        } else if (startDate && !endDate && value < startDate) {
            setStartDate(value);
            setSelectedRange([value, null]);
        }
    };

    const getAllBrand = () => {
        setLoading(true);
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element._id
                    }
                    brands.push(brandObject)
                });
                // dispatch(salesActions.setDefaultBrand({ type: brands[0]?.value }));
                setBrandList(brands);
                // setSelectedBrand({ label: brands[0]?.label, value: brands[0]?.value });
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setLoading(false);
        })
    }

    const storeTradeNameCheck = (store, tradeName) => {
        if (tradeName && tradeName !== 'null') {
            return tradeName
        } else {
            return store
        }
    }

    const getStoreList = (brand_id) => {
        let queryParams = `?brand_id=${brand_id}`;
        GetStoreCustomerNameList(queryParams, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: storeTradeNameCheck(item.customer_name, item?.store_trade_name),
                    value: item?._id,
                }
                dropdownData.push(row);
            })
            setStoreNameList(dropdownData);
        }).catch(error => {
            // if (error.response.status === 401) {
            //     history.push('/')
            // }
            // ToastErrorService(error.response.data.message);
            console.log(error)
        });
    }

    const convertDate = (createdDate) => {
        let date = new Date(createdDate);
        // Extract the date part
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // Month is zero-based, so we add 1
        let day = date.getDate();

        // Create a new date string in the format YYYY-MM-DD
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD-MMM-YYYY");
    }

    function toUTCStartOfDay(date) {
        const localMidnight = setMilliseconds(setSeconds(setMinutes(setHours(date, 0), 0), 0), 0);
        return new Date(localMidnight.getTime() - localMidnight.getTimezoneOffset() * 60000);
    }

    function toUTCEndOfDay(date) {
        const localEndOfDay = setMilliseconds(setSeconds(setMinutes(setHours(date, 23), 59), 59), 999);
        return new Date(localEndOfDay.getTime() - localEndOfDay.getTimezoneOffset() * 60000);
    }

    const generateReport = () => {
        setLoading(true);
        setOpenCustomReport(false);
        let brands = []
        inputValue.brand.forEach(item => {
            brands.push(item.brand_id)
        })
        let stores = []
        inputValue.store.forEach(item => {
            stores.push(item.store_id)
        })
        let reqBody = {
            from_date: toUTCStartOfDay(startDate),
            to_date: toUTCEndOfDay(endDate),
            brand: brands,
            store: stores,
            report: "Sales",
            service: "good_sales"
        }
        CreateCustomReport(reqBody, USER_TOKEN).then(response => {
            if (response.status === 200) {
                ToastSuccessService('We are processing the reports in background, you can check after sometime for the report.')
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setLoading(false);
        })

    }

    const convertDates = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

        return moment(dateString).format("DD MMM YYYY");
    }

    const getConsolidatedReportList = (page, currentMonth) => {
        let params = `?report_type=Sales&month=${currentMonth}&page=${page}&limit=20`
        GetConsolidatedReportList(params, USER_TOKEN).then(response => {
            let dataForTable = [];
            if (response.data.statusCode === 200) {
                response.data.data.brand_list.forEach(item => {
                    let row = {
                        brand_id: item.brand_id,
                        month: item.sale_month,
                        nameBrand: item.brand_name,
                        passRecord: item.isGoodData,
                        errorRecord: item.isBadData,
                        brandId: item?.brand_id,
                        updatedAt: convertDates(item?.file_info?.created_at)
                    }
                    dataForTable.push(row);
                })
                setConsolidatedReport(dataForTable)
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickView = (id) => {
        alert('Coming Soon!!!')
    }

    const onFilterChanged = (event) => {
        alert('Coming Soon!!!')
    };

    const onGridReady = (params) => {
        params.api.setFilterModel(tableFilterValue);
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    const unprocessedReportDownload = (fileUrl) => {
        setLoading(true);
        let param = `?file_url=${fileUrl}`
        DownloadUnprocssedReportList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                window.open(response.data.file_url, '_blank');
                ToastSuccessService('Stock report downloaded successfully.');
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickDownloadReport = (reportType, data, actionType, range) => {
        setLoading(true);
        let params = `?brand=${data.data.brandId}&report=Sales`
        if (actionType !== 'refresh') {
            if (range === 'consolidated') {
                params = params + `&view_range=${selectedMonthYear}`
            } else {
                let currentYear = new Date().getFullYear();
                params = params + `&view_range=${currentYear}`
            }
            if (reportType === 'failedRecord') {
                unprocessedReportDownload(data.data.failedRecordFile);
            } else if (reportType === 'errorRecord') {
                params = params + '&service=bad_sales';
                downloadErrorRecordFile(params);
            } else {
                params = params + '&service=good_sales';
                downloadConsolidatedReports(params);
            }
        } else {
            // let params = `&brand=${data.data.brandId}&report=Sales`;
            // let params = `?brand=${data.data.brandId}&report=Sales`
            if (range === 'consolidated') {
                params = params + `&view_range=${selectedMonthYear}`
            } else {
                let currentYear = new Date().getFullYear();
                params = params + `&view_range=${currentYear}`
            }
            if (reportType === 'failedRecord') {
                unprocessedReportDownload(data.data.failedRecordFile);
            } else if (reportType === 'errorRecord') {
                params = params + '&service=bad_sales';
            } else {
                params = params + '&service=good_sales';
            }
            RefreshUnprocssedReportList(params, USER_TOKEN).then(response => {
                if (response.data.statusCode === 200) {
                    ToastSuccessService('Reports are being refreshed, please check after sometime.');
                } else if (response.data.statusCode === 400) {
                    ToastErrorService(response.data.message);
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    history.push('/')
                }
                ToastErrorService(error.response.data.message);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const onSelectValue = (event, name) => {
        const {
            target: { value },
        } = event;
        if (name === 'store') {
            let storeObject = [];
            let storeIds = [];
            value.map(storeName => {
                const selectedStore = storeNameListForCustom?.find(store => store?.value === storeName);
                let storeSelected = {
                    store_id: selectedStore?.store_id,
                    store_trade_name: selectedStore?.store_trade_name !== 'null' ? selectedStore?.store_trade_name : selectedStore?.label,
                    store_code: selectedStore?.store_code,
                    store_name: selectedStore?.label
                }
                storeObject.push(storeSelected);
                storeIds.push(selectedStore?.store_id);
            }).filter(storeName => storeName);
            setInputValue(values => ({
                ...values, 'store': storeObject,
            }));

        } else if (name === 'brand') {
            let brandObject = [];
            let brandIdsObject = [];
            value.map(brandName => {
                const selectedBrand = brandNameList?.find(brand => brand?.value === brandName);
                let brandSelected = {
                    brand_name: selectedBrand?.label,
                    brand_id: selectedBrand?.brand_id
                }
                brandObject.push(brandSelected);
                brandIdsObject.push(brandSelected?.brand_id);
            }).filter(brand => brand);
            setInputValue(values => ({
                ...values, 'brand': brandObject, store: []
            }));
            setBrandNameQueryParm(brandIdsObject);
            getStoreListForCustom(brandIdsObject)
        }
    }

    const downloadCustomReport = (data) => {
        let param = `?id=${data.id}`
        downloadConsolidatedReports(param);
    }

    const downloadConsolidatedReports = (params) => {
        DownloadConsolidatedReportList(params, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                window.open(response.data.presignedFileUrl, '_blank');
                ToastSuccessService('Sales report downloaded successfully.');
            } else if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const displayDescription = (item, from) => {
        let strToArray = item.description.split(",");
        if (from === 'table') {
            return <>
                <div className='ps-1'>{moment.utc(item.from_date).format("DD MMM YYYY")} - {moment.utc(item.to_date).format("DD MMM YYYY")}</div>
                <ol id='table-desc'>
                    {strToArray.splice(0, 2).map(item => (<li>{item}</li>))}
                </ol>
            </>
        } else {
            return <>
                <ol id='table-desc'>
                    {strToArray.map(item => (<li>{item}</li>))}
                </ol>
            </>
        }
    }

    const onClickInfo = (id) => {
        setViewAllInfo(true);
        const selectedRow = customReportData?.find(item => item?.id === id);
        setSelectedItem(selectedRow)
    }

    const getBrandList = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.brand_name,
                    value: item?.brand_name,
                    brand_id: item?._id
                }
                dropdownData.push(row)
            })
            setBrandNameList(dropdownData);
            // dispatch(userManagementActions.setBrandList({ type: dropdownData }));

        }).catch(error => {
            console.log(error)
        });
    }
    const getStoreListForCustom = (brandNameQueryParm) => {
        let queryParams = `?brand_id=${brandNameQueryParm ? brandNameQueryParm?.join(',') : ''}`;
        GetStoreCustomerNameList(queryParams, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: `${item?.customer_name} - ${item.brand_name}`,
                    value: item?.store_code,
                    store_code: item?.store_code,
                    store_id: item?._id,
                    store_trade_name: item?.store_trade_name
                }
                dropdownData.push(row);
            })
            setStoreNameListForCustom(dropdownData);
            // dispatch(userManagementActions.setStoreList({ type: dropdownData }));
        }).catch(error => {
            console.log(error)
        });
    }

    const downloadErrorRecordFile = (param) => {
        setLoading(true);
        DownloadStockReports(param).then(response => {
            if (response.data.statusCode === 200) {
                window.open(response.data.fileUrl, '_blank');
                ToastSuccessService('Sales report downloaded successfully.');
            } else if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message);
            }
        }).catch(error => {
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickImport = () => {
        setUploadedFileStock([]);
        setUploadedFileSales([]);
        setSelectedBrand({ label: '', value: '' });
        setSelectedStoreName({ label: '', value: '' });
        setOpenUploadFileModal(true);
    }

    const handleFileDrop = (files, fileFor) => {
        if (selectedBrand?.value && selectedStoreName?.value) {
            const fileUploaded = files[0];
            if (fileFor === 'stock') {
                if (fileUploaded) {
                    setUploadedFileStock(fileUploaded);
                    setProgress(0);
                    simulateUpload();
                    uploadStockAndSalesReport(fileFor, fileUploaded);
                }
            } else {
                if (fileUploaded) {
                    setUploadedFileSales(fileUploaded);
                    setProgress(0);
                    simulateUpload();
                    uploadStockAndSalesReport(fileFor, fileUploaded);
                }
            }
        } else {
            ToastErrorService('Please select the brand and store first.')
        }
    };

    const simulateUpload = () => {
        // setUploadButtonDisabled(true);
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + 25;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000; // in seconds
                const speed = (nextProgress / elapsedTime).toFixed(2); // in % per second
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
    };

    const onSelectBrand = (event) => {
        brandList.forEach(brand => {
            if (event.target.value === brand?.value) {
                setSelectedBrand({ label: brand?.label, value: event.target.value })
                setAnchorElForBrand(null);
            }
        });
        getStoreList(event.target.value)
    }

    const removeFile = (fileOf) => {
        if (fileOf === 'stock') {
            setUploadedFileStock([]);
        } else {
            setUploadedFileSales([]);
        }
    }

    const handleBrandClick = (event) => {
        setAnchorElForBrand(event.currentTarget);
    };
    const handleBrandClose = () => {
        setAnchorElForBrand(null);
    };
    const handleStoreClick = (event) => {
        setAnchorElForStore(event.currentTarget);
    };
    const handleStoreClose = () => {
        setAnchorElForStore(null);
    };

    const onSelectStoreName = (event, value) => {
        storeNameList.forEach(store => {
            if (event.target.value === store?.value) {
                setSelectedStoreName({ label: store?.label, value: event.target.value })
                setAnchorElForStore(null);
            }
        });
    }

    const onDateSelect = (event) => {
        let date = new Date(event);
        setSelectedDate(date);
    }

    const uploadStockAndSalesReport = (fileFor, file) => {
        setLoading(true);
        const formData = new FormData;
        if (fileFor === 'stock') {
            formData.append('report_type', 'SOH');
        } else {
            formData.append('report_type', 'SLS');
        }
        formData.append('file', file);
        formData.append('brand_id', selectedBrand?.value);
        formData.append('store_id', selectedStoreName?.value);
        formData.append('report_date', timeMoment.utc(selectedDate).tz("Asia/Kolkata").format());
        UploadSalesAndStockReport(formData).then(response => {
            ToastSuccessService(`${response?.data?.message}`);
        }).catch(error => {
            if (error?.response) {
                ToastErrorService(error?.response?.data?.message);
            } else {
                ToastErrorService("Something went wrong, please try again.");
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const gotoReconReport = (data) => {
        brandList.forEach(brand => {
            if (data.data.brand_id === brand?.value) {
                dispatch(salesActions.setDefaultBrandForRecon({ type: brand }));
            }
        });
        history.push('/app/recon-report');
    }

    const customReportList = () => {
        let param = '?view_range=Custom&report_type=good_sales'
        GetCustomReportList(param).then(response => {
            let rowdata = []
            response.data.data.forEach(item => {
                let row = {
                    description: item.description,
                    id: item._id,
                    from_date: item.from_date,
                    to_date: item.to_date
                }
                rowdata.push(row)
            })
            setCustomReportData(rowdata)
        }).catch(error => {
            console.log(error)
        }).finally(() => {

        })
    }

    const onMonthSelect = (value) => {
        setSelectedMonthYear(convertDateIntoMonth(value));
        getConsolidatedReportList(1, convertDateIntoMonth(value));
        getUnprocessedReportList(1, convertDateIntoMonth(value));
        dispatch(salesActions.setDefaultMonthForRecon({ type: convertDateIntoMonth(value) }));
    }

    const onClickFetchReport = () => {
        setLoading(true);
        RunAllApis(USER_TOKEN).then(response => {
            if (response.status === 200) {
                ToastSuccessService('We are fetching the reports in background, check after sometime for new reports.')
            } else {
                ToastErrorService('Something went wrong, please try again after sometime.')
            }
        }).catch(error => {
            ToastErrorService('Something went wrong, please try again after sometime.')
            console.log(error)
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickCreateCustomReport = () => {
        setOpenCustomReport(true);
        setInputValue({
            brand: [],
            store: [],
            report: 'Sales',
            service: 'good_sales',
            from_date: null,
            to_date: null
        })
        setStartDate(null);
        setEndDate(null);
    }

    return (
        <div className='mainContainer'>
            <div className='notoSansFont d-flex justify-content-end'>
                <div className='filterMainDiv'>
                    <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={onClickImport} >
                        <label className="cursorPointer">
                            <img src={ImportIcon} alt="" />
                        </label>
                    </div>
                    <div className='position-relative'>
                        <CustomMonthYearPicker disabled={false} maxDateRange={dayjs()} value={selectedMonthYear} defaultValue={dayjs(selectedMonthYear)} onDateChange={(newValue) => onMonthSelect(newValue)} required customClass={'customeClass'} />
                        {/* <button className='d-flex statusFilter cursorPointer align-items-center justify-content-between'
                            onClick={handleMonthClick}>{selectedMonth?.label}
                            <img className={`${openMonthFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </button>
                        <CustomFilterDropDown data={MonthList} handleClose={handleMonthClose} open={openMonthFilter} anchorEl={anchorElForYear}
                            onChange={onSelectMonth} selectedValue={selectedMonth?.value} category={'Year'} /> */}
                    </div>
                </div>
            </div>
            <div className='reportTableList'>
                <div>
                    <div className='d-flex justify-content-between align-items-center montSerratFont' >
                        <div className='fontSize18 pt-3 pb-3'>Sales Consolidated Report</div>
                        <CustomBlueButton onClick={onClickUnprocessedReport} title='Unprocessed Report' />
                    </div>
                    {consolidatedReport && <div className='tableContainer reports'>
                        <CustomTableWithFilter totalRow={() => { }} gotoReconReport={gotoReconReport} isContentLeftRightFixed={false} height={450} column={salesRecConsolidatedTableHeader} tableRowData={consolidatedReport} onClickDownloadReport={onClickDownloadReport} onClickView={onClickView} onFilterChanged={onFilterChanged} onGridReady={onGridReady} filterModel={tableFilterValue} customeHeightClass={'tableCustomeHeightClass'} />
                    </div>}
                </div>
                <div>
                    <div className='montSerratFont fontSize18 d-flex justify-content-between align-items-center'>
                        <div className='py-3'>Custom Report</div>
                        <CustomBlueButton title={'Create Custom Report'} onClick={onClickCreateCustomReport} />
                    </div>
                    <div className='tableContainer reports'>
                        {/* <CustomTableWithFilter totalRow={() => { }} isContentLeftRightFixed={false} height={450} column={salesCustomTableHeader} tableRowData={customReportData} onClickDownloadReport={downloadCustomReport} onClickView={onClickView} onFilterChanged={onFilterChanged} onGridReady={onGridReady} filterModel={tableFilterValue} customeHeightClass={'tableCustomeHeightClass'} /> */}
                        <div className=''>
                            <div className='table-row table-row-header'>
                                <div className='table-header' >Description</div>
                                <div className='table-header' >Action</div>
                            </div>
                            <div className='table-cont pt-1 custom-report'>
                                {customReportData.map(item => (<div className='table-row ps-0'>
                                    <div className='table-data table-row-data custom-report' >{displayDescription(item, 'table')}</div>
                                    <div className='table-data d-flex'>
                                        <div className="h100 actionBtnContainer">
                                            <button title="Download" className={`tableActionButtons d-flex align-items-center justify-content-center passReport`}
                                                onClick={() => downloadCustomReport(item)}>
                                                <img src={DownloadIcon} alt="" />
                                            </button>
                                            <button onClick={() => onClickInfo(item.id)}>
                                                <InfoOutlined />
                                            </button>
                                        </div>
                                    </div>
                                </div>))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CustomModal classes="customeProgressModal" open={openUploadFileModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenUploadFileModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex paddingTop24 align-items-center justify-content-between'>
                        <div className='d-flex'>
                            <div>
                                <div className='position-relative max-w-200'>
                                    <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                        onClick={handleBrandClick}>
                                        <p className='text-truncate' title={selectedBrand.label}>{selectedBrand?.label ? selectedBrand?.label : 'Select Brand'}</p>
                                        <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                    </div>
                                    <CustomFilterSearchDropdown customClass='upload-file' data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                                        onChange={onSelectBrand} selectedValue={selectedBrand?.value} category={'Brand'}
                                        dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                                </div>
                            </div>
                            <div>
                                <div className='position-relative max-w-200'>
                                    <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                                        onClick={handleStoreClick}>
                                        <p className='text-truncate' title={selectedStoreName?.label}>{selectedStoreName?.label ? selectedStoreName?.label : 'Select Store'}</p>
                                        <img className={`${openStoreFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                                    </div>
                                    <CustomFilterSearchDropdown customClass='upload-file' data={storeNameList} handleClose={handleStoreClose} open={openStoreFilter} anchorEl={anchorElForStore}
                                        onChange={onSelectStoreName} selectedValue={selectedStoreName?.value} category={'Store'}
                                        dropSearchValue={dropSearchValueStore} setdropSearchValue={setdropSearchValueStore} handleSearchChange={handleStoreSearchChange} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <CustomDatePicker disabled={false} defaultValue={dayjs(selectedDate)} value={selectedDate} onDateChange={onDateSelect} required label='Select date*' />
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className='dropZoneContentContainer gap-4'>
                            <div className='w-50'>
                                {uploadedFileSales?.length < 1 ? <CustomDropzone onFileDrop={(e) => handleFileDrop(e, 'sales')} displayContent={<div className='dropZoneContent uploadedFileDropZoneContent'>
                                    <div className='fontSemibold fontSize18 textColorBlack pb-3'>Select Sales File</div>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <SecondaryButton onClick={(e) => handleFileDrop(e, 'sales')} title={<span className='fontSemiBold textColorBlue'>Choose File</span>} />
                                    </div>
                                </div>} />
                                    : <div className='uploaded-file-cont'>
                                        <div style={{ width: '100%' }}>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={fileTypeIcon(uploadedFileSales?.fileUploaded?.path?.split('.')[1])} alt="" />
                                                    </label>
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <div className='fontMedium textColorBlack fontSize16'>Sales File</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('sales')} progress={100} File={uploadedFileSales} uploadSpeed={''} notShowBrand={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='w-50'>
                                {uploadedFileStock?.length < 1 ? <CustomDropzone onFileDrop={(e) => handleFileDrop(e, 'stock')} displayContent={<div className='dropZoneContent uploadedFileDropZoneContent'>
                                    <div className='fontSemibold fontSize18 textColorBlack pb-3'>Select Stock File</div>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <SecondaryButton onClick={(e) => handleFileDrop(e, 'stock')} title={<span className='fontSemiBold textColorBlue'>Choose File</span>} />
                                    </div>
                                </div>} />
                                    : <div className='uploaded-file-cont'>
                                        <div style={{ width: '100%' }}>
                                            <div className='d-flex align-items-center'>
                                                <div className='me-3 importExportFile cursorPointer' title='Upload' onClick={null} >
                                                    <label className="cursorPointer">
                                                        <img src={fileTypeIcon(uploadedFileStock?.fileUploaded?.path?.split('.')[1])} alt="" />
                                                    </label>
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <div className='fontMedium textColorBlack fontSize16'>Stock File</div>
                                                    <div className='fontSize14 textColorGray'>
                                                        <ProgressBarForFileUpload isCLass={'brandUploadProgress'} onClickImageCloseIcon={() => removeFile('stock')} progress={100} File={uploadedFileStock} uploadSpeed={''} notShowBrand={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* <div className='dropZoneContentRight'>
                                {uploadedFile?.path && <div>
                                    <p className='uploadingP'>Uploading</p>
                                    <div className='paddingTop24'>
                                        <ProgressBarForFileUpload onClickImageCloseIcon={removeFile} progress={progress} File={uploadedFile} uploadSpeed={uploadSpeed} />
                                    </div>
                                    <div className='pt-3 CustomBlueButtonConatiner' >
                                        <CustomBlueButton customCreatePropsBtnClass="customCreatePropsBtnClass" disabled={false} onClick={onClickUpload} title={'Upload'} />
                                    </div>
                                </div>}
                            </div> */}
                        </div>
                    </div>
                    <div className='paddingTop24 d-flex justify-content-center'>
                        <div className='widthFit'>
                            <CustomBlueButton customCreatePropsBtnClass="custom-close-btn" disabled={false} onClick={() => setOpenUploadFileModal(false)} title={'Close'} />
                        </div>
                    </div>
                </div>} />

            {/* Unprocessed reports */}
            <CustomModal classes="customeProgressModal" open={openUnprocessedReport} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div className='pb-3'>Unprocessed Report</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenUnprocessedReport(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='tableContainer reports'>
                        <CustomTableWithFilter totalRow={() => { }} isContentLeftRightFixed={false} height={450} column={salesUnprocessedTableHeader} tableRowData={unprocessedReports} onClickDownloadReport={onClickDownloadReport} onClickView={onClickView} onFilterChanged={onFilterChanged} onGridReady={onGridReady} filterModel={tableFilterValue} customeHeightClass={'tableCustomeHeightClass'} />
                    </div>
                </div>} />
            {/* view all info */}
            <CustomModal classes="customeProgressModal" open={viewAllInfo} style={viewReportFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div className=''>Report Details <span className='fontSize14'>({convertDates(selectedItem.from_date)} - {convertDates(selectedItem.to_date)})</span></div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setViewAllInfo(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='custom-height custom-report'>
                        <div className='' >{displayDescription(selectedItem, 'popup')}</div>
                    </div>
                </div>} />

            {/* create custom report */}
            <CustomModal classes="customeProgressModal" open={openCustomReport} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Generate Custom Report</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenCustomReport(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex paddingTop24 align-items-center justify-content-between'>
                        <div className='d-flex'>
                            <div style={{ width: '250px' }}>
                                <MultiSelectCheckBox MenuProps={MenuProps} errored={false} errorMsg={''} selectedValue={inputValue?.brand?.map(brand => brand?.brand_name)} handleChange={(e) => onSelectValue(e, 'brand')} required options={brandNameList} label='Brand Name*' />
                            </div>
                            <div style={{ width: '250px' }} className='ps-2'>
                                <MultiSelectCheckBox MenuProps={MenuProps} errored={false} errorMsg={''} selectedValue={inputValue?.store?.map(stores => stores?.store_code)} handleChange={(e) => onSelectValue(e, 'store')} required options={storeNameListForCustom} label='Store Name*' />
                            </div>
                            <div style={{ width: '270px' }} className='ps-2'>
                                <div className='inputDateRangeBottomSpace'>
                                    <div onClick={openCalendar} className='open_calendar_open'>
                                        <InputTextField
                                            type='text'
                                            disabled={false}
                                            name='dateRange'
                                            value={
                                                startDate || endDate
                                                    ? `${startDate ? convertDate(startDate) : ''} ${(startDate && endDate) ? '-' : ''} ${endDate ? convertDate(endDate) : ''}`
                                                    : ''
                                            }
                                            onChange={() => { }}
                                            required
                                            minLength={10}
                                            maxLength={10}
                                            label='Date'
                                            calendarIcon={true}
                                        />
                                    </div>
                                    <div className=''>
                                        <CustomDateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            calendarOpen={calendarOpen}
                                            selectedRange={selectedRange}
                                            handleSelect={(date, newVal) => handleDateRangeChange('dateRange', date, '', '')}
                                            closeOnOutSide={() => setCalendarOpen(false)}
                                            restrictFutureDates={true}
                                        />
                                    </div>
                                    {/* {formDataError?.date && <div className="error">{formDataError?.date}</div>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24 d-flex justify-content-center'>
                        <div className='widthFit'>
                            <CustomBlueButton customCreatePropsBtnClass="custom-close-btn" disabled={inputValue.brand?.length === 0 || inputValue.store?.length === 0 || inputValue.from_date === null || inputValue.to_date === null} onClick={generateReport} title={'Generate Report'} />
                        </div>
                    </div>
                </div>} />

            {loading && <Loader />}
        </div>
    )
}

export default SalesOverview