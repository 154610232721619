import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    defaultYear: {},
    defaultBrand: {},
    defaultBrandForRecon: {},
    defaultMonthForRecon: {},
    startDateDisplay: null,
    endDateDisplay: null,
    startDate: null,
    endDate: null,
    tableFilters: {},
    storeSalesList: [],
    salesData: [],
};

const salesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        setDefaultYear: (state, action) => {
            state.defaultYear = action.payload.type
        },
        setDefaultBrand: (state, action) => {
            state.defaultBrand = action.payload.type
        },
        setDefaultBrandForRecon: (state, action) => {
            state.defaultBrandForRecon = action.payload.type
        },
        setDefaultMonthForRecon: (state, action) => {
            state.defaultMonthForRecon = action.payload.type
        },
        setStartDateDisplay: (state, action) => {
            state.startDateDisplay = action.payload.type
        },
        setEndDateDisplay: (state, action) => {
            state.endDateDisplay = action.payload.type
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload.type
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload.type
        },
        setTableFilters: (state, action) => {
            state.tableFilters = action.payload.type
        },
        setStoreSalesList: (state, action) => {
            state.storeSalesList = action.payload.type
        },
        setSalesData: (state, action) => {
            state.salesData = action.payload.type
        },
    }
});

export const salesActions = salesSlice.actions;
export default salesSlice;