import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./Pages/Main";
// import configureStore from "./config/configureStore";
import { Provider } from "react-redux";
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from "react-toastify";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://04fe559b4bef1d0f2266d661275a7430@o4505397343879168.ingest.us.sentry.io/4508482031321088",
  integrations: [],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
});


// const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <Component />
        </HashRouter>
      </PersistGate>
      <ToastContainer />
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./Pages/Main", () => {
    const NextApp = require("./Pages/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
