import React, { useEffect, useState, useRef } from 'react';
import CustomHorizontalStepper from '../../../ReUsableComponent/CustomHorizontalStepper';
import RightArrowIcon from '../../../assets/svg/rightArrow.svg';
import { Link, useHistory } from 'react-router-dom';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import '../TeamManagement.css';
import ProgressBarWithLabel from '../../../ReUsableComponent/ProgressBarWithLabel';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import CreateUserForm from './CreateUserForm';
import moment from 'moment/moment';
import { getListStateCityByPincode } from '../../BrandManagement/BrandManagement.service';
import { useDispatch, useSelector } from 'react-redux';
import { teamManagementActions } from '../../../store/reducers/TeamManagementReducer';
import { CreateAndUpdateUser, GetDesignationList, GetReportingManagerList, GetUserRoleList, UploadUserDocs, GetBrandStateList } from '../TeamManagement.service';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import { GetBrandNameList, GetStoreCustomerNameList } from '../../RuleManagement/RuleManagement.service';
import { userManagementActions } from '../../../store/reducers/UserManagementReducer';
import Loader from '../../../ReUsableComponent/Loader';
import { calculate_age } from '../../../utils/AgeCalculator';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import { Validate, ValidationFunction, checkValidation } from '../../../utils/ValidationFunction';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const OfflineChannels = [
    { label: 'MBO', value: 'MBO' },
    { label: 'EBO', value: 'EBO' },
    { label: 'Regional Player', value: 'Regional Player' },
    { label: 'Departmental Store', value: 'Departmental Store' },
    { label: 'E-commerce', value: 'E-commerce' },
    { label: 'Others', value: 'Others' },
];
const OnlineChannels = [
    { label: 'Amazon', value: 'Amazon' },
    { label: 'Flipkart', value: 'Flipkart' },
    { label: 'Myntra', value: 'Myntra' }
];
const ChannelServed = [
    { label: 'Online', value: 'Online' },
    { label: 'Offline', value: 'Offline' }
];


const CreateUser = () => {
    const timeoutRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const steps = ['Personal Information', 'Bank Details', 'Onboarding Details', 'Work Experience', 'Upload Documents'];
    const STATUS = [{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }];
    const USER_ID = useSelector(state => state.userAuthentication.userId);
    const [progress, setProgress] = React.useState(0);
    const [fileUploadprogress, setFileUploadProgress] = React.useState(0);
    const [startTime, setStartTime] = useState(null);
    const [inputValue, setInputValue] = useState({ 'user_status': 'Inactive' });
    const [storeNameList, setStoreNameList] = useState([]);
    const [brandNameList, setBrandNameList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [validation, setValidation] = useState({});
    const [disableSubmitBtn, setdisableSubmitBtn] = useState(true);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [statesNameList, setStatesNameList] = useState([]);
    const [brandNameQueryParm, setBrandNameQueryParm] = useState([]);
    const [selectedBrandState, setSelectedBrandState] = useState('');
    const [completedSteps, setCompletedSteps] = useState({});
    const getActiveStep = useSelector((state) => state.userManagement.stepActiveState);
    const [activeStep, setActiveStep] = React.useState(0);
    const isStepCompleted = (step) => !!completedSteps[step];
    const [currentStep, setCurrentStep] = useState(getActiveStep);

    const createUserRole = useSelector(state => state.userManagement.createUserRole);
    useEffect(() => {
        getBrandList();
        getReportingmngrList('');
        getDesignations();
        getUserRoleList();
        getChannelList();
        // dispatch(userManagementActions.setSelectedBrands({ type: [] }));
        // dispatch(userManagementActions.setSelectedStores({ type: [] }));
    }, []);

    useEffect(() => {
        getBrandStateList(brandNameQueryParm.toString());
    }, [selectedRegion]);

    useEffect(() => {
        getStoreList();
        getReportingmngrList('')
    }, [brandNameQueryParm, selectedRegion, selectedBrandState]);

    useEffect(() => {
        if (activeStep === 0) {
            //fieldcheck
            if (!inputValue?.name || !inputValue?.emp_id || !inputValue?.gender || !inputValue?.mobile_number || !inputValue?.designation_id || !inputValue?.role
                || !inputValue?.email_id || !inputValue?.doj || !inputValue?.dob || !inputValue?.age || !inputValue?.company || !inputValue?.adhar_no
                || !inputValue?.pan_no || !inputValue?.address || !inputValue?.pincode || !inputValue?.city || !inputValue?.state || !inputValue?.region
                || !inputValue?.year_exp || !inputValue?.current_gross_ctc || !inputValue?.last_gross_ctc || (validation?.name && validation?.name !== '')
                || (validation?.emp_id && validation?.emp_id !== '') || (validation?.last_city && validation?.last_city !== '') || (validation?.mobile_number && validation?.mobile_number !== '')
                || (validation?.email_id && validation?.email_id !== '') || (validation?.doj && validation?.doj !== '') || (validation?.dob && validation?.dob !== '')
                || (validation?.age && validation?.age !== '') || (validation?.company && validation?.company !== '') || (validation?.adhar_no && validation?.adhar_no !== '')
                || (validation?.pan_no && validation?.pan_no !== '') || (validation?.address && validation?.address !== '') || (validation?.pincode && validation?.pincode !== '')
                || (validation?.city && validation?.city !== '') || (validation?.state && validation?.state !== '') || (validation?.region && validation?.region !== '')
                || (validation?.year_exp && validation?.year_exp !== '') || (validation?.current_gross_ctc && validation?.current_gross_ctc !== '')
                || (validation?.last_gross_ctc && validation?.last_gross_ctc !== '') || (validation?.company && validation?.company !== '')
                || (validation?.address && validation?.address !== '')) {
                setdisableSubmitBtn(true);
            } else {
                setdisableSubmitBtn(false);
            }
        } else if (activeStep === 1) {
            if (!inputValue?.bank_name || !inputValue?.accountNumber || !inputValue?.bank_ifsc || !inputValue?.branch_name
                || !inputValue?.branch_address || !inputValue?.esi_no || !inputValue?.pf_no || !inputValue?.remarks
                || (validation?.bank_name && validation?.bank_name !== '') || (validation?.accountNumber && validation?.accountNumber !== '')
                || (validation?.bank_ifsc && validation?.bank_ifsc !== '') || (validation?.branch_name && validation?.branch_name !== '')
                || (validation?.branch_address && validation?.branch_address !== '') || (validation?.esi_no && validation?.esi_no !== '')
                || (validation?.pf_no && validation?.pf_no !== '') || (validation?.remarks && validation?.remarks !== '')) {
                setdisableSubmitBtn(true);
            } else {
                setdisableSubmitBtn(false);
            }
        } else if (activeStep === 2) {
            if (!inputValue?.exit_date || !inputValue?.login_type || !inputValue?.channel
                || ((createUserRole === 'SM' || createUserRole === 'FL') && inputValue?.store_id?.length < 1 || inputValue?.brand_name?.length < 1)
                || ((createUserRole === 'Manager' || createUserRole === 'ASM') && inputValue?.store_id?.length < 1 || inputValue?.brand_name?.length < 1)
                || ((createUserRole === 'RSM') && inputValue?.store_id?.length < 1 || inputValue?.brand_name?.length < 1)
            ) {
                setdisableSubmitBtn(true);

            } else {
                setdisableSubmitBtn(false);
            }
        } else if (activeStep === 3) {
            if (!inputValue?.education) {
                setdisableSubmitBtn(true);
            } else {
                setdisableSubmitBtn(false);
            }
        } else if (activeStep === 4) {
            if ((!inputValue?.pan_doc) || (!inputValue?.adhar_doc)) {
                setdisableSubmitBtn(true);
            } else {
                setdisableSubmitBtn(false);
            }
        }
    }, [validation, inputValue, activeStep])


    const handleNext = () => {
        if ((activeStep + 1) < steps.length) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setCurrentStep((prevActiveStep) => prevActiveStep + 1);
            dispatch(userManagementActions.setSelectedStores({ type: [] }));
            dispatch(userManagementActions.setSelectedBrands({ type: [] }));
            dispatch(userManagementActions.setPan({ type: {} }));
            dispatch(userManagementActions.setAadhaar({ type: {} }));
            setCompletedSteps({ ...completedSteps, [activeStep]: true });
        }
        if (progress < 100) {
            let formCompleted = 100 / steps.length
            setProgress(prev =>
                prev += formCompleted
            )
        }
    };


    const handleBackStep = (label, index) => {
        setCurrentStep(index);
        if (activeStep > index && !(activeStep === 0 && index === 0)) {
            if ((activeStep - 1) < steps.length) {
                setActiveStep(index);
                setCompletedSteps((prev) => {
                    const updatedSteps = { ...prev };
                    Object.keys(updatedSteps).forEach((key) => {
                        if (parseInt(key) >= index) delete updatedSteps[key];
                    });
                    return updatedSteps;
                });
                // dispatch(storeManagementActions.setStepActiveState({ type: index}));
            }
            if (progress > 0) {
                let formCompleted = 100 / (steps.length);
                setProgress((100 - (100 - (formCompleted * (index + 1)))) - formCompleted)
                // dispatch(storeManagementActions.setStepProgress({ type: (100 - (100 - (formCompleted * (index+1)))) - formCompleted}));

            }
        }
        if (index === 0) {
            dispatch(userManagementActions.setBackActiveStep({ type: true }));
        }
    };

    const getBrandList = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.brand_name,
                    value: item?.brand_name,
                    brand_id: item?._id
                }
                dropdownData.push(row)
            })
            setBrandNameList(dropdownData);
            dispatch(userManagementActions.setBrandList({ type: dropdownData }));

        });
    }

    const getBrandStateList = (brand) => {
        let param = `?region=${selectedRegion}&brand=${brand}`
        GetBrandStateList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item,
                    value: item,
                }
                dropdownData.push(row)
            });
            setStatesNameList(dropdownData);
            dispatch(userManagementActions.setStatesList({ type: dropdownData }));
        });
    }

    const getStoreList = () => {
        let queryParams = `?brand_id=${brandNameQueryParm?.join(',')}&region=${selectedRegion ? selectedRegion : ''}&state=${selectedBrandState ? selectedBrandState : ''}`;
        GetStoreCustomerNameList(queryParams, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.customer_name,
                    value: item?.customer_name,
                    store_code: item?.store_code,
                    store_id: item?._id,
                    store_trade_name: item?.store_trade_name
                }
                dropdownData.push(row);
            })
            setStoreNameList(dropdownData);
            dispatch(userManagementActions.setStoreList({ type: dropdownData }));
        });
    }
    const getUserRoleList = () => {
        let param = `?module=${'user_roles'}&dropdown_key=user_roles`
        GetUserRoleList(param, USER_TOKEN).then(response => {
            if (response?.data?.statusCode === 400) {
                dispatch(userManagementActions.setUserRoles({ type: [] }));
            } else {
                let dropdownData = [];
                response?.data?.data[0]?.dropdown_values?.map(item => {
                    let row = {
                        label: item,
                        value: item,
                    }
                    dropdownData.push(row);
                })
                dispatch(userManagementActions.setUserRoles({ type: dropdownData }));
            }
        });
    }
    const getDesignations = () => {
        let param = `?module=${'user_designation'}&dropdown_key=user_designation`
        GetDesignationList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data[0]?.dropdown_values?.map(item => {
                let row = {
                    label: item,
                    value: item,
                }
                dropdownData.push(row);
            })
            dispatch(userManagementActions.setDesignations({ type: dropdownData }));
        });
    }
    const getReportingmngrList = (name, keyName) => {
        let param = `?name=${name}&brand_id=${brandNameQueryParm?.join(',')}`
        GetReportingManagerList(param, USER_TOKEN).then(response => {
            if (name) {
                setInputValue(values => ({ ...values, ['reporting_manager_email']: response?.data?.data[0].email, ['reporting_manager_mob']: response?.data?.data[0].mobileNumber }));
            } else {
                let dropdownData = [];
                response?.data?.data?.map((item, index) => {
                    let row = {
                        label: item?.name,
                        value: item?.name,
                        id: index,
                        email_id: item?.email
                    }
                    dropdownData.push(row);
                })
                dropdownData = dropdownData.filter(function (element) {
                    return element?.email_id !== inputValue?.email_id;
                })
                dispatch(userManagementActions.setReportingMngr({ type: dropdownData }));
            }
        });
    }
    const getChannelList = () => {
        let param = `?module=${'brand'}&dropdown_key=ChannelOffline`
        GetDesignationList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data[0]?.dropdown_values?.map(item => {
                let row = {
                    label: item,
                    value: item,
                }
                dropdownData.push(row);
            })
            dispatch(userManagementActions.setChannels({ type: dropdownData }));
        });
    }

    // const validatePersonalInformationFields = () => {
    //     const newValidations = {};
    //     let firstInvalidField = null;

    //     // List all required fields and their validation rules
    //     const requiredFields = [
    //         { name: 'name', minLength: 3 },
    //         { name: 'emp_id', minLength: 1 },
    //         { name: 'gender', minLength: 1 },
    //         { name: 'mobile_number', minLength: 10 },
    //         { name: 'email_id', minLength: 6 },
    //         { name: 'designation_id', minLength: 1 },
    //         { name: 'role', minLength: 1 },
    //         { name: 'dob', minLength: 1 },
    //         { name: 'doj', minLength: 1 },
    //         { name: 'company', minLength: 3 },
    //         { name: 'adhar_no', minLength: 12 },
    //         { name: 'pan_no', minLength: 10 },
    //         { name: 'address', minLength: 3 },
    //         { name: 'pincode', minLength: 6 },
    //         { name: 'year_exp', minLength: 1 },
    //         { name: 'last_gross_ctc', minLength: 1 },
    //         { name: 'current_gross_ctc', minLength: 1 }
    //     ];

    //     // Iterate over the required fields and validate them
    //     requiredFields.forEach(field => {
    //         const value = inputValue[field.name];
    //         if (!value || (value.length < field.minLength)) {
    //             newValidations[field.name] = `Please enter minimum ${field.minLength} character${field.minLength > 1 ? 's' : ''}`;
    //         }
    //     });

    //     setValidation(newValidations);
    //     return Object.keys(newValidations).length === 0;
    // };

    // const validateBankDetailsFields = () => {
    //     const newValidations = {};

    //     // List all required fields and their validation rules for Bank Details
    //     const requiredFields = [
    //         { name: 'bank_name', minLength: 2 },
    //         { name: 'accountNumber', minLength: 8 },
    //         { name: 'bank_ifsc', minLength: 11, maxLength: 11 },
    //         { name: 'branch_name', minLength: 2 },
    //         { name: 'branch_address', minLength: 3 },
    //         { name: 'esi_no', minLength: 17, maxLength: 17 },
    //         { name: 'pf_no', minLength: 22, maxLength: 26 },
    //         { name: 'remarks', minLength: 2 }
    //     ];

    //     // Iterate over the required fields and validate them
    //     requiredFields.forEach(field => {
    //         const value = inputValue[field.name];
    //         if (!value || (value.length < field.minLength) || (field.maxLength && value.length > field.maxLength)) {
    //             newValidations[field.name] = `Please enter ${
    //                 field.maxLength
    //                     ? `between ${field.minLength} and ${field.maxLength}`
    //                     : `minimum ${field.minLength}`
    //             } character${field.minLength > 1 ? 's' : ''}`;
    //         }
    //     });

    //     setValidation(newValidations);
    //     return Object.keys(newValidations).length === 0; // Returns true if no validation errors
    // };

    // const validateOnboardingDetailsFields = () => {
    //     const newValidations = {};

    //     // List all required fields and their validation rules for User Onboarding Details
    //     const requiredFields = [
    //         { name: 'brand_name', minLength: 1 },
    //         { name: 'brand_region', minLength: 1 },
    //         { name: 'brand_state', minLength: 1 },
    //         { name: 'store_detail', minLength: 1 },
    //         { name: 'reporting_manager', minLength: 1 },
    //         { name: 'value', minLength: 1 },
    //         { name: 'reporting_manager_mob', minLength: 3, maxLength: 80 },
    //         { name: 'reporting_manager_email', minLength: 3, maxLength: 80 },
    //         { name: 'exit_date', minLength: 1 },
    //         { name: 'login_type', minLength: 1 },
    //         { name: 'channel', minLength: 1 },
    //         { name: 'user_status', minLength: 1 }
    //     ];

    //     // Iterate over the required fields and validate them
    //     requiredFields.forEach(field => {
    //         const value = inputValue[field.name];
    //         if (!value || (value.length < field.minLength) || (field.maxLength && value.length > field.maxLength)) {
    //             newValidations[field.name] = `Please enter ${
    //                 field.maxLength
    //                     ? `between ${field.minLength} and ${field.maxLength}`
    //                     : `minimum ${field.minLength}`
    //             } character${field.minLength > 1 ? 's' : ''}`;
    //         }
    //     });

    //     setValidation(newValidations);
    //     return Object.keys(newValidations).length === 0; // Returns true if no validation errors
    // };

    // const validateWorkExperienceFields = () => {
    //     const newValidations = {};

    //     // List all required fields and their validation rules for User Work Experience
    //     const requiredFields = [
    //         { name: 'education', minLength: 2 },
    //     ];

    //     // Iterate over the required fields and validate them
    //     requiredFields.forEach(field => {
    //         const value = inputValue[field.name];
    //         if (!value || (value.length < field.minLength) || (field.maxLength && value.length > field.maxLength)) {
    //             newValidations[field.name] = `Please enter ${
    //                 field.maxLength
    //                     ? `between ${field.minLength} and ${field.maxLength}`
    //                     : `minimum ${field.minLength}`
    //             } character${field.minLength > 1 ? 's' : ''}`;
    //         }
    //     });

    //     setValidation(newValidations);
    //     return Object.keys(newValidations).length === 0; // Returns true if no validation errors
    // };

    // const validateRequiredFields = () => {
    //     const newValidations = {};

    //     // List all required documents for Upload Documents
    //     const requiredDocuments = [
    //         { name: 'pan_doc', displayName: 'PAN Document' },
    //         { name: 'aadhaar_doc', displayName: 'Aadhaar Document' }
    //     ];

    //     // Iterate over required documents to check if they're uploaded
    //     requiredDocuments.forEach(doc => {
    //         if (!inputValue[doc.name]?.fileUrl) {
    //             newValidations[doc.name] = `Please upload ${doc.displayName}`;
    //         }
    //     });

    //     setValidation(newValidations);
    //     if (Object.keys(newValidations).length > 0) {
    //         alert("Please upload all required documents before proceeding.");
    //     }
    //     return Object.keys(newValidations).length === 0; // Returns true if no validation errors
    // };

    // const handleCreateUser = () => {
    //     setLoading(true);
    //     if(activeStep === 0){
    //         if (!validatePersonalInformationFields()) {
    //             setLoading(false);
    //             return;
    //         }
    //     } else if(activeStep === 1){
    //         if (!validateBankDetailsFields()) {
    //             setLoading(false);
    //             return;
    //         }
    //     } else if(activeStep === 2){
    //         if (!validateOnboardingDetailsFields()) {
    //             setLoading(false);
    //             return;
    //         }
    //     } else if(activeStep === 3){
    //         if (!validateWorkExperienceFields()) {
    //             setLoading(false);
    //             return;
    //         }
    //     } else if(activeStep === 4){
    //         if (!validateRequiredFields()) {
    //             setLoading(false);
    //             return;
    //         }
    //     }

    //     let param = `?id=${USER_ID}`
    //     CreateAndUpdateUser(inputValue, param, USER_TOKEN).then(response => {
    //         if (response.data.statusCode === 201) {
    //             setInputValue({ 'id': response?.data?.data?._id, 'user_status': 'Inactive', 'doj': response?.data?.data?.doj });
    //             ToastSuccessService(response.data.message);
    //             getReportingmngrList('');
    //             handleNext();
    //             if (activeStep === 4) {
    //                 setOpenSuccessPopup(true);
    //                 if (timeoutRef.current) {
    //                     clearTimeout(timeoutRef.current);
    //                 }
    //                 // Set a new timeout to automatically close the popup
    //                 timeoutRef.current = setTimeout(() => {
    //                     closeSuccessPopup();
    //                 }, 5000);
    //             }
    //         } else {
    //             ToastErrorService(response.data.message)
    //         }
    //     }).catch(error => {
    //         if (error.response.status === 401) {
    //             history.push('/')
    //         }
    //         ToastErrorService(error.response.data.message);
    //     }).finally(() => {
    //         setLoading(false);
    //     })
    // }


    const validateFields = (fields) => {

        const newValidations = {};
        let firstInvalidField = null;

        fields.forEach(field => {
            const value = inputValue[field.name];
            console.log("field.type====>", field.type);
            if (field.type === 'date') {
                if (!value) {
                    newValidations[field.name] = `Please select a valid ${field.displayName}`;
                    if (!firstInvalidField) {
                        firstInvalidField = ''; // Capture the first invalid field
                    }
                }
                // Optional: Add format or range validation if required
                // Example: Validate if the date is in the past
                // const selectedDate = new Date(value);
                // if (selectedDate > new Date()) {
                //     newValidations[field.name] = `${field.displayName} cannot be a future date.`;
                //     if (!firstInvalidField) {
                //         firstInvalidField = field.name;
                //     }
                // }
            }

            // Check for empty fields or fields not meeting min/max length requirements
            // if (!value || (field.minLength && value.length < field.minLength) || 
            //     (field.maxLength && value.length > field.maxLength) ||
            //     (field.type === 'date' && !value) || // For date fields
            //     (field.type === 'dropdown' && !value) // For dropdowns
            // ) {
            //     newValidations[field.name] = `Please enter minimum ${field.minLength} character`
            //     if (!firstInvalidField) {
            //         firstInvalidField = field.name;
            //     }
            // }

            else if (!value || (field.minLength && value.length < field.minLength) || (field.maxLength && value.length > field.maxLength) || (field.type === 'dropdown' && !value)) {
                newValidations[field.name] = `Please enter minimum ${field.minLength} characters for ${field.displayName}`;
                if (!firstInvalidField) {
                    firstInvalidField = field.name;
                }
            }
        });

        setValidation(newValidations);

        if (firstInvalidField) {
            document.getElementById(firstInvalidField)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            document.getElementById(firstInvalidField)?.focus();
        }

        return Object.keys(newValidations).length === 0; // Returns true if no validation errors
    };

    const handleCreateUser = () => {
        setLoading(true);

        let isValid = true;
        const newValidations = {}; // Declare here to avoid 'not defined' error
        let firstInvalidField = null; // Declare here to track the first invalid field

        // Define all required fields for different sections
        if (activeStep === 0) {
            const personalInfoFields = [
                { name: 'name', minLength: 3, displayName: 'Name' },
                { name: 'emp_id', minLength: 1, displayName: 'Employee Id' },
                { name: 'gender', minLength: 1, displayName: 'Gender', type: 'dropdown' },
                { name: 'mobile_number', minLength: 10, displayName: 'Mobile Number' },
                { name: 'email_id', minLength: 6, displayName: 'E-Mail Id' },
                { name: 'designation_id', minLength: 1, displayName: 'User Designation', type: 'dropdown' },
                { name: 'role', minLength: 1, displayName: 'User Role', type: 'dropdown' },
                { name: 'dob', minLength: 1, displayName: 'Birth Date', type: 'date' },
                { name: 'doj', minLength: 1, displayName: 'Date Of Joining', type: 'date' },
                { name: 'company', minLength: 3, displayName: 'Company' },
                { name: 'adhar_no', minLength: 12, displayName: 'Aadhaar Number' },
                { name: 'pan_no', minLength: 10, displayName: 'PAN Number' },
                { name: 'address', minLength: 3, displayName: 'Address' },
                { name: 'pincode', minLength: 6, displayName: 'Pincode' },
                { name: 'year_exp', minLength: 1, displayName: 'Years of Experience' },
                { name: 'last_gross_ctc', minLength: 1, displayName: 'Last Gross CTC' },
                { name: 'current_gross_ctc', minLength: 1, displayName: 'Current Gross CTC' }
            ];
            isValid = validateFields(personalInfoFields);
        } else if (activeStep === 1) {
            const bankDetailsFields = [
                { name: 'bank_name', minLength: 2, displayName: 'Bank Name' },
                { name: 'accountNumber', minLength: 8, displayName: 'Account Number' },
                { name: 'bank_ifsc', minLength: 11, maxLength: 11, displayName: 'Bank IFSC' },
                { name: 'branch_name', minLength: 2, displayName: 'Branch Name' },
                { name: 'branch_address', minLength: 3, displayName: 'Branch Address' },
                { name: 'esi_no', minLength: 17, maxLength: 17, displayName: 'ESI Number' },
                { name: 'pf_no', minLength: 22, maxLength: 26, displayName: 'PF Number' },
                { name: 'remarks', minLength: 2, displayName: 'Remarks' }
            ];
            isValid = validateFields(bankDetailsFields);
        } else if (activeStep === 2) {
            const onboardingDetailsFields = [
                { name: 'brand_name', minLength: 1, displayName: 'Brand Name' },
                { name: 'brand_region', minLength: 1, displayName: 'Brand Region' },
                { name: 'brand_state', minLength: 1, displayName: 'Brand State' },
                { name: 'store_detail', minLength: 1, displayName: 'Store Detail' },
                { name: 'reporting_manager', minLength: 1, displayName: 'Reporting Manager' },
                // { name: 'value', minLength: 1, displayName: 'Value' },
                { name: 'reporting_manager_mob', minLength: 3, maxLength: 80, displayName: 'Reporting Manager Mobile' },
                { name: 'reporting_manager_email', minLength: 3, maxLength: 80, displayName: 'Reporting Manager Email' },
                // { name: 'exit_date', minLength: 1, displayName: 'Exit Date', type: 'date' },
                { name: 'login_type', minLength: 1, displayName: 'Login Type' },
                { name: 'channel', minLength: 1, displayName: 'Channel' },
                { name: 'user_status', minLength: 1, displayName: 'User Status' }
            ];
            isValid = validateFields(onboardingDetailsFields);
        } else if (activeStep === 3) {
            const workExperienceFields = [
                { name: 'education', minLength: 2, displayName: 'Education' }
            ];
            isValid = validateFields(workExperienceFields);
        }
        // else if (activeStep === 4) {
        //     const requiredDocuments = [
        //         { name: 'pan_doc', minLength: 1, displayName: 'PAN Document' },
        //         { name: 'aadhaar_doc', minLength: 1, displayName: 'Aadhaar Document' }
        //     ];

        //     requiredDocuments.forEach(doc => {
        //         const fileUrl = inputValue[doc.name]?.fileUrl;
        //         if (!fileUrl || fileUrl.trim() === "") {
        //             newValidations[doc.name] = `Please upload ${doc.displayName}`;
        //             if (!firstInvalidField) {
        //                 firstInvalidField = doc.name;
        //             }
        //         }
        //     });

        //     if (Object.keys(newValidations).length > 0) {
        //         setValidation(newValidations);
        //         if (firstInvalidField) {
        //             const invalidElement = document.getElementById(firstInvalidField);
        //             invalidElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        //             invalidElement?.focus();
        //         }
        //         alert("Please upload all required documents before proceeding.");
        //         setLoading(false);
        //         return; // Stop execution if validation fails
        //     }

        //     isValid = true; // No validation errors
        // }

        // if (!isValid) {
        //     setLoading(false);
        //     return; // Stop execution if validation fails
        // }

        let param = `?id=${USER_ID}`;
        CreateAndUpdateUser(inputValue, param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 201) {
                setInputValue({ 'id': response?.data?.data?._id, 'user_status': 'Inactive', 'doj': response?.data?.data?.doj });
                ToastSuccessService(response.data.message);
                getReportingmngrList('');
                handleNext();
                if (activeStep === 4) {
                    setOpenSuccessPopup(true);
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    // Set a new timeout to automatically close the popup
                    timeoutRef.current = setTimeout(() => {
                        closeSuccessPopup();
                    }, 5000);
                }
            } else {
                ToastErrorService(response.data.message);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/');
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        });
    };

    const checkMinLength = (name, minlength, value) => {
        if (value?.length < minlength) {
            setValidation(prev => ({ ...prev, [name]: `Please enter minimum ${minlength} character` }))
        }
    }

    const onInputValue = (event) => {
        const name = event.target.name;
        if (name === 'name' || name === 'emp_id' || name === 'last_city' || name === 'bank_name' || name === 'address'
            || name === 'previous_companies' || name === 'branch_address' || name === 'education' || name === 'company'
            || name === 'branch_name' || name === 'remarks' || name === 'past_experience' || name === 'previous_companies') {
            checkMinLength(name, event.target.minLength, event.target.value);
        }
        if (name === 'name') {
            event.target.value = event.target.value.replace(/\b\w/g, char => char.toUpperCase());
        }
        if (name === 'mobile_number' || name === 'adhar_no' || name === 'pincode' || name === 'door_count' || name === 'accountNumber' || name === 'esi_no') {
            event.target.value = event.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
        } else if (name === 'name' || name === 'past_experience') {
            event.target.value = event.target.value.replace(/[^A-Za-z ]/g, '').replace(/(\..*)\./g, '$1');
        } else if (name === 'pan_no' || name === 'bank_ifsc' || name === 'pf_no') {
            event.target.value = event.target.value.toUpperCase();
        } else if (name === 'gross_sales') {
            event.target.value = event.target.value.replace(/[^0-9,.]/g, '').replace(/(\..*)\./g, '$1');
        } else if (name === 'last_gross_ctc' || name === 'current_gross_ctc') {
            event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }
        if (name === 'store_code') {
            setInputValue(values => ({
                ...values, 'store_detail': {
                    ...values.store_detail, [name]: event.target.value
                }
            }));
        } else {
            let valuecheck = { [name]: event.target.value }
            checkValidation(valuecheck, validation, name)
            setInputValue(values => ({ ...values, [name]: event.target.value }));
        }
        if (name === 'pincode' && event.target.value?.length === 6) {
            getCityStateByPin(event.target.value);
        }

    }

    const onChangeValue = (event, name, value) => {
        if (name === 'reporting_manager') {
            getReportingmngrList(value.value);
        } else if (name === 'brand_region') {
            setSelectedRegion(value?.value);
        } else if (name === 'brand_state') {
            setSelectedBrandState(value?.value);
            // dispatch(userManagementActions.setSelectedStates({ type: value?.value }));
        }
        setInputValue(values => ({ ...values, [name]: value.value }));
        if (name === 'designation_id') {
            dispatch(userManagementActions.setCreateUserRole({ type: value.value }));
        }

    };

    const onSelectValue = (event, name) => {
        const {
            target: { value },
        } = event;
        if (name === 'store_id') {
            let storeObject = [];
            value.map(storeName => {
                const selectedStore = storeNameList?.find(store => store?.value === storeName);
                let storeSelected = {
                    store_id: selectedStore?.store_id,
                    store_trade_name: selectedStore?.store_trade_name !== 'null' ? selectedStore?.store_trade_name : selectedStore?.label,
                    store_code: selectedStore?.store_code,
                    store_name: selectedStore?.label
                }
                storeObject.push(storeSelected);
                dispatch(userManagementActions.setSelectedBrands({ type: value }));
            }).filter(store => store);
            setInputValue(values => ({
                ...values, 'store_detail': storeObject,
            }));
            dispatch(userManagementActions.setSelectedStores({ type: value }));

        } else if (name === 'brand_name') {
            let brandObject = [];
            let brandIdsObject = [];
            value.map(brandName => {
                const selectedBrand = brandNameList?.find(brand => brand?.value === brandName);
                let brandSelected = {
                    brand_name: selectedBrand?.label,
                    brand_id: selectedBrand?.brand_id
                }
                brandObject.push(brandSelected);
                brandIdsObject.push(brandSelected?.brand_id);
                dispatch(userManagementActions.setSelectedBrands({ type: value }));
            }).filter(store => store);
            setInputValue(values => ({
                ...values, 'brand_name': brandObject, brand_state: '', store_detail: [], reporting_manager: '', reporting_manager_mob: '', reporting_manager_email: ''
            }));
            setBrandNameQueryParm(brandIdsObject);
            getBrandStateList(brandIdsObject.toString());
        }
    }

    const onDateSelect = (value, name) => {
        let date = new Date(value)
        if (name === 'dob') {
            setInputValue(values => ({ ...values, ['age']: calculate_age(date) }));
        }
        setInputValue(values => ({ ...values, [name]: moment(date).format("DD-MM-YYYY") }));
    }

    const handleFileDrop = (files, name) => {
        setLoading(true);
        const fileUploaded = files[0];
        const formData = new FormData();
        if (name === 'pan_doc') {
            formData.append('service_type', 'Pan Document');
        } else if (name === 'adhar_doc') {
            formData.append('service_type', 'Adhar Document');
        }
        formData.append('service', 'User_Documents');
        formData.append('file', fileUploaded);
        let userDocs = [];
        UploadUserDocs(formData, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                setInputValue(values => ({ ...values, [name]: { 'fileUrl': response?.data?.data?.file_url, 'size': response?.data?.data?.size, 'fileName': response?.data?.data?.fileName, 'fileType': response?.data?.data?.file_type, 'created_at': response?.data?.data?.created_at } }));
                userDocs = { ...userDocs, [name]: { 'fileUrl': response?.data?.data?.file_url, 'size': response?.data?.data?.size, 'fileName': response?.data?.data?.fileName, 'fileType': response?.data?.data?.file_type, 'created_at': response?.data?.data?.created_at } }
                if (name === 'pan_doc') {
                    dispatch(userManagementActions.setPan({ type: userDocs }));
                } else {
                    dispatch(userManagementActions.setAadhaar({ type: userDocs }));
                }
            }
        }).catch(error => {
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    };

    const getCityStateByPin = (pincode) => {
        const param = `?pincode=${pincode}`
        getListStateCityByPincode(param, USER_TOKEN).then(response => {
            if (response?.data?.data?.length > 0) {
                setInputValue(values => ({
                    ...values, ['state']: response?.data?.data[0]?.state,
                    ['city']: response?.data?.data[0]?.city, ['region']: response?.data?.data[0]?.region
                }))
            } else {
                setInputValue(values => ({
                    ...values, ['state']: '',
                    ['city']: '', ['region']: ''
                }))
                ToastErrorService('No data available for given pincode');
            }

            let stateData = response?.data?.data?.map((item) => ({
                label: item?.state,
                value: item?.state,
                name: 'state'
            }));
            const uniqueStateData = Array.from(new Set(stateData.map(item => item.value))).map(value => {
                return stateData.find(item => item.value === value);
            });

            let cityData = response?.data?.data?.map((item) => ({
                label: item?.city,
                value: item?.city,
                name: 'city'
            }));
            const uniqueCityData = Array.from(new Set(cityData.map(item => item.value))).map(value => {
                return cityData.find(item => item.value === value);
            });

            let regionData = response?.data?.data?.map((item) => ({
                label: item?.region,
                value: item?.region,
                name: 'region'
            }));
            const uniqueRegionData = Array.from(new Set(regionData.map(item => item.value))).map(value => {
                return regionData.find(item => item.value === value);
            });

            let areaData = response?.data?.data?.map((item) => ({
                label: item?.area,
                value: item?.area,
                name: 'area'
            }));
            const uniqueAreaData = Array.from(new Set(areaData.map(item => item.value))).map(value => {
                return areaData.find(item => item.value === value);
            });
            let master = [{ stateMaster: uniqueStateData, cityMaster: uniqueCityData, regionMaster: uniqueRegionData, areaMaster: uniqueAreaData }]
            dispatch(teamManagementActions.setMasterData({ type: master }))
            // setAddressMaster(prev => ({ ...prev, stateMaster: uniqueStateData, cityMaster: uniqueCityData, regionMaster: uniqueRegionData, areaMaster: uniqueAreaData }))
        }).catch(error => {

        }).finally(() => {

        })
    }

    const onClickCancel = () => {
        history.push('/app/teamManagement');
        dispatch(userManagementActions.setStepActiveState({ type: 0 }));
    }

    const closeSuccessPopup = () => {
        if (timeoutRef.current) { clearTimeout(timeoutRef.current); }
        setOpenSuccessPopup(false);
        history.push('/app/teamManagement');
    };

    return (
        <div className='notoSansFont'>
            <div className='d-flex justify-content-between align-items-center'>
                <div className='breadCrumbs'>
                    <span className='fontLight'>
                        <Link className="breadCrumbs" to={'/app/teamManagement'}>Home</Link>
                    </span>
                    <span>
                        {" "}<img src={RightArrowIcon} height={9} alt="" />{" "}
                    </span>
                    <span className='fontSemiBold'>Create User</span>
                </div>
            </div>
            <div className='pt-3'>
                <div className='textColor333 pb-1'>You Have Completed <span className='textColorGreen fontBold'>{progress < 10 ? '0' : ''}{Math.round(progress)}%</span> User Profile!</div>
                <ProgressBarWithLabel progress={progress} />
            </div>
            <div className='pt-3'>
                <CustomHorizontalStepper steps={steps} isStepSkipped={null} isStepOptional={null} activeStep={currentStep} handleBackStep={handleBackStep} completedStep={isStepCompleted} />
            </div>
            <div className='pt-3'>
                <div className='bg-white createStoreFormCard'>
                    <CreateUserForm validations={validation} currentStep={currentStep} onSelectValue={onSelectValue} values={inputValue} activeStep={activeStep} onInputValue={onInputValue} onChangeValue={onChangeValue} handleFileDrop={handleFileDrop} onDateSelect={onDateSelect} selectedRegion={selectedRegion} selectedBrandState={selectedBrandState} />
                </div>
            </div>

            <div className='pt-3'>
                <div className='bg-white continueBtn d-flex'>
                    {activeStep === currentStep && activeStep === 0 && <div className='pe-3'>
                        <SecondaryButton customClsName='cancelBtn' title={'Cancel'} onClick={onClickCancel} />
                    </div>}
                    {activeStep === currentStep && < div >
                        <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={handleCreateUser}
                            // disabled={disableSubmitBtn} 
                            title={`${activeStep !== 4 ? 'Confirm & Next' : 'Upload Document'}`} />
                    </div>}
                    {activeStep !== currentStep && <CustomBlueButton customCreatePropsBtnClass={'confirmButton'} onClick={() => { setCurrentStep(activeStep) }}
                        title={'Goto Current Step'} />}
                </div>
            </div>

            {/* Success Popup content */}
            <SuccessPopup open={openSuccessPopup} closable={false} onClose={closeSuccessPopup} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>Created Successfully!</div>
                    <div className='fontSize16 textColor333'>User Created successfully.</div>
                </div>
            </div>} />
            {loading && <Loader />}
        </div >
    )
}

export default CreateUser