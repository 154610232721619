import React, { useEffect, useRef, useState } from 'react';
import ImportIcon from '../../../assets/svg/uploadIcon.svg';
import ExportIcon from '../../../assets/svg/downloadIcon.svg';
import CustomFilterDropDown from '../../../ReUsableComponent/CustomFilterDropdown';
import CustomTableWithFilter from '../../../ReUsableComponent/CustomTableWithFilter';
import CustomPagination from '../../../ReUsableComponent/Pagination';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import CustomDropzone from '../../../ReUsableComponent/CustomDropzone';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import ProgressBarForFileUpload from '../../../ReUsableComponent/ProgressBarForFileUpload';
import { brandTableHeader, ItemMasterHeader, ReconReportHeader } from '../../TableHeaders';
import { brandManagementActions } from '../../../store/reducers/BrandManagementReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CloseIcon from '../../../assets/svg/closeIconofmodal.svg';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import Loader from '../../../ReUsableComponent/Loader';
// import { DownaloadBrandItemMaster, GetBrandItemMaster, UploadBrandMasterList } from '../BrandManagement.service';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import dropDownIcon from '../../../assets/svg/dropDownIcon.svg';
// import { GetBrandNameList } from '../../RuleManagement/RuleManagement.service';
import { saveAs } from 'file-saver';
import { GetBrandNameList, GetStoreCustomerNameList } from '../../RuleManagement/RuleManagement.service';
import { salesActions } from '../../../store/reducers/SalesOverviewReducer';
import { CreateCustomReconReport, DownloadConsolidatedReportList, GetCustomReportList, GetSalesReconData, RefreshReconReports, RefreshUnprocssedReportList } from '../SalesOverview.service';
import CustomFilterSearchDropdown from '../../../ReUsableComponent/CustomFilterSearchDropdown';
import CustomMonthYearPicker from '../../../ReUsableComponent/CustomMonthYearPicker';
import dayjs from 'dayjs';
import DownloadIcon from '../../../assets/svg/reportsDownload.svg';
import { InfoOutlined } from '@mui/icons-material';
import { differenceInDays, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';
import moment from 'moment';
import CustomDateRangePicker from '../../../ReUsableComponent/CustomDaterangePicker';
import MultiSelectCheckBox from '../../../ReUsableComponent/MultiSelectCheckBox';
import InputTextField from '../../../ReUsableComponent/InputTextField';
import CustomAutoComplete from '../../../ReUsableComponent/CustomAutocomplete';
// import { DownloadTemplate } from '../../../Configurations/Core.service';

const downloadType = [{ label: 'Template Download', value: 'Template' }, { label: 'Report Download', value: 'Report' }]
const uploadFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const SuccessUploadModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 835,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
            width: 'max-content',
        },
    },
}; const viewReportFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};


const MonthList = [{ label: 'January', value: 'January' }, { label: 'February', value: 'February' }, { label: 'March', value: 'March' }, { label: 'April', value: 'April' }, { label: 'May', value: 'May' },
{ label: 'June', value: 'June' }, { label: 'July', value: 'July' }, { label: 'August', value: 'August' }, { label: 'September', value: 'September' },
{ label: 'October', value: 'October' }, { label: 'November', value: 'November' }, { label: 'December', value: 'December' },
];


const ReconReport = () => {
    const gridRef = useRef(null);
    const today = new Date();
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [viewAllInfo, setViewAllInfo] = useState(false);
    const [anchorElForExport, setAnchorElForExport] = useState(null);
    const [reconReportTableData, setReconReportTableData] = useState([]);
    const openDownloadType = Boolean(anchorElForExport);
    const [openCustomReport, setOpenCustomReport] = useState(false);
    const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
    const [openFileSelectedModal, setOpenFileSelectedModal] = useState(false);
    const [progress, setProgress] = useState(25);
    const [uploadSpeed, setUploadSpeed] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const selectedPage = useSelector(state => state.brandManagement.selectedPageForMatser);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [anchorElForBrand, setAnchorElForBrand] = useState(null);
    const openBrandFilter = Boolean(anchorElForBrand);
    const [selectedBrand, setSelectedBrand] = useState({ label: useSelector(state => state?.sales?.defaultBrandForRecon?.label), value: useSelector(state => state?.sales?.defaultBrandForRecon?.value) });
    const [brandList, setBrandList] = useState([]);
    const defaultBrand = useSelector(state => state?.sales?.defaultBrandForRecon);
    const itemMasterFilter = useSelector(state => state?.brandManagement?.masterFilter);
    const [anchorElForYear, setAnchorElForMonth] = useState(null);
    const openMonthFilter = Boolean(anchorElForYear);
    const [selectedMonth, setSelectedMonth] = useState({ label: useSelector(state => state?.sales?.defaultMonthForRecon?.label), value: useSelector(state => state?.sales?.defaultMonthForRecon?.value) });
    const [dropSearchValue, setdropSearchValue] = useState('');
    const USER_ID = useSelector((state) => state.userAuthentication.userId);
    const [customReportData, setCustomReportData] = useState([]);
    const convertDateIntoMonth = (dateString) => {
        let date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'long' })
        return `${month}-${year}`;
    }
    const [inputValue, setInputValue] = useState({
        brand: [],
        store: [],
        report: 'Sales',
        service: 'good_sales',
        from_date: null,
        to_date: null
    });
    const [startDate, setStartDate] = useState(inputValue?.from_date ? new Date(inputValue?.from_date) : null);
    const [endDate, setEndDate] = useState(inputValue?.to_date ? new Date(inputValue?.to_date) : null);
    const [selectedRange, setSelectedRange] = useState([
        inputValue?.from_date ? new Date(inputValue?.from_date) : today,
        inputValue?.to_date ? new Date(inputValue?.to_date) : null,
    ]);
    const [brandNameList, setBrandNameList] = useState([]);
    const [calendarOpen, setCalendarOpen] = useState(false);
    const [storeNameListForCustom, setStoreNameListForCustom] = useState([]);
    const [brandNameQueryParm, setBrandNameQueryParm] = useState([]);
    const [selectedItem, setSelectedItem] = useState({ description: '', id: '' });
    const [selectedMonthYear, setSelectedMonthYear] = useState(convertDateIntoMonth(new Date(useSelector(state => state?.sales?.defaultMonthForRecon))));
    useEffect(() => {
        getAllBrand();
        customReportList();
        getStoreListForCustom();
        getBrandList();
        // const date = new Date();
        // const currentMonthName = MonthList[date.getMonth() - 1];
        // setSelectedMonth(currentMonthName);
        // brandList.forEach(brand => {
        //     if (defaultBrand === brand?.label) {
        //         setSelectedBrand({ label: brand?.label, value: defaultBrand })
        //         setAnchorElForBrand(null);
        //     }
        // });
    }, [])
    useEffect(() => {
        getStoreList(selectedBrand?.value)
    }, [defaultBrand])


    useEffect(() => {
        let param = '';
        if (itemMasterFilter?.barcode) {
            param = param + `&barcode=${itemMasterFilter?.barcode?.filter}`;
        }
        if (itemMasterFilter?.styleCode) {
            param = param + `&style_code=${itemMasterFilter?.styleCode?.filter}`;
        }
        if (itemMasterFilter?.categoryDivision) {
            param = param + `&category_division=${itemMasterFilter?.categoryDivision?.filter}`;
        }
        if (itemMasterFilter?.categoryGroup) {
            param = param + `&category_group=${itemMasterFilter?.categoryGroup?.filter}`;
        }
        if (itemMasterFilter?.categorySection) {
            param = param + `&category_presence=${itemMasterFilter?.categorySection?.filter}`;
        }
        // getBrandMasterList(param);
    }, [selectedPage, defaultBrand, itemMasterFilter])


    const onSelectDownloadCategory = (event) => {
        if (event?.target.value.toUpperCase() === 'Template'.toUpperCase()) {
            onClickDownloadTemplate();
        } else {
            let requestBody = ''
            downloadBrandMasterReport(requestBody);
        }
        setAnchorElForExport(null);
    }
    const handleMonthClick = (event) => {
        setAnchorElForMonth(event.currentTarget);
    };

    const handleMonthClose = () => {
        setAnchorElForMonth(null);
    };
    const onSelectMonth = (event) => {
        MonthList.forEach(year => {
            if (event.target.value === year?.value) {
                setSelectedMonth({ label: year?.label, value: event.target.value })
                setAnchorElForMonth(null);
                // setLoading(true);
            }
        })
    }

    const onClickDownloadTemplate = () => {
        // setLoading(true);
        // let param = `?template_type=item_master_templete`
        // DownloadTemplate(param, USER_TOKEN).then(response => {
        //     if (response.data.statusCode === 200) {
        //         window.location.href = response.data.data
        //         ToastSuccessService('Template Downloaded Successfully.')
        //     } else {
        //         ToastErrorService(response.data.message);
        //     }
        //     // setOpenUploadFileModal(false);
        // }).catch(error => {
        //     ToastErrorService('Something went wrong, Please try again after sometime.');
        // }).finally(() => {
        //     setLoading(false);
        // })
    }
    const downloadBrandMasterReport = (body) => {
        // let param = `?brand_name=${defaultBrand}`
        // DownaloadBrandItemMaster(param).then(response => {
        //     const responseInUnit = new Uint8Array(response.data.buffer.data);
        //     const blob = new Blob([responseInUnit], { type: 'application/octet-stream' });
        //     saveAs(blob, response.data.filename);
        //     if (response?.data?.buffer?.statusCode === 400) {
        //         ToastErrorService(response?.data?.buffer?.message);
        //     } else {
        //         ToastSuccessService('Report downloaded successfully.');
        //     }
        // }).catch(error => {
        //     if (error.response.status === 400) {
        //         ToastErrorService(error?.response?.data?.message);
        //     } else {
        //         ToastErrorService('Something went wrong, Please try again after sometime.');
        //     }
        //     if (error.response.status === 401) {
        //         history.push('/')
        //     }
        // }).finally(() => {
        //     setLoading(false);
        // })
    }

    const onClickDownloadReport = (event) => {
        setAnchorElForExport(event.currentTarget)
    }

    const handlePageChange = (pageValue) => {
        dispatch(brandManagementActions.setSelectedPageForMatser({ type: pageValue }));
    }

    const handleExportClose = () => {
        setAnchorElForExport(null);
    }

    const onClickImport = () => {
        setOpenFileUploadModal(true);
    }
    const onGridReady = (params) => {
        // params.api.setFilterModel(itemMasterFilter);
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    const handleFileDrop = (files) => {
        const fileUploaded = files[0];
        if (fileUploaded) {
            setUploadedFile(fileUploaded);
            setProgress(0);
            simulateUpload();
            setOpenFileSelectedModal(true);
            setOpenFileUploadModal(false);
        }
    };

    const uploadBulkList = () => {
        // setLoading(true);
        // const formData = new FormData();
        // formData.append('file', uploadedFile);
        // UploadBrandMasterList(formData, USER_TOKEN).then(response => {
        //     if ((response?.status === 200 || response?.status === 201) && response?.data?.statusCode !== 400) {
        //         setOpenSuccessPopup(true);
        //         setOpenFileSelectedModal(false);
        //         setTimeout(() => {
        //             setOpenSuccessPopup(false);
        //         }, 5000)
        //     } else if (response?.data?.statusCode === 400) {
        //         ToastErrorService(response?.data?.message)
        //     }

        // }).catch(error => {
        // }).finally(() => {
        //     setLoading(false);
        // })
    };

    const simulateUpload = () => {
        setUploadButtonDisabled(true);
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + 25;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    setUploadButtonDisabled(false);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000;
                const speed = (nextProgress / elapsedTime).toFixed(2);
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
    };

    const removeFile = () => {
        setUploadedFile([]);
    }

    const handleBrandClick = (event) => {
        setAnchorElForBrand(event.currentTarget);
    };
    const handleBrandClose = () => {
        setAnchorElForBrand(null);
    };

    const onSelectBrand = (event) => {
        brandList.forEach(brand => {
            if (event.target.value === brand?.value) {
                setSelectedBrand({ label: brand?.label, value: event.target.value })
                setAnchorElForBrand(null);
                dispatch(salesActions.setDefaultBrandForRecon({ type: brand }));
            }
        });
        // getStoreList(event.target.value)
    }

    const getAllBrand = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element._id
                    }
                    brands.push(brandObject)
                });
                setBrandList(brands);
                // getStoreList(brands[0]?.value)
                // dispatch(salesActions.setDefaultBrandForRecon({ type: brands[0] }));
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
        })
    }

    const getBrandList = () => {
        let param = `?user_id=${USER_ID}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: item?.brand_name,
                    value: item?.brand_name,
                    brand_id: item?._id
                }
                dropdownData.push(row)
            })
            setBrandNameList(dropdownData);
            // dispatch(userManagementActions.setBrandList({ type: dropdownData }));

        }).catch(error => {
            console.log(error)
        });
    }

    const valueCheck = (value) => {
        if (value && value !== 'null') {
            return value;
        } else {
            return '-';
        }
    }

    const getStoreList = (brand_id) => {
        setLoading(true);
        let param = `?brand_id=${brand_id}`;
        GetStoreCustomerNameList(param, USER_TOKEN).then(response => {
            let tableData = [];
            response?.data?.data?.map(item => {
                let row = {
                    codeStore: valueCheck(item?.store_code),
                    nameStore: valueCheck((item?.store_trade_name && item?.store_trade_name !== 'null') ? item?.store_trade_name : item?.customer_name),
                    email: valueCheck(item?.store_email_id),
                    id: item?._id
                }
                tableData.push(row)
            })
            setReconReportTableData(tableData);
        }).catch(error => {

        }).finally(() => {
            setLoading(false);
        })
    }

    const onFilterChanged = (event) => {
        let filterModel = event.api.getFilterModel();
        dispatch(brandManagementActions.setMasterFilter({ type: filterModel }))
        // getStoreList(param);
    };

    const downloadReport = (event, action) => {
        setLoading(true);
        if (action === 'download') {
            let param = `?store=${event.data.id}&brand=${selectedBrand?.value}&report=Sales&service=recon`;
            if (event.colDef.field === 'reconFileDownload') {
                param = param + `&view_range=${selectedMonthYear}`
            } else {
                let currentYear = new Date().getFullYear()
                param = param + `&view_range=${currentYear}`
            }
            DownloadConsolidatedReportList(param, USER_TOKEN).then(response => {
                if (response.data.statusCode === 200) {
                    window.open(response.data.presignedFileUrl, '_blank');
                    ToastSuccessService('Sales report downloaded successfully.');
                } else if (response.data.statusCode === 400) {
                    ToastErrorService(response.data.message);
                }
            }).catch(error => {
                ToastErrorService(error?.response.data.message);
            }).finally(() => {
                setLoading(false);
            })
        } else {
            let param = `?store=${event.data.id}&brand=${selectedBrand?.value}&report=Sales&service=recon`;
            if (event.colDef.field === 'reconFileDownload') {
                param = param + `&view_range=${selectedMonthYear}`
            } else {
                let currentYear = new Date().getFullYear()
                param = param + `&view_range=${currentYear}`
            }
            RefreshReconReports(param, USER_TOKEN).then(response => {
                if (response.data.statusCode === 200) {
                    ToastSuccessService('Reports are being refreshed, please check after sometime.');
                } else if (response.data.statusCode === 400) {
                    ToastErrorService(response.data.message);
                }
            }).catch(error => {
                if (error.response.status === 401) {
                    history.push('/')
                }
                ToastErrorService(error.response.data.message);
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };

    const onMonthSelect = (value) => {
        setSelectedMonthYear(convertDateIntoMonth(value));
    }

    const onClickCreateCustomReport = () => {
        setOpenCustomReport(true);
        setInputValue({
            brand: [],
            store: [],
            report: 'Sales',
            service: 'good_sales',
            from_date: null,
            to_date: null
        })
        setStartDate(null);
        setEndDate(null);
    }

    const customReportList = () => {
        let param = '?view_range=Custom&report_type=recon'
        GetCustomReportList(param).then(response => {
            let rowdata = []
            response.data.data.forEach(item => {
                let row = {
                    description: item.description,
                    id: item._id,
                    from_date: item.from_date,
                    to_date: item.to_date
                }
                rowdata.push(row)
            })
            setCustomReportData(rowdata)
        }).catch(error => {
            console.log(error)
        }).finally(() => {

        })
    }

    function toUTCStartOfDay(date) {
        const localMidnight = setMilliseconds(setSeconds(setMinutes(setHours(date, 0), 0), 0), 0);
        return new Date(localMidnight.getTime() - localMidnight.getTimezoneOffset() * 60000);
    }

    function toUTCEndOfDay(date) {
        const localEndOfDay = setMilliseconds(setSeconds(setMinutes(setHours(date, 23), 59), 59), 999);
        return new Date(localEndOfDay.getTime() - localEndOfDay.getTimezoneOffset() * 60000);
    }

    const displayDescription = (item, from) => {
        let strToArray = item.description.split(",");
        if (from === 'table') {
            return <>
                <div className='ps-1'>{moment.utc(item.from_date).format("DD MMM YYYY")} - {moment.utc(item.to_date).format("DD MMM YYYY")}</div>
                <ol id='table-desc'>
                    {strToArray.splice(0, 2).map(item => (<li>{item}</li>))}
                </ol>
            </>
        } else {
            return <>
                <ol id='table-desc'>
                    {strToArray.map(item => (<li>{item}</li>))}
                </ol>
            </>
        }
    }

    const convertDates = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

        return moment(dateString).format("DD MMM YYYY");
    }

    const generateReport = () => {
        setLoading(true);
        setOpenCustomReport(false);
        let brands = []
        inputValue.brand.forEach(item => {
            brands.push(item.brand_id)
        })
        let stores = []
        inputValue.store.forEach(item => {
            stores.push(item.store_id)
        })
        let reqBody = {
            from_date: toUTCStartOfDay(startDate),
            to_date: toUTCEndOfDay(endDate),
            brand: brands,
            store: stores,
            report: "Sales",
            service: "good_sales"
        }
        CreateCustomReconReport(reqBody, USER_TOKEN).then(response => {
            if (response.status === 200) {
                ToastSuccessService('We are processing the reports in background, you can check after sometime for the report.')
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            setLoading(false);
        })

    }

    const downloadCustomReport = (data) => {
        let param = `?id=${data.id}`
        downloadConsolidatedReports(param);
    }

    const onClickInfo = (id) => {
        setViewAllInfo(true);
        const selectedRow = customReportData?.find(item => item?.id === id);
        setSelectedItem(selectedRow)
    }

    const downloadConsolidatedReports = (params) => {
        DownloadConsolidatedReportList(params, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                window.open(response.data.presignedFileUrl, '_blank');
                ToastSuccessService('Recon report downloaded successfully.');
            } else if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const openCalendar = () => {
        setCalendarOpen(prev => !prev)
    }

    const onSelectValue = (event, name) => {
        const {
            target: { value },
        } = event;
        if (name === 'store') {
            let storeObject = [];
            let storeIds = [];
            value.map(storeName => {
                const selectedStore = storeNameListForCustom?.find(store => store?.value === storeName);
                let storeSelected = {
                    store_id: selectedStore?.store_id,
                    store_trade_name: selectedStore?.store_trade_name !== 'null' ? selectedStore?.store_trade_name : selectedStore?.label,
                    store_code: selectedStore?.store_code,
                    store_name: selectedStore?.label
                }
                storeObject.push(storeSelected);
                storeIds.push(selectedStore?.store_id);
            }).filter(storeName => storeName);
            setInputValue(values => ({
                ...values, 'store': storeObject,
            }));

        } else if (name === 'brand') {
            let brandObject = [];
            let brandIdsObject = [];
            value.map(brandName => {
                const selectedBrand = brandNameList?.find(brand => brand?.value === brandName);
                let brandSelected = {
                    brand_name: selectedBrand?.label,
                    brand_id: selectedBrand?.brand_id
                }
                brandObject.push(brandSelected);
                brandIdsObject.push(brandSelected?.brand_id);
            }).filter(brand => brand);
            setInputValue(values => ({
                ...values, 'brand': brandObject, store: []
            }));
            setBrandNameQueryParm(brandIdsObject);
            getStoreListForCustom(brandIdsObject)
        }
    }

    const getStoreListForCustom = (brandNameQueryParm) => {
        let queryParams = `?brand_id=${brandNameQueryParm ? brandNameQueryParm?.join(',') : ''}`;
        GetStoreCustomerNameList(queryParams, USER_TOKEN).then(response => {
            let dropdownData = [];
            response?.data?.data?.map(item => {
                let row = {
                    label: `${item?.customer_name} - ${item.brand_name}`,
                    value: item?.store_code,
                    store_code: item?.store_code,
                    store_id: item?._id,
                    store_trade_name: item?.store_trade_name
                }
                dropdownData.push(row);
            })
            setStoreNameListForCustom(dropdownData);
            // dispatch(userManagementActions.setStoreList({ type: dropdownData }));
        }).catch(error => {
            console.log(error)
        });
    }


    const convertDate = (createdDate) => {
        let date = new Date(createdDate);
        // Extract the date part
        let year = date.getFullYear();
        let month = date.getMonth() + 1; // Month is zero-based, so we add 1
        let day = date.getDate();

        // Create a new date string in the format YYYY-MM-DD
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD-MMM-YYYY");
    }

    const handleDateRangeChange = (key, value, optionsList, index) => {

        const ONE_DAY = 1;
        const TWO_MONTHS = 60

        const dayDifference = startDate ? differenceInDays(value, startDate) : null;
        if (!startDate || (startDate && endDate)) {
            setStartDate(value);
            setEndDate(null);
            setSelectedRange([value, null]);
        } else if (startDate && !endDate && value >= startDate) {
            if (dayDifference >= ONE_DAY && dayDifference <= TWO_MONTHS) {
                setEndDate(value);
                setSelectedRange([startDate, value]);
                setInputValue(prevState => ({
                    ...prevState,
                    from_date: startDate,
                    to_date: value
                }));
            } else {
                ToastErrorService('Please select the date up to 60 days')
            }
        } else if (startDate && !endDate && value < startDate) {
            setStartDate(value);
            setSelectedRange([value, null]);
        }
    };

    return (
        <div className='notoSansFont'>
            <div className='d-flex justify-content-end'>
                <div className='d-flex align-items-center first-filter'>
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleBrandClick}>
                            <p className='text-truncate' title={selectedBrand.label}>{selectedBrand?.label}</p>
                            <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterSearchDropdown customClass='upload-file' data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                            onChange={onSelectBrand} selectedValue={selectedBrand?.value} category={'Brand'}
                            dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                    </div>
                </div>
                <div className='position-relative'>
                    <CustomMonthYearPicker maxDateRange={dayjs()} disabled={false} value={selectedMonthYear} defaultValue={dayjs(selectedMonthYear)} onDateChange={(newValue) => onMonthSelect(newValue)} required customClass={'customeClass'} />
                </div>
            </div>
            <div className='reportTableList'>
                <div>
                    <div className='d-flex justify-content-between align-items-center montSerratFont' >
                        <div className='fontSize18 pt-3 pb-3'>Recon Report</div>
                        {/* <CustomBlueButton onClick={onClickUnprocessedReport} title='Unprocessed Report' /> */}
                    </div>
                    <div className='tableContainer reports'>
                        <CustomTableWithFilter onFilterChanged={onFilterChanged} totalRow={() => { }} onClickDownloadReport={downloadReport} column={ReconReportHeader} tableRowData={reconReportTableData} onClickView={null} onGridReady={onGridReady} filterModel={null} customeHeightClass={'tableCustomeHeightClass'} />
                    </div>
                </div>
                <div>
                    <div className='montSerratFont fontSize18 d-flex justify-content-between align-items-center'>
                        <div className='py-3'>Custom Report</div>
                        <CustomBlueButton title={'Create Custom Report'} onClick={onClickCreateCustomReport} />
                    </div>
                    <div className='tableContainer reports'>
                        {/* <CustomTableWithFilter totalRow={() => { }} isContentLeftRightFixed={false} height={450} column={salesCustomTableHeader} tableRowData={customReportData} onClickDownloadReport={downloadCustomReport} onClickView={onClickView} onFilterChanged={onFilterChanged} onGridReady={onGridReady} filterModel={tableFilterValue} customeHeightClass={'tableCustomeHeightClass'} /> */}
                        <div className=''>
                            <div className='table-row table-row-header'>
                                <div className='table-header' >Description</div>
                                <div className='table-header' >Action</div>
                            </div>
                            <div className='table-cont pt-1 custom-report'>
                                {customReportData.map(item => (<div className='table-row ps-0'>
                                    <div className='table-data table-row-data custom-report' >{displayDescription(item, 'table')}</div>
                                    <div className='table-data d-flex'>
                                        <div className="h100 actionBtnContainer">
                                            <button title="Download" className={`tableActionButtons d-flex align-items-center justify-content-center passReport`}
                                                onClick={() => downloadCustomReport(item)}>
                                                <img src={DownloadIcon} alt="" />
                                            </button>
                                            <button onClick={() => onClickInfo(item.id)}>
                                                <InfoOutlined />
                                            </button>
                                        </div>
                                    </div>
                                </div>))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-3'>

                {/* <div className='paddingTop16 d-flex justify-content-end customPagination'>
                    <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalCount} />
                </div> */}
            </div>

            {/* Upload File Modal */}
            <CustomModal open={openFileUploadModal} style={uploadFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileUploadModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className=''>
                            <div className='channelDisplayData'>
                                <CustomDropzone filesAccepted={['.xlsx', '.csv']} onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                        <div className='textColorGray fontSize12 pt-3'>Or</div>
                                    </div>
                                    <div className='pt-3'>
                                        <CustomBlueButton onClick={null} title={'Choose File'} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='pt-3 d-flex justify-content-between align-items-center'>
                                <div className='fontSize12 textColorGray'>Download excel file template</div>
                                <div>
                                    <SecondaryButton onClick={onClickDownloadTemplate} title={<div className='d-flex align-items-center'>
                                        <div>
                                            <img src={ExportIcon} alt="" />
                                        </div>
                                        <div className='fontBold'>
                                            Download Template
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* Upload file progress Modal */}
            <CustomModal classes="customeProgressModal" open={openFileSelectedModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileSelectedModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className='dropZoneContentContainer'>
                            <div className='channelDisplayData'>
                                <CustomDropzone filesAccepted={['.xlsx', '.csv']} onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent uploadedFileDropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <SecondaryButton onClick={handleFileDrop} title={<span className='fontSemiBold textColorBlue'>Choose File</span>} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='dropZoneContentRight'>
                                {uploadedFile?.path && <div>
                                    <p className='uploadingP'>Uploading</p>
                                    <div className='paddingTop24'>
                                        <ProgressBarForFileUpload onClickImageCloseIcon={removeFile} progress={progress} File={uploadedFile} uploadSpeed={uploadSpeed} />
                                    </div>
                                    <div className='pt-3 CustomBlueButtonConatiner' >
                                        <CustomBlueButton customCreatePropsBtnClass="customCreatePropsBtnClass" disabled={uploadButtonDisabled} onClick={uploadBulkList} title={'Upload'} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* File Uploaded Successfully Modal */}
            <SuccessPopup open={openSuccessPopup} closable={false} onClose={() => setOpenSuccessPopup(false)} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>File Uploaded Successfully!</div>
                    <div className='fontSize12 textColor333'>We will be processing the data in background, you can continue with your work.</div>
                </div>
            </div>} />
            {/* create custom report */}
            <CustomModal classes="customeProgressModal" open={openCustomReport} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Generate Custom Report</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenCustomReport(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex paddingTop24 align-items-center justify-content-between'>
                        <div className='d-flex'>
                            <div style={{ width: '250px' }}>
                                <MultiSelectCheckBox MenuProps={MenuProps} errored={false} errorMsg={''} selectedValue={inputValue?.brand?.map(brand => brand?.brand_name)} handleChange={(e) => onSelectValue(e, 'brand')} required options={brandNameList} label='Brand Name*' />
                            </div>
                            <div style={{ width: '250px' }} className='ps-2'>
                                <MultiSelectCheckBox MenuProps={MenuProps} errored={false} errorMsg={''} selectedValue={inputValue?.store?.map(stores => stores?.store_code)} handleChange={(e) => onSelectValue(e, 'store')} required options={storeNameListForCustom} label='Store Name*' />
                            </div>
                            <div style={{ width: '270px' }} className='ps-2'>
                                <div className='inputDateRangeBottomSpace'>
                                    <div onClick={openCalendar} className='open_calendar_open'>
                                        <InputTextField
                                            type='text'
                                            disabled={false}
                                            name='dateRange'
                                            value={
                                                startDate || endDate
                                                    ? `${startDate ? convertDate(startDate) : ''} ${(startDate && endDate) ? '-' : ''} ${endDate ? convertDate(endDate) : ''}`
                                                    : ''
                                            }
                                            onChange={() => { }}
                                            required
                                            minLength={10}
                                            maxLength={10}
                                            label='Date'
                                            calendarIcon={true}
                                        />
                                    </div>
                                    <div className=''>
                                        <CustomDateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            calendarOpen={calendarOpen}
                                            selectedRange={selectedRange}
                                            handleSelect={(date, newVal) => handleDateRangeChange('dateRange', date, '', '')}
                                            closeOnOutSide={() => setCalendarOpen(false)}
                                            restrictFutureDates={true}
                                        />
                                    </div>
                                    {/* {formDataError?.date && <div className="error">{formDataError?.date}</div>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24 d-flex justify-content-center'>
                        <div className='widthFit'>
                            <CustomBlueButton customCreatePropsBtnClass="custom-close-btn" disabled={inputValue.brand === '' || inputValue.store === '' || inputValue.from_date === null || inputValue.to_date === null} onClick={generateReport} title={'Generate Report'} />
                        </div>
                    </div>
                </div>} />
            {/* view all info */}
            <CustomModal classes="customeProgressModal" open={viewAllInfo} style={viewReportFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div className=''>Report Details <span className='fontSize14'>({convertDates(selectedItem.from_date)} - {convertDates(selectedItem.to_date)})</span></div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setViewAllInfo(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='custom-height custom-report'>
                        <div className='' >{displayDescription(selectedItem, 'popup')}</div>
                    </div>
                </div>} />
            {loading && <Loader />}
        </div>
    )
}

export default ReconReport