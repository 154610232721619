import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './sidebar.css';
import { useDispatch, useSelector } from "react-redux";
import { userAuthActions } from '../../store/reducers/UserAuthReducers';
import { brandManagementActions } from '../../store/reducers/BrandManagementReducer';
import { storeManagementActions } from '../../store/reducers/StoreManagementReducer';
import { userManagementActions } from '../../store/reducers/UserManagementReducer';
import { feedbackActions } from '../../store/reducers/FeedbackReducer';
import { sidebarActions } from '../../store/reducers/SidebarReducer';
import { salesActions } from '../../store/reducers/SalesOverviewReducer';
import { stockActions } from '../../store/reducers/StockOverviewReducer';
import { targetVsAchievementActions } from '../../store/reducers/TargetVsAchievement.reducer';
import { promotionActions } from '../../store/reducers/PromotionReducer';
import { employeeStatusActions } from '../../store/reducers/EmployeeStatusReducer';
import dayjs from 'dayjs';
import { incentiveActions } from '../../store/reducers/IncentiveReducer';
import AppLogo from '../../assets/png/dataflow logo.png';

const Sidebar = () => {

    const activePage = useSelector((state) => state.userAuthentication.activePage);
    const activeSidebarForMobile = useSelector((state) => state.sidebar.isSidebarVisible);
    const dispatch = useDispatch();
    const history = useHistory();
    const TOKEN = useSelector(state => state.userAuthentication.userToken);

    useEffect(() => {
        dispatch(sidebarActions.setIsSidebarVisible({ type: false }));
    }, [])

    useEffect(() => {
        checkForToken();
    }, [TOKEN]);

    const checkForToken = () => {
        if (!TOKEN) {
            history.push('/')
        }
    }

    const onClickSideMenu = (active, headerName) => {
        // setActivePage(active);
        if (active !== activePage) {
            dispatch(brandManagementActions.setSelectedPage({ type: 1 }));
            dispatch(storeManagementActions.setSelectedPage({ type: 1 }));
            dispatch(userAuthActions.setHeaderName({ type: headerName }));
            dispatch(userAuthActions.setActivePage({ type: active }));
            dispatch(userAuthActions.setIsBackBtnActive({ type: false }));
            dispatch(feedbackActions.setSelectedPage({ type: 1 }));
            dispatch(userManagementActions.setSelectedPage({ type: 1 }));
            dispatch(sidebarActions.setIsSidebarVisible({ type: false }));
            dispatch(salesActions.setDefaultYear({ type: {} }));
            dispatch(brandManagementActions.setDefaultYear({ type: {} }));
            dispatch(salesActions.setDefaultBrand({ type: {} }));
            dispatch(stockActions.setDefaultYear({ type: '' }));
            dispatch(stockActions.setDefaultBrand({ type: '' }));
            dispatch(targetVsAchievementActions.setDefaultYear({ type: '' }));
            dispatch(targetVsAchievementActions.setDefaultBrand({ type: '' }));
            dispatch(promotionActions.setStartDate({ type: '' }));
            dispatch(promotionActions.setEndDate({ type: '' }));
            dispatch(promotionActions.setFilterModel({ type: {} }));
            dispatch(targetVsAchievementActions.setFilterModel({ type: {} }));
            dispatch(feedbackActions.setTableFilters({ type: {} }));
            dispatch(employeeStatusActions.setSelectedBrandId({ type: { label: '', value: '' } }));
            dispatch(employeeStatusActions.setSelectedCurrentDate({ type: dayjs() }));
            dispatch(incentiveActions.setDefaultBrand({ type: {} }));
            dispatch(brandManagementActions.setBackActiveStep({ type: false }));
            dispatch(storeManagementActions.setBackActiveStep({ type: false }));
            dispatch(brandManagementActions.setBrandDropdownList({ type: [] }));
        }
    }

    const handleSidebarForMobile = () => {
        dispatch(sidebarActions.setIsSidebarVisible({ type: false }));
    }

    return (
        <>
            <div className={`appSidebarCont ${activeSidebarForMobile ? 'inActive' : ''}`}>
                <div className='appSidebarOverlayForMobile' onClick={handleSidebarForMobile}></div>
                <div className='appSidebar appSidebarMobile scrollChange'>
                    <div className='sidebarContent'>
                        <div id='logodiv'>
                            <div className='closedSidebar' >
                                <div className='d-flex align-items-center justify-content-center pb-4 mobileNone'>
                                    <div className='closedSidebarLogo'>
                                        <img src={AppLogo} alt="" height={40} />
                                    </div>
                                </div>
                                <div className='pt-2 px-3'>
                                    <div className=''>
                                        <Link to={'/app/viewCardBrandManagement'} onClick={() => onClickSideMenu('/brandManagement', 'Brand Management')}>
                                            <div className={`d-flex ${activePage === '/brandManagement' ? 'activeSidebarClosedBg' : 'text-white'} sidebarPadding`}>
                                                <div className=''>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g id="Group_182293" data-name="Group 182293" transform="translate(-1999 -959)">
                                                            <rect id="Rectangle_148453" data-name="Rectangle 148453" width="24" height="24" transform="translate(1999 959)" fill="none" />
                                                            <g id="shop_69865" transform="translate(2005 962)">
                                                                <g id="Icons_44_" transform="translate(0)">
                                                                    <g id="Group_164979" data-name="Group 164979">
                                                                        <path id="Path_49714" data-name="Path 49714" d="M221.363,77.023a.94.94,0,1,0-.939-.94A.941.941,0,0,0,221.363,77.023Z" transform="translate(-215.147 -72.396)" fill={`${activePage === '/brandManagement' ? '#fff' : '#7363D6'}`} />
                                                                        <path id="Path_49715" data-name="Path 49715" d="M88.7,5.485,83.981.471a1.5,1.5,0,0,0-2.181,0L77.081,5.485a1.5,1.5,0,0,0-.407,1.026V16.5a1.5,1.5,0,0,0,1.5,1.5H87.61a1.5,1.5,0,0,0,1.5-1.5V6.511A1.5,1.5,0,0,0,88.7,5.485ZM82.891,1.824a1.9,1.9,0,1,1-1.9,1.9A1.905,1.905,0,0,1,82.891,1.824Zm3.9,9.448-1.479,1.441a.432.432,0,0,0-.124.38l.349,2.036a.429.429,0,0,1-.623.453l-1.828-.961a.429.429,0,0,0-.4,0l-1.828.961a.429.429,0,0,1-.623-.453l.349-2.036a.428.428,0,0,0-.123-.38l-1.479-1.441a.43.43,0,0,1,.239-.733l2.044-.3a.429.429,0,0,0,.323-.235l.914-1.852a.429.429,0,0,1,.77,0l.914,1.852a.429.429,0,0,0,.324.235l2.044.3a.43.43,0,0,1,.238.733Z" transform="translate(-76.674 0)" fill={`${activePage === '/brandManagement' ? '#fff' : '#7363D6'}`} />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='pt-4'>
                                        <Link to={'/app/storeDashboard'} onClick={() => onClickSideMenu('/storeManagement', 'storeManagement')}>
                                            <div className={`d-flex ${activePage === '/storeManagement' ? 'activeSidebarClosedBg' : 'text-white'} sidebarPadding`}>
                                                <div className=''>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g id="Group_182294" data-name="Group 182294" transform="translate(-2043 -959)">
                                                            <rect id="Rectangle_148453" data-name="Rectangle 148453" width="24" height="24" transform="translate(2043 959)" fill="none" />
                                                            <g id="store_726569" transform="translate(2046 963)">
                                                                <g id="Group_164981" data-name="Group 164981" transform="translate(0 0)">
                                                                    <g id="Group_164980" data-name="Group 164980">
                                                                        <path id="Path_49716" data-name="Path 49716" d="M18.268,37.01l-1.034-4.144A1.142,1.142,0,0,0,16.126,32H2.161a1.144,1.144,0,0,0-1.11.866L.017,37.01A.56.56,0,0,0,0,37.148a2.793,2.793,0,0,0,2.714,2.86A2.666,2.666,0,0,0,4.857,38.9a2.628,2.628,0,0,0,4.286,0,2.628,2.628,0,0,0,4.286,0,2.664,2.664,0,0,0,2.143,1.106,2.793,2.793,0,0,0,2.714-2.86A.56.56,0,0,0,18.268,37.01Z" transform="translate(0 -32)" fill={`${activePage === '/storeManagement' ? '#fff' : '#7363D6'}`} />
                                                                    </g>
                                                                </g>
                                                                <g id="Group_164983" data-name="Group 164983" transform="translate(1.144 8.463)">
                                                                    <g id="Group_164982" data-name="Group 164982">
                                                                        <path id="Path_49717" data-name="Path 49717" d="M46.426,269.857a3.735,3.735,0,0,1-2.143-.673,3.841,3.841,0,0,1-4.285,0,3.842,3.842,0,0,1-4.285,0A3.706,3.706,0,0,1,32,269.5v6.076a1.144,1.144,0,0,0,1.143,1.144h4.571v-4.576h4.571v4.576h4.571A1.144,1.144,0,0,0,48,275.577V269.5A3.7,3.7,0,0,1,46.426,269.857Z" transform="translate(-32 -269.184)" fill={`${activePage === '/storeManagement' ? '#fff' : '#7363D6'}`} />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='pt-4'>
                                        <Link to={'/app/teamManagement'} onClick={() => {
                                            onClickSideMenu('/teamManagement', 'User Management');
                                            dispatch(userManagementActions.setTableFilters({ type: {} }));
                                        }}>
                                            <div className={`d-flex ${activePage === '/teamManagement' ? 'activeSidebarClosedBg' : ''} sidebarPadding`}>
                                                <div className=''>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g id="Group_182343" data-name="Group 182343" transform="translate(4704 -12198)">
                                                            <rect id="Rectangle_149775" data-name="Rectangle 149775" width="24" height="24" transform="translate(-4704 12198)" fill="none" />
                                                            <g id="multiple-users-silhouette_33308" transform="translate(-4701 12196.078)">
                                                                <path id="Path_49740" data-name="Path 49740" d="M10.862,8.948a3.271,3.271,0,0,1,1.522,2.43,2.639,2.639,0,1,0-1.522-2.43ZM9.133,14.353a2.64,2.64,0,1,0-2.64-2.64A2.64,2.64,0,0,0,9.133,14.353Zm1.12.18H8.013a3.384,3.384,0,0,0-3.38,3.38v2.739l.007.043.189.059a15.386,15.386,0,0,0,4.6.741,9.435,9.435,0,0,0,4.013-.753l.176-.089h.019v-2.74A3.383,3.383,0,0,0,10.253,14.533Zm4.368-2.725H12.4a3.253,3.253,0,0,1-1,2.266,4.016,4.016,0,0,1,2.869,3.844v.844a9.1,9.1,0,0,0,3.543-.744l.176-.089H18v-2.74A3.384,3.384,0,0,0,14.62,11.808ZM4.5,11.628a2.622,2.622,0,0,0,1.4-.407A3.267,3.267,0,0,1,7.133,9.137c0-.049.007-.1.007-.148A2.64,2.64,0,1,0,4.5,11.628Zm2.371,2.446a3.255,3.255,0,0,1-1-2.254c-.082-.006-.164-.013-.248-.013H3.38A3.384,3.384,0,0,0,0,15.188v2.74l.007.042.189.06A15.848,15.848,0,0,0,4,18.745v-.827A4.017,4.017,0,0,1,6.871,14.074Z" transform="translate(0 0)" fill={`${activePage === '/teamManagement' ? '#fff' : '#7363D6'}`} />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='pt-4'>
                                        <Link to={'/app/salesOverview'} onClick={() => onClickSideMenu('/salesOverview', 'Sales Overview')}>
                                            <div className={`d-flex ${activePage === '/salesOverview' ? 'activeSidebarClosedBg' : ''} sidebarPadding`}>
                                                <div className=''>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g id="Group_182292" data-name="Group 182292" transform="translate(-1911 -959)">
                                                            <rect id="Rectangle_148453" data-name="Rectangle 148453" width="24" height="24" transform="translate(1911 959)" fill="none" />
                                                            <g id="reload_4943845" transform="translate(1913 961.359)">
                                                                <path id="Path_49708" data-name="Path 49708" d="M110.07,103a7.3,7.3,0,1,0,7.07,7.3A7.2,7.2,0,0,0,110.07,103Zm2.1,5.028h.941a.477.477,0,0,1,0,.954h-1.093a2.946,2.946,0,0,1-.627,1.165,3.418,3.418,0,0,1-2.679,1c-.144,0-.292-.005-.444-.015l2.6,2.761a.488.488,0,0,1-.009.675.452.452,0,0,1-.653-.009l-3.507-3.724a.484.484,0,0,1-.13-.347.493.493,0,0,1,.009-.108.463.463,0,0,1,.545-.372c1.748.368,2.956.2,3.589-.511a1.854,1.854,0,0,0,.323-.518h-3.827a.477.477,0,0,1,0-.954h4.033a4.968,4.968,0,0,0-.083-1.184h-3.95a.477.477,0,0,1,0-.954h5.9a.477.477,0,0,1,0,.954H112.1A5.772,5.772,0,0,1,112.169,108.028Z" transform="translate(-100.009 -100.954)" fill={`${activePage === '/salesOverview' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Arrow1" d="M59.841,40.034a.443.443,0,0,0-.566-.082l-.427.269A7.485,7.485,0,0,0,56.2,34.955a9.98,9.98,0,0,0-12.818,0,8.009,8.009,0,0,0-1.874,2.388c-.207.395-.379.777-.409.845a.344.344,0,0,0,.23.461.455.455,0,0,0,.148.025.409.409,0,0,0,.378-.226c.089-.2.295-.637.5-1h0c.059-.107.328-.523.34-.556a8.535,8.535,0,0,1,7.1-3.552c4.421,0,8.04,3.067,8.243,6.9l-.459-.289a.443.443,0,0,0-.566.082.329.329,0,0,0,.093.5l1.075.677a.443.443,0,0,0,.236.067h.021a.443.443,0,0,0,.236-.067l1.075-.677A.329.329,0,0,0,59.841,40.034Z" transform="translate(-39.841 -32.625)" fill={`${activePage === '/salesOverview' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Arrow2" d="M24.916,239.452a.419.419,0,0,0-.508.235,8.578,8.578,0,0,1-.719,1.277,8.561,8.561,0,0,1-7.057,3.482c-4.425,0-8.049-3.067-8.251-6.9l.46.289a.444.444,0,0,0,.236.067.426.426,0,0,0,.33-.149.329.329,0,0,0-.093-.5l-1.076-.677a.494.494,0,0,0-.494,0l-1.076.677a.329.329,0,0,0-.093.5.426.426,0,0,0,.33.149.444.444,0,0,0,.236-.067l.428-.269a7.482,7.482,0,0,0,2.648,5.266,9.694,9.694,0,0,0,6.416,2.33,9.694,9.694,0,0,0,6.416-2.33,8.32,8.32,0,0,0,1.2-1.292c.013-.014.485-.718.654-1.044a4.223,4.223,0,0,0,.286-.6A.35.35,0,0,0,24.916,239.452Z" transform="translate(-6.498 -226.517)" fill={`${activePage === '/salesOverview' ? '#fff' : '#7363D6'}`} />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='pt-4'>
                                        <Link to={'/app/stockOverview'} onClick={() => onClickSideMenu('/stockOverview', 'Stock Overview')}>
                                            <div className={`d-flex ${activePage === '/stockOverview' ? 'activeSidebarClosedBg' : 'text-white'} sidebarPadding`}>
                                                <div className=''>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g id="Group_182328" data-name="Group 182328" transform="translate(-52 -289)">
                                                            <rect id="Rectangle_149773" data-name="Rectangle 149773" width="24" height="24" transform="translate(52 289)" fill="none" />
                                                            <g id="cube_835318" transform="translate(50.229 292)">
                                                                <g id="Group_182327" data-name="Group 182327" transform="translate(4.771)">
                                                                    <path id="Path_49721" data-name="Path 49721" d="M18.283,7.5V2.7L13.6,0,8.923,2.7V7.5l-4.152,2.4v5.4L9.451,18,13.6,15.6,17.756,18l4.68-2.7v-5.4Zm-9.36,8.98-3.1-1.788V11.113l3.1,1.788Zm4.15-5.362h0L9.979,12.9v-.609l-.526-.306,3.1-1.787.525.305Zm0-1.83-1.284-.742L9.979,7.5V3.921l3.1,1.788V9.284ZM13.6,4.795l3.1-1.789.526.306v.609L14.133,5.707V5.1Zm3.625,11.681-3.1-1.788V11.113l3.1,1.788ZM14.659,10.2l-.528-.307V9.284l3.1-1.788v.609l.526.306Zm3.618,2.707v-.617l-.519-.3L20.85,10.2l.53.308v.605h0Z" transform="translate(-4.771)" fill={`${activePage === '/stockOverview' ? '#fff' : '#7363D6'}`} />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='pt-4'>
                                        <Link to={'/app/ruleManagement'} onClick={() => {
                                            onClickSideMenu('/ruleManagement', 'Rule Management');
                                            dispatch(userManagementActions.setTableFilters({ type: {} }));
                                        }}>
                                            <div className={`d-flex ${activePage === '/ruleManagement' ? 'activeSidebarClosedBg' : ''} sidebarPadding`}>
                                                <div className=''>
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="Group_182343" data-name="Group 182343" transform="translate(4704 -12198)">
                                                        <rect id="Rectangle_149775" data-name="Rectangle 149775" width="24" height="24" transform="translate(-4704 12198)" fill="none" />
                                                        <g id="multiple-users-silhouette_33308" transform="translate(-4701 12196.078)">
                                                            <path id="Path_49740" data-name="Path 49740" d="M10.862,8.948a3.271,3.271,0,0,1,1.522,2.43,2.639,2.639,0,1,0-1.522-2.43ZM9.133,14.353a2.64,2.64,0,1,0-2.64-2.64A2.64,2.64,0,0,0,9.133,14.353Zm1.12.18H8.013a3.384,3.384,0,0,0-3.38,3.38v2.739l.007.043.189.059a15.386,15.386,0,0,0,4.6.741,9.435,9.435,0,0,0,4.013-.753l.176-.089h.019v-2.74A3.383,3.383,0,0,0,10.253,14.533Zm4.368-2.725H12.4a3.253,3.253,0,0,1-1,2.266,4.016,4.016,0,0,1,2.869,3.844v.844a9.1,9.1,0,0,0,3.543-.744l.176-.089H18v-2.74A3.384,3.384,0,0,0,14.62,11.808ZM4.5,11.628a2.622,2.622,0,0,0,1.4-.407A3.267,3.267,0,0,1,7.133,9.137c0-.049.007-.1.007-.148A2.64,2.64,0,1,0,4.5,11.628Zm2.371,2.446a3.255,3.255,0,0,1-1-2.254c-.082-.006-.164-.013-.248-.013H3.38A3.384,3.384,0,0,0,0,15.188v2.74l.007.042.189.06A15.848,15.848,0,0,0,4,18.745v-.827A4.017,4.017,0,0,1,6.871,14.074Z" transform="translate(0 0)" fill={`${activePage === '/ruleManagement' ? '#fff' : '#7363D6'}`} />
                                                        </g>
                                                    </g>
                                                </svg> */}
                                                    <svg width="24" height="24" viewBox="0 0 50 56" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="Page-1" fill="none" fillRule="evenodd">
                                                            <g id="002---Browser" fillRule="nonzero">
                                                                <path id="Rectangle-path" d="m5 13.001h40v3.999h-40z" fill={`${activePage === '/ruleManagement' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Shape" d="m0 6h50v-6h-50zm45-4h2c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-2c-.5522847 0-1-.44771525-1-1s.4477153-1 1-1zm-36 0h32c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-32c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1zm-6 0h2c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1h-2c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1z" fill={`${activePage === '/ruleManagement' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Rectangle-path" d="m28 24.001h17v3.999h-17z" fill={`${activePage === '/ruleManagement' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Shape" d="m50 8h-50v48h50zm-47 16c0-1.1045695.8954305-2 2-2h16c1.1045695 0 2 .8954305 2 2v4c0 1.1045695-.8954305 2-2 2h-16c-1.1045695 0-2-.8954305-2-2zm44 27c0 1.1045695-.8954305 2-2 2h-40c-1.1045695 0-2-.8954305-2-2v-16c0-1.1045695.8954305-2 2-2h40c1.1045695 0 2 .8954305 2 2zm0-23c0 1.1045695-.8954305 2-2 2h-17c-1.1045695 0-2-.8954305-2-2v-4c0-1.1045695.8954305-2 2-2h17c1.1045695 0 2 .8954305 2 2zm0-11c0 1.1045695-.8954305 2-2 2h-40c-1.1045695 0-2-.8954305-2-2v-4c0-1.1045695.8954305-2 2-2h40c1.1045695 0 2 .8954305 2 2z" fill={`${activePage === '/ruleManagement' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Rectangle-path" d="m5 24.001h16v3.999h-16z" fill={`${activePage === '/ruleManagement' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Rectangle-path" d="m5 35.001h40v15.999h-40z" fill={`${activePage === '/ruleManagement' ? '#fff' : '#7363D6'}`} />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    {/* <div className='pt-4'>
                                    <Link to={'/dashboard'} onClick={() =>onClickSideMenu()}>
                                    <div className={`d-flex ${activePage === '/dashboard' ? 'activeSidebarClosedBg' : ''} sidebarPadding `}>
                                        <div className=''>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g id="Group_182297" data-name="Group 182297" transform="translate(-2175 -959)">
                                                    <rect id="Rectangle_148453" data-name="Rectangle 148453" width="24" height="24" transform="translate(2175 959)" fill="none" />
                                                    <g id="menu_3388694" transform="translate(2178 962)">
                                                        <path id="Path_49704" data-name="Path 49704" d="M6.891,0H1.3A1.305,1.305,0,0,0,0,1.3V4.656a1.305,1.305,0,0,0,1.3,1.3H6.891a1.305,1.305,0,0,0,1.3-1.3V1.3A1.305,1.305,0,0,0,6.891,0Z" fill={`${activePage === '/dashboard' ? '#fff' : '#7363D6'}`} />
                                                        <path id="Path_49705" data-name="Path 49705" d="M6.891,10H1.3A1.305,1.305,0,0,0,0,11.3v7.822a1.305,1.305,0,0,0,1.3,1.3H6.891a1.305,1.305,0,0,0,1.3-1.3V11.3A1.305,1.305,0,0,0,6.891,10Z" transform="translate(0 -2.43)" fill={`${activePage === '/dashboard' ? '#fff' : '#7363D6'}`} />
                                                        <path id="Path_49706" data-name="Path 49706" d="M19.891,16H14.3A1.305,1.305,0,0,0,13,17.3v3.352a1.305,1.305,0,0,0,1.3,1.3h5.587a1.305,1.305,0,0,0,1.3-1.3V17.3A1.305,1.305,0,0,0,19.891,16Z" transform="translate(-3.195 -3.96)" fill={`${activePage === '/dashboard' ? '#fff' : '#7363D6'}`} />
                                                        <path id="Path_49707" data-name="Path 49707" d="M19.891,0H14.3A1.305,1.305,0,0,0,13,1.3V9.126a1.305,1.305,0,0,0,1.3,1.3h5.587a1.305,1.305,0,0,0,1.3-1.3V1.3A1.305,1.305,0,0,0,19.891,0Z" transform="translate(-3.195)" fill={`${activePage === '/dashboard' ? '#fff' : '#7363D6'}`} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                    </Link>
                                </div> */}
                                    <div className='pt-4'>
                                        <Link to={'/app/targetVsAchievement'} onClick={() => onClickSideMenu('/targetVsAchievement', 'Target Vs Achievement')}>
                                            <div className={`d-flex ${activePage === '/targetVsAchievement' ? 'activeSidebarClosedBg' : 'text-white'} sidebarPadding`}>
                                                <div className=''>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g id="Group_182340" data-name="Group 182340" transform="translate(-37 -516)">
                                                            <rect id="Rectangle_149774" data-name="Rectangle 149774" width="24" height="24" transform="translate(37 516)" fill="none" />
                                                            <g id="target_407061" transform="translate(39.997 519)">
                                                                <g id="Group_182335" data-name="Group 182335" transform="translate(7.3 0.001)">
                                                                    <g id="Group_182334" data-name="Group 182334" transform="translate(0 0)">
                                                                        <path id="Path_49722" data-name="Path 49722" d="M210.385,1.728l-1.25.179.179-1.25a.574.574,0,0,0-.973-.486l-2.293,2.293a.574.574,0,0,0-.162.324l-.26,1.817-2.831,2.831a1.7,1.7,0,0,0-.737-.172,1.72,1.72,0,1,0,1.72,1.72,1.7,1.7,0,0,0-.172-.737l2.831-2.831,1.817-.26a.573.573,0,0,0,.324-.162L210.872,2.7A.574.574,0,0,0,210.385,1.728Z" transform="translate(-200.338 -0.001)" fill={`${activePage === '/targetVsAchievement' ? '#fff' : '#7363D6'}`} />
                                                                    </g>
                                                                </g>
                                                                <g id="Group_182337" data-name="Group 182337" transform="translate(3.641 4.044)">
                                                                    <g id="Group_182336" data-name="Group 182336">
                                                                        <path id="Path_49723" data-name="Path 49723" d="M109.982,114.275l-1.816,1.816a2.891,2.891,0,1,1-2.432-2.432l1.816-1.816a5.179,5.179,0,1,0,2.432,2.432Z" transform="translate(-100.171 -111.334)" fill={`${activePage === '/targetVsAchievement' ? '#fff' : '#7363D6'}`} />
                                                                    </g>
                                                                </g>
                                                                <g id="Group_182339" data-name="Group 182339" transform="translate(0.003 0.384)">
                                                                    <g id="Group_182338" data-name="Group 182338" transform="translate(0)">
                                                                        <path id="Path_49724" data-name="Path 49724" d="M16.79,16.249l-.469.469a1.771,1.771,0,0,1-1,.5l-.637.091A6.479,6.479,0,1,1,11.478,14.1l.091-.637a1.773,1.773,0,0,1,.5-1L12.537,12a8.817,8.817,0,1,0,4.253,4.253Z" transform="translate(-0.003 -11.166)" fill={`${activePage === '/targetVsAchievement' ? '#fff' : '#7363D6'}`} />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className='pt-4'>
                                        <Link to={'/app/employeeStatus'} onClick={() => onClickSideMenu('/employeeStatus', 'Employee Status')}>
                                            <div className={`d-flex ${activePage === '/employeeStatus' ? 'activeSidebarClosedBg' : 'text-white'} sidebarPadding`}>
                                                <div className=''>
                                                    <svg id="glipy" viewBox="0 0 64 64" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="m16.07 15.75a5.885 5.885 0 1 0 -5.89-5.88 5.89169 5.89169 0 0 0 5.89 5.88z" fill={`${activePage === '/employeeStatus' ? '#fff' : '#7363D6'}`} />
                                                        <path d="m16.07 18.12a11.856 11.856 0 0 0 -8.65 3.75 5.39229 5.39229 0 0 0 3.94 9.08h9.42a5.39229 5.39229 0 0 0 3.94-9.08 11.87235 11.87235 0 0 0 -8.65-3.75z" fill={`${activePage === '/employeeStatus' ? '#fff' : '#7363D6'}`} />
                                                        <circle cx="16.07" cy="38.84" r="5.89" transform="matrix(.987 -.16 .16 .987 -6.014 3.076)" fill={`${activePage === '/employeeStatus' ? '#fff' : '#7363D6'}`} />
                                                        <path d="m16.07 47.09a11.89443 11.89443 0 0 0 -8.65 3.75 5.40059 5.40059 0 0 0 3.94 9.09h9.42a5.40059 5.40059 0 0 0 3.94-9.09 11.91094 11.91094 0 0 0 -8.65-3.75z" fill={`${activePage === '/employeeStatus' ? '#fff' : '#7363D6'}`} />
                                                        <path d="m53.04 4.02h-17.99a4.00322 4.00322 0 0 0 -3.99 4v17.99a3.99465 3.99465 0 0 0 3.99 3.99h17.99a3.9947 3.9947 0 0 0 3.99-3.99v-17.99a4.00327 4.00327 0 0 0 -3.99-4zm-2.29 9.7-8 8a1.0011 1.0011 0 0 1 -.7.29 1.02418 1.02418 0 0 1 -.71-.29l-4-4a.99579.99579 0 0 1 0-1.41 1.0079 1.0079 0 0 1 1.42 0l3.29 3.29 7.28-7.29a1.0079 1.0079 0 0 1 1.42 0 .99581.99581 0 0 1 0 1.41z" fill={`${activePage === '/employeeStatus' ? '#fff' : '#7363D6'}`} />
                                                        <path d="m53.04 34h-17.99a3.99461 3.99461 0 0 0 -3.99 3.99v17.99a4.00318 4.00318 0 0 0 3.99 4h17.99a4.00323 4.00323 0 0 0 3.99-4v-17.99a3.99466 3.99466 0 0 0 -3.99-3.99zm-3.25 17.28a.995.995 0 0 1 -.7 1.7 1.00684 1.00684 0 0 1 -.71-.29l-4.29-4.29-4.29 4.29a1.02433 1.02433 0 0 1 -.71.29.995.995 0 0 1 -.7-1.7l4.29-4.29-4.29-4.29a.99588.99588 0 0 1 0-1.41.9784.9784 0 0 1 1.41 0l4.29 4.28 4.29-4.28a.997.997 0 1 1 1.41 1.41l-4.29 4.29z" fill={`${activePage === '/employeeStatus' ? '#fff' : '#7363D6'}`} />
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className='pt-4'>
                                        <Link to={'/app/incentive'} onClick={() => onClickSideMenu('incentive', 'incentive')}>
                                            <div className={`d-flex ${activePage === '/incentive' ? 'activeSidebarClosedBg' : 'text-white'} sidebarPadding`}>
                                                <div className=''>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g id="Group_182341" data-name="Group 182341" transform="translate(4707 -12190)">
                                                            <rect id="Rectangle_149776" data-name="Rectangle 149776" width="24" height="24" transform="translate(-4707 12190)" fill="none" />
                                                            <g id="gift-wrap_11497362" transform="translate(-4704.961 12193)">
                                                                <path id="Path_49725" data-name="Path 49725" d="M1,133.635a1.477,1.477,0,0,0,1.475,1.476H7.654v-2.952H2.475A1.477,1.477,0,0,0,1,133.635Z" transform="translate(0 -127.078)" fill={`${activePage === '/incentive' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49726" data-name="Path 49726" d="M299.506,132.159h-5.179v2.952h5.179a1.476,1.476,0,0,0,0-2.952Z" transform="translate(-282.058 -127.078)" fill={`${activePage === '/incentive' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49727" data-name="Path 49727" d="M51.356,238.507a1.477,1.477,0,0,0,1.475,1.476H56.07v-9.139H51.356Z" transform="translate(-48.415 -221.983)" fill={`${activePage === '/incentive' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49728" data-name="Path 49728" d="M294.327,239.983h3.239a1.477,1.477,0,0,0,1.475-1.476v-7.663h-4.714Z" transform="translate(-282.058 -221.983)" fill={`${activePage === '/incentive' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49729" data-name="Path 49729" d="M250.772.571a1.957,1.957,0,0,0-2.762,0c-.5.5-.868,2.2-1.061,3.686h.983c2.071-.35,2.675-.768,2.839-.932a1.962,1.962,0,0,0,0-2.754Z" transform="translate(-236.496 -0.001)" fill={`${activePage === '/incentive' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49730" data-name="Path 49730" d="M110.543.57a1.95,1.95,0,0,0-2.762,2.754c.164.164.769.582,2.84.932h.983c-.193-1.484-.562-3.189-1.061-3.686Z" transform="translate(-102.135 0)" fill={`${activePage === '/incentive' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49731" data-name="Path 49731" d="M195.73,132.522h2.95V145.45h-2.95Z" transform="translate(-187.243 -127.45)" fill={`${activePage === '/incentive' ? '#fff' : '#7363D6'}`} />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='pt-4'>
                                        <Link to={'/app/promotion'} onClick={() => onClickSideMenu('promotion', 'promotion')}>
                                            <div className={`d-flex ${activePage === '/promotion' ? 'activeSidebarClosedBg' : 'text-white'} sidebarPadding`}>
                                                <div className=''>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <g id="Group_182342" data-name="Group 182342" transform="translate(4707 -12225)">
                                                            <rect id="Rectangle_149777" data-name="Rectangle 149777" width="24" height="24" transform="translate(-4707 12225)" fill="none" />
                                                            <g id="megaphone_3651862" transform="translate(-4714.135 12228)">
                                                                <path id="Path_49732" data-name="Path 49732" d="M12.912,247.447a.3.3,0,0,0-.414-.1l-1.559.962a1.69,1.69,0,0,0-.551,2.327l.958,1.553a1.693,1.693,0,0,0,2.327.551l1.559-.963a.3.3,0,0,0,.1-.414Zm-.595,3.416a.3.3,0,0,1-.167-.551l.451-.3a.3.3,0,0,1,.334.5l-.451.3A.3.3,0,0,1,12.317,250.863Z" transform="translate(0 -238.04)" fill={`${activePage === '/promotion' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49733" data-name="Path 49733" d="M219.47,12.571a1.517,1.517,0,0,1-2.088-.494l-6.024-9.763A1.517,1.517,0,1,1,213.94.721l6.024,9.762a1.519,1.519,0,0,1-.494,2.088Z" transform="translate(-193.48 0)" fill={`${activePage === '/promotion' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49734" data-name="Path 49734" d="M105.029,101.227a12.88,12.88,0,0,0-6.286,1.339.3.3,0,0,1-.389-.111L95.89,98.461a.3.3,0,0,1,.075-.4,12.873,12.873,0,0,0,4.016-5.019.3.3,0,0,1,.529-.033l4.787,7.758A.3.3,0,0,1,105.029,101.227Z" transform="translate(-82.504 -89.394)" fill={`${activePage === '/promotion' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49735" data-name="Path 49735" d="M360.757,100.2l-1.442-2.336a.3.3,0,0,1,.3-.455,2.124,2.124,0,0,1,1.683,2.727A.3.3,0,0,1,360.757,100.2Z" transform="translate(-336.051 -93.75)" fill={`${activePage === '/promotion' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49736" data-name="Path 49736" d="M125.666,363.492a1.406,1.406,0,0,1-.992-.411l-2.241-2.227a.3.3,0,0,1,.054-.469l1.546-.954c.309-.169.619-.323.934-.464a.3.3,0,0,1,.372.107l1.5,2.219A1.411,1.411,0,0,1,125.666,363.492Z" transform="translate(-108.005 -345.492)" fill={`${activePage === '/promotion' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49737" data-name="Path 49737" d="M432.484,83.962a.3.3,0,0,1-.172-.547l.541-.376a.3.3,0,0,1,.343.493l-.541.377A.3.3,0,0,1,432.484,83.962Z" transform="translate(-406.225 -79.873)" fill={`${activePage === '/promotion' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49738" data-name="Path 49738" d="M440.383,175.833a.3.3,0,0,1-.42.068l-.549-.395a.3.3,0,0,1,.351-.488l.549.4A.3.3,0,0,1,440.383,175.833Z" transform="translate(-413.064 -168.402)" fill={`${activePage === '/promotion' ? '#fff' : '#7363D6'}`} />
                                                                <path id="Path_49739" data-name="Path 49739" d="M372.788,26.308v.676a.3.3,0,1,1-.6,0v-.676a.3.3,0,1,1,.6,0Z" transform="translate(-348.477 -25.03)" fill={`${activePage === '/promotion' ? '#fff' : '#7363D6'}`} />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='pt-4'>
                                        <Link to={'/app/feedback'} onClick={() => onClickSideMenu('feedback', 'feedback')}>
                                            <div className={`d-flex ${activePage === '/feedback' ? 'activeSidebarClosedBg' : 'text-white'} sidebarPadding`}>
                                                <div className=''>
                                                    <svg fill={`${activePage === '/feedback' ? '#fff' : '#7363D6'}`} height="20" width="24" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 477.655 477.655" >
                                                        <g>
                                                            <path d="M440.367,440.415l-10.173-29.91c-19.102-56.262-70.83-94.605-129.763-97.121c-5.187,4.03-10.655,7.493-16.322,10.521
                                                            c-8.449,22.185-36.836,28.195-53.468,11.205c-19.676-1.738-37.69-9.511-53.422-21.725c-58.933,2.508-110.647,40.851-129.763,97.121
                                                            L37.3,440.415c-2.936,8.603-1.522,18.084,3.774,25.469c5.279,7.391,13.821,11.771,22.906,11.771h349.693
                                                            c9.083,0,17.626-4.379,22.906-11.771C441.873,458.499,443.286,449.018,440.367,440.415z"/>
                                                            <path d="M277.758,290.619c34.212-24.047,58.141-77.151,58.141-128.491c0-145.907-194.133-145.752-194.133,0
                                                            c0,62.397,35.33,127.303,81.546,139.556c4.456-12.626,16.382-21.757,30.515-21.757C263.331,279.926,271.81,284.095,277.758,290.619
                                                            z"/>
                                                            <path d="M99.169,223.042c4.813,18.906,31.044,13.704,31.044-3.805c0-70.178,3.354-76.731-6.041-84.348
                                                            C145.679,2.361,330.946,3.355,353.495,134.904c-9.381,7.641-6.025,14.163-6.025,84.333c0,5.489,2.95,10.095,7.189,12.952
                                                            c0,54.594-22.145,51.402-88.736,69.052c-10.295-11.174-28.683-3.899-28.683,11.173c0,18.876,27.053,23.293,32.302,4.318
                                                            c53.762-14.256,101.018-18.752,101.018-72.484v-11.027c3.991-2.066,6.817-5.729,7.951-10.179c51.822-1.056,51.838-78.719,0-79.775
                                                            c-1.072-4.24-3.711-7.703-7.423-9.815c1.336-15.902-1.94-36.805-11.057-56.985C296.626-54.368,109.355-3.176,106.422,123.622
                                                            c-0.404,4.294-0.078,7.338,0.17,9.83c-3.712,2.112-6.351,5.575-7.423,9.815c-21.71,0.419-39.212,18.084-39.212,39.888
                                                            C59.957,204.958,77.459,222.622,99.169,223.042z"/>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='pt-4'>
                                        <Link to={'/app/notifications'} onClick={() => onClickSideMenu('notifications', 'notifications')}>
                                            <div className={`d-flex ${activePage === '/notifications' ? 'activeSidebarClosedBg' : 'text-white'} sidebarPadding`}>
                                                <div className=''>
                                                    <svg fill={`${activePage === '/notifications' ? '#fff' : '#7363D6'}`} xmlns="http://www.w3.org/2000/svg" width="16.66" height="19.992" viewBox="0 0 16.66 19.992">
                                                        <g id="bell_1827347" transform="translate(0)">
                                                            <path id="Path_63347" data-name="Path 63347" d="M16.143,13.871a5.459,5.459,0,0,1-1.982-4.2V7.381a5.775,5.775,0,0,0-5-5.675V.82A.833.833,0,0,0,7.5.82v.886a5.775,5.775,0,0,0-5,5.675V9.668A5.465,5.465,0,0,1,.51,13.877,1.424,1.424,0,0,0,0,14.967,1.448,1.448,0,0,0,1.458,16.4H15.2a1.448,1.448,0,0,0,1.458-1.435A1.429,1.429,0,0,0,16.143,13.871Zm0,0" transform="translate(0)" />
                                                            <path id="Path_63348" data-name="Path 63348" d="M138.25,450.692a3.371,3.371,0,0,0,3.3-2.692h-6.595A3.371,3.371,0,0,0,138.25,450.692Zm0,0" transform="translate(-129.92 -430.7)" />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    {/* <div className='pt-4'>
                                    <Link to={'/app/hierarchyManagement'} onClick={() => {
                                        onClickSideMenu('/hierarchyManagement', 'Hierarchy Management');
                                        dispatch(userManagementActions.setTableFilters({ type: {} }));
                                    }}>
                                        <div className={`d-flex ${activePage === '/hierarchyManagement' ? 'activeSidebarClosedBg' : ''} sidebarPadding`}>
                                            <div className=''>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <defs>
                                                        <clipPath id="clipPath">
                                                            <path id="Path_49742" data-name="Path 49742" d="M0,0H18V18H0Z" fill="none" />
                                                        </clipPath>
                                                    </defs>
                                                    <g id="Group_182348" data-name="Group 182348" transform="translate(4705 -12162)">
                                                        <rect id="Rectangle_149778" data-name="Rectangle 149778" width="24" height="24" transform="translate(-4705 12162)" fill="none" />
                                                        <g id="network_8756745" transform="translate(-4702 12165)" clip-path="url(#clip-path)">
                                                            <path id="Path_49741" data-name="Path 49741" d="M15.993,12.819V9H9.458V7.617a3.824,3.824,0,1,0-1.071-.009V9H1.85v3.835a2.63,2.63,0,1,0,1.286-.03V10.286h5.25v2.509a2.63,2.63,0,1,0,1.072,0V10.286h5.25v2.533a2.63,2.63,0,1,0,1.286,0Z" transform="translate(0.049)" fill={`${activePage === '/hierarchyManagement' ? '#fff' : '#7363D6'}`} />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </Link>
                                </div> */}

                                </div>
                            </div>
                        </div>
                        <div id='appLogoHover'>
                            <div className='d-flex justify-content-center align-items-center bgForLogo rounded-[8px] mobileNone'>
                                <img src={AppLogo} alt="" height={50} />
                            </div>
                            <div className='pt-2'>
                                <div className=''>
                                    <Link to={'/app/viewCardBrandManagement'} onClick={() => { onClickSideMenu('/brandManagement', 'Brand Management') }}>
                                        <div className={`d-flex ${activePage === '/brandManagement' ? 'bg-white' : 'text-white'} sidebarPadding`}>
                                            <div className='pe-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="Group_182293" data-name="Group 182293" transform="translate(-1999 -959)">
                                                        <rect id="Rectangle_148453" data-name="Rectangle 148453" width="24" height="24" transform="translate(1999 959)" fill="none" />
                                                        <g id="shop_69865" transform="translate(2005 962)">
                                                            <g id="Icons_44_" transform="translate(0)">
                                                                <g id="Group_164979" data-name="Group 164979">
                                                                    <path id="Path_49714" data-name="Path 49714" d="M221.363,77.023a.94.94,0,1,0-.939-.94A.941.941,0,0,0,221.363,77.023Z" transform="translate(-215.147 -72.396)" fill={`${activePage === '/brandManagement' ? '#7363D6' : '#fff'}`} />
                                                                    <path id="Path_49715" data-name="Path 49715" d="M88.7,5.485,83.981.471a1.5,1.5,0,0,0-2.181,0L77.081,5.485a1.5,1.5,0,0,0-.407,1.026V16.5a1.5,1.5,0,0,0,1.5,1.5H87.61a1.5,1.5,0,0,0,1.5-1.5V6.511A1.5,1.5,0,0,0,88.7,5.485ZM82.891,1.824a1.9,1.9,0,1,1-1.9,1.9A1.905,1.905,0,0,1,82.891,1.824Zm3.9,9.448-1.479,1.441a.432.432,0,0,0-.124.38l.349,2.036a.429.429,0,0,1-.623.453l-1.828-.961a.429.429,0,0,0-.4,0l-1.828.961a.429.429,0,0,1-.623-.453l.349-2.036a.428.428,0,0,0-.123-.38l-1.479-1.441a.43.43,0,0,1,.239-.733l2.044-.3a.429.429,0,0,0,.323-.235l.914-1.852a.429.429,0,0,1,.77,0l.914,1.852a.429.429,0,0,0,.324.235l2.044.3a.43.43,0,0,1,.238.733Z" transform="translate(-76.674 0)" fill={`${activePage === '/brandManagement' ? '#7363D6' : '#fff'}`} />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            Brand Management
                                        </div>
                                    </Link>
                                </div>
                                <div className='pt-4'>
                                    <Link to={'/app/storeDashboard'} onClick={() => { onClickSideMenu('/storeManagement', 'Store Management') }}>
                                        <div className={`d-flex ${activePage === '/storeManagement' ? 'bg-white' : 'text-white'} sidebarPadding`}>
                                            <div className='pe-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="Group_182294" data-name="Group 182294" transform="translate(-2043 -959)">
                                                        <rect id="Rectangle_148453" data-name="Rectangle 148453" width="24" height="24" transform="translate(2043 959)" fill="none" />
                                                        <g id="store_726569" transform="translate(2046 963)">
                                                            <g id="Group_164981" data-name="Group 164981" transform="translate(0 0)">
                                                                <g id="Group_164980" data-name="Group 164980">
                                                                    <path id="Path_49716" data-name="Path 49716" d="M18.268,37.01l-1.034-4.144A1.142,1.142,0,0,0,16.126,32H2.161a1.144,1.144,0,0,0-1.11.866L.017,37.01A.56.56,0,0,0,0,37.148a2.793,2.793,0,0,0,2.714,2.86A2.666,2.666,0,0,0,4.857,38.9a2.628,2.628,0,0,0,4.286,0,2.628,2.628,0,0,0,4.286,0,2.664,2.664,0,0,0,2.143,1.106,2.793,2.793,0,0,0,2.714-2.86A.56.56,0,0,0,18.268,37.01Z" transform="translate(0 -32)" fill={`${activePage === '/storeManagement' ? '#7363D6' : '#fff'}`} />
                                                                </g>
                                                            </g>
                                                            <g id="Group_164983" data-name="Group 164983" transform="translate(1.144 8.463)">
                                                                <g id="Group_164982" data-name="Group 164982">
                                                                    <path id="Path_49717" data-name="Path 49717" d="M46.426,269.857a3.735,3.735,0,0,1-2.143-.673,3.841,3.841,0,0,1-4.285,0,3.842,3.842,0,0,1-4.285,0A3.706,3.706,0,0,1,32,269.5v6.076a1.144,1.144,0,0,0,1.143,1.144h4.571v-4.576h4.571v4.576h4.571A1.144,1.144,0,0,0,48,275.577V269.5A3.7,3.7,0,0,1,46.426,269.857Z" transform="translate(-32 -269.184)" fill={`${activePage === '/storeManagement' ? '#7363D6' : '#fff'}`} />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            Store Management
                                        </div>
                                    </Link>
                                </div>
                                <div className='pt-4'>
                                    <Link to={'/app/teamManagement'} onClick={() => {
                                        onClickSideMenu('/teamManagement', 'User Management');
                                        dispatch(userManagementActions.setTableFilters({ type: {} }));
                                    }}>
                                        <div className={`d-flex ${activePage === '/teamManagement' ? 'bg-white' : 'text-white'} sidebarPadding`}>
                                            <div className='pe-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="Group_182343" data-name="Group 182343" transform="translate(4704 -12198)">
                                                        <rect id="Rectangle_149775" data-name="Rectangle 149775" width="24" height="24" transform="translate(-4704 12198)" fill="none" />
                                                        <g id="multiple-users-silhouette_33308" transform="translate(-4701 12196.078)">
                                                            <path id="Path_49740" data-name="Path 49740" d="M10.862,8.948a3.271,3.271,0,0,1,1.522,2.43,2.639,2.639,0,1,0-1.522-2.43ZM9.133,14.353a2.64,2.64,0,1,0-2.64-2.64A2.64,2.64,0,0,0,9.133,14.353Zm1.12.18H8.013a3.384,3.384,0,0,0-3.38,3.38v2.739l.007.043.189.059a15.386,15.386,0,0,0,4.6.741,9.435,9.435,0,0,0,4.013-.753l.176-.089h.019v-2.74A3.383,3.383,0,0,0,10.253,14.533Zm4.368-2.725H12.4a3.253,3.253,0,0,1-1,2.266,4.016,4.016,0,0,1,2.869,3.844v.844a9.1,9.1,0,0,0,3.543-.744l.176-.089H18v-2.74A3.384,3.384,0,0,0,14.62,11.808ZM4.5,11.628a2.622,2.622,0,0,0,1.4-.407A3.267,3.267,0,0,1,7.133,9.137c0-.049.007-.1.007-.148A2.64,2.64,0,1,0,4.5,11.628Zm2.371,2.446a3.255,3.255,0,0,1-1-2.254c-.082-.006-.164-.013-.248-.013H3.38A3.384,3.384,0,0,0,0,15.188v2.74l.007.042.189.06A15.848,15.848,0,0,0,4,18.745v-.827A4.017,4.017,0,0,1,6.871,14.074Z" transform="translate(0 0)" fill={`${activePage === '/teamManagement' ? '#7363D6' : '#fff'}`} />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            User Management
                                        </div>
                                    </Link>
                                </div>
                                <div className='pt-4'>
                                    <Link to={'/app/salesOverview'} onClick={() => onClickSideMenu('/salesOverview', 'Sales Overview')}>
                                        <div className={`d-flex ${activePage === '/salesOverview' ? 'bg-white' : 'text-white'} sidebarPadding`}>
                                            <div className='pe-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="Group_182292" data-name="Group 182292" transform="translate(-1911 -959)">
                                                        <rect id="Rectangle_148453" data-name="Rectangle 148453" width="24" height="24" transform="translate(1911 959)" fill="none" />
                                                        <g id="reload_4943845" transform="translate(1913 961.359)">
                                                            <path id="Path_49708" data-name="Path 49708" d="M110.07,103a7.3,7.3,0,1,0,7.07,7.3A7.2,7.2,0,0,0,110.07,103Zm2.1,5.028h.941a.477.477,0,0,1,0,.954h-1.093a2.946,2.946,0,0,1-.627,1.165,3.418,3.418,0,0,1-2.679,1c-.144,0-.292-.005-.444-.015l2.6,2.761a.488.488,0,0,1-.009.675.452.452,0,0,1-.653-.009l-3.507-3.724a.484.484,0,0,1-.13-.347.493.493,0,0,1,.009-.108.463.463,0,0,1,.545-.372c1.748.368,2.956.2,3.589-.511a1.854,1.854,0,0,0,.323-.518h-3.827a.477.477,0,0,1,0-.954h4.033a4.968,4.968,0,0,0-.083-1.184h-3.95a.477.477,0,0,1,0-.954h5.9a.477.477,0,0,1,0,.954H112.1A5.772,5.772,0,0,1,112.169,108.028Z" transform="translate(-100.009 -100.954)" fill={`${activePage === '/salesOverview' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Arrow1" d="M59.841,40.034a.443.443,0,0,0-.566-.082l-.427.269A7.485,7.485,0,0,0,56.2,34.955a9.98,9.98,0,0,0-12.818,0,8.009,8.009,0,0,0-1.874,2.388c-.207.395-.379.777-.409.845a.344.344,0,0,0,.23.461.455.455,0,0,0,.148.025.409.409,0,0,0,.378-.226c.089-.2.295-.637.5-1h0c.059-.107.328-.523.34-.556a8.535,8.535,0,0,1,7.1-3.552c4.421,0,8.04,3.067,8.243,6.9l-.459-.289a.443.443,0,0,0-.566.082.329.329,0,0,0,.093.5l1.075.677a.443.443,0,0,0,.236.067h.021a.443.443,0,0,0,.236-.067l1.075-.677A.329.329,0,0,0,59.841,40.034Z" transform="translate(-39.841 -32.625)" fill={`${activePage === '/salesOverview' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Arrow2" d="M24.916,239.452a.419.419,0,0,0-.508.235,8.578,8.578,0,0,1-.719,1.277,8.561,8.561,0,0,1-7.057,3.482c-4.425,0-8.049-3.067-8.251-6.9l.46.289a.444.444,0,0,0,.236.067.426.426,0,0,0,.33-.149.329.329,0,0,0-.093-.5l-1.076-.677a.494.494,0,0,0-.494,0l-1.076.677a.329.329,0,0,0-.093.5.426.426,0,0,0,.33.149.444.444,0,0,0,.236-.067l.428-.269a7.482,7.482,0,0,0,2.648,5.266,9.694,9.694,0,0,0,6.416,2.33,9.694,9.694,0,0,0,6.416-2.33,8.32,8.32,0,0,0,1.2-1.292c.013-.014.485-.718.654-1.044a4.223,4.223,0,0,0,.286-.6A.35.35,0,0,0,24.916,239.452Z" transform="translate(-6.498 -226.517)" fill={`${activePage === '/salesOverview' ? '#7363D6' : '#fff'}`} />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            Sales Overview
                                        </div>
                                    </Link>
                                </div>
                                <div className='pt-4'>
                                    <Link to={'/app/stockOverviewDashboard'} onClick={() => onClickSideMenu('/stockOverview', 'Stock Overview')}>
                                        <div className={`d-flex ${activePage === '/stockOverview' ? 'bg-white' : 'text-white'} sidebarPadding`}>
                                            <div className='pe-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="Group_182328" data-name="Group 182328" transform="translate(-52 -289)">
                                                        <rect id="Rectangle_149773" data-name="Rectangle 149773" width="24" height="24" transform="translate(52 289)" fill="none" />
                                                        <g id="cube_835318" transform="translate(50.229 292)">
                                                            <g id="Group_182327" data-name="Group 182327" transform="translate(4.771)">
                                                                <path id="Path_49721" data-name="Path 49721" d="M18.283,7.5V2.7L13.6,0,8.923,2.7V7.5l-4.152,2.4v5.4L9.451,18,13.6,15.6,17.756,18l4.68-2.7v-5.4Zm-9.36,8.98-3.1-1.788V11.113l3.1,1.788Zm4.15-5.362h0L9.979,12.9v-.609l-.526-.306,3.1-1.787.525.305Zm0-1.83-1.284-.742L9.979,7.5V3.921l3.1,1.788V9.284ZM13.6,4.795l3.1-1.789.526.306v.609L14.133,5.707V5.1Zm3.625,11.681-3.1-1.788V11.113l3.1,1.788ZM14.659,10.2l-.528-.307V9.284l3.1-1.788v.609l.526.306Zm3.618,2.707v-.617l-.519-.3L20.85,10.2l.53.308v.605h0Z" transform="translate(-4.771)" fill={`${activePage === '/stockOverview' ? '#7363D6' : '#fff'}`} />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            Stock Overview
                                        </div>
                                    </Link>
                                </div>
                                <div className='pt-4'>
                                    <Link to={'/app/ruleManagement'} onClick={() => {
                                        onClickSideMenu('/ruleManagement', 'Rule Management');
                                        dispatch(userManagementActions.setTableFilters({ type: {} }));
                                    }}>
                                        <div className={`d-flex ${activePage === '/ruleManagement' ? 'bg-white' : 'text-white'} sidebarPadding`}>
                                            <div className='pe-2'>
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g id="Group_182343" data-name="Group 182343" transform="translate(4704 -12198)">
                                                    <rect id="Rectangle_149775" data-name="Rectangle 149775" width="24" height="24" transform="translate(-4704 12198)" fill="none" />
                                                    <g id="multiple-users-silhouette_33308" transform="translate(-4701 12196.078)">
                                                        <path id="Path_49740" data-name="Path 49740" d="M10.862,8.948a3.271,3.271,0,0,1,1.522,2.43,2.639,2.639,0,1,0-1.522-2.43ZM9.133,14.353a2.64,2.64,0,1,0-2.64-2.64A2.64,2.64,0,0,0,9.133,14.353Zm1.12.18H8.013a3.384,3.384,0,0,0-3.38,3.38v2.739l.007.043.189.059a15.386,15.386,0,0,0,4.6.741,9.435,9.435,0,0,0,4.013-.753l.176-.089h.019v-2.74A3.383,3.383,0,0,0,10.253,14.533Zm4.368-2.725H12.4a3.253,3.253,0,0,1-1,2.266,4.016,4.016,0,0,1,2.869,3.844v.844a9.1,9.1,0,0,0,3.543-.744l.176-.089H18v-2.74A3.384,3.384,0,0,0,14.62,11.808ZM4.5,11.628a2.622,2.622,0,0,0,1.4-.407A3.267,3.267,0,0,1,7.133,9.137c0-.049.007-.1.007-.148A2.64,2.64,0,1,0,4.5,11.628Zm2.371,2.446a3.255,3.255,0,0,1-1-2.254c-.082-.006-.164-.013-.248-.013H3.38A3.384,3.384,0,0,0,0,15.188v2.74l.007.042.189.06A15.848,15.848,0,0,0,4,18.745v-.827A4.017,4.017,0,0,1,6.871,14.074Z" transform="translate(0 0)" fill={`${activePage === '/ruleManagement' ? '#7363D6' : '#fff'}`} />
                                                    </g>
                                                </g>
                                            </svg> */}
                                                <svg width="24" height="24" viewBox="0 0 50 56" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="Page-1" fill="none" fillRule="evenodd">
                                                        <g id="002---Browser" fillRule="nonzero">
                                                            <path id="Rectangle-path" d="m5 13.001h40v3.999h-40z" fill={`${activePage === '/ruleManagement' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Shape" d="m0 6h50v-6h-50zm45-4h2c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-2c-.5522847 0-1-.44771525-1-1s.4477153-1 1-1zm-36 0h32c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-32c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1zm-6 0h2c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1h-2c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1z" fill={`${activePage === '/ruleManagement' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Rectangle-path" d="m28 24.001h17v3.999h-17z" fill={`${activePage === '/ruleManagement' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Shape" d="m50 8h-50v48h50zm-47 16c0-1.1045695.8954305-2 2-2h16c1.1045695 0 2 .8954305 2 2v4c0 1.1045695-.8954305 2-2 2h-16c-1.1045695 0-2-.8954305-2-2zm44 27c0 1.1045695-.8954305 2-2 2h-40c-1.1045695 0-2-.8954305-2-2v-16c0-1.1045695.8954305-2 2-2h40c1.1045695 0 2 .8954305 2 2zm0-23c0 1.1045695-.8954305 2-2 2h-17c-1.1045695 0-2-.8954305-2-2v-4c0-1.1045695.8954305-2 2-2h17c1.1045695 0 2 .8954305 2 2zm0-11c0 1.1045695-.8954305 2-2 2h-40c-1.1045695 0-2-.8954305-2-2v-4c0-1.1045695.8954305-2 2-2h40c1.1045695 0 2 .8954305 2 2z" fill={`${activePage === '/ruleManagement' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Rectangle-path" d="m5 24.001h16v3.999h-16z" fill={`${activePage === '/ruleManagement' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Rectangle-path" d="m5 35.001h40v15.999h-40z" fill={`${activePage === '/ruleManagement' ? '#7363D6' : '#fff'}`} />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            Rule Management
                                        </div>
                                    </Link>
                                </div>

                                <div className='pt-4'>
                                    <Link to={'/app/targetVsAchievement'} onClick={() => onClickSideMenu('/targetVsAchievement', 'Target Vs Achievement')}>
                                        <div className={`d-flex ${activePage === '/targetVsAchievement' ? 'bg-white' : 'text-white'} sidebarPadding`}>
                                            <div className='pe-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="Group_182340" data-name="Group 182340" transform="translate(-37 -516)">
                                                        <rect id="Rectangle_149774" data-name="Rectangle 149774" width="24" height="24" transform="translate(37 516)" fill="none" />
                                                        <g id="target_407061" transform="translate(39.997 519)">
                                                            <g id="Group_182335" data-name="Group 182335" transform="translate(7.3 0.001)">
                                                                <g id="Group_182334" data-name="Group 182334" transform="translate(0 0)">
                                                                    <path id="Path_49722" data-name="Path 49722" d="M210.385,1.728l-1.25.179.179-1.25a.574.574,0,0,0-.973-.486l-2.293,2.293a.574.574,0,0,0-.162.324l-.26,1.817-2.831,2.831a1.7,1.7,0,0,0-.737-.172,1.72,1.72,0,1,0,1.72,1.72,1.7,1.7,0,0,0-.172-.737l2.831-2.831,1.817-.26a.573.573,0,0,0,.324-.162L210.872,2.7A.574.574,0,0,0,210.385,1.728Z" transform="translate(-200.338 -0.001)" fill={`${activePage === '/targetVsAchievement' ? '#7363D6' : '#fff'}`} />
                                                                </g>
                                                            </g>
                                                            <g id="Group_182337" data-name="Group 182337" transform="translate(3.641 4.044)">
                                                                <g id="Group_182336" data-name="Group 182336">
                                                                    <path id="Path_49723" data-name="Path 49723" d="M109.982,114.275l-1.816,1.816a2.891,2.891,0,1,1-2.432-2.432l1.816-1.816a5.179,5.179,0,1,0,2.432,2.432Z" transform="translate(-100.171 -111.334)" fill={`${activePage === '/targetVsAchievement' ? '#7363D6' : '#fff'}`} />
                                                                </g>
                                                            </g>
                                                            <g id="Group_182339" data-name="Group 182339" transform="translate(0.003 0.384)">
                                                                <g id="Group_182338" data-name="Group 182338" transform="translate(0)">
                                                                    <path id="Path_49724" data-name="Path 49724" d="M16.79,16.249l-.469.469a1.771,1.771,0,0,1-1,.5l-.637.091A6.479,6.479,0,1,1,11.478,14.1l.091-.637a1.773,1.773,0,0,1,.5-1L12.537,12a8.817,8.817,0,1,0,4.253,4.253Z" transform="translate(-0.003 -11.166)" fill={`${activePage === '/targetVsAchievement' ? '#7363D6' : '#fff'}`} />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            Target vs Achievement
                                        </div>
                                    </Link>
                                </div>



                                {/* <div className='pt-4'>
                                <Link to={'/app/dashboard'} onClick={() => onClickSideMenu('/dashboard', 'dashboard')}>
                                    <div className={`d-flex ${activePage === '/dashboard' ? 'bg-white' : 'text-white'} sidebarPadding`}>
                                        <div className='pe-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <g id="Group_182297" data-name="Group 182297" transform="translate(-2175 -959)">
                                                    <rect id="Rectangle_148453" data-name="Rectangle 148453" width="24" height="24" transform="translate(2175 959)" fill="none" />
                                                    <g id="menu_3388694" transform="translate(2178 962)">
                                                        <path id="Path_49704" data-name="Path 49704" d="M6.891,0H1.3A1.305,1.305,0,0,0,0,1.3V4.656a1.305,1.305,0,0,0,1.3,1.3H6.891a1.305,1.305,0,0,0,1.3-1.3V1.3A1.305,1.305,0,0,0,6.891,0Z" fill={`${activePage === '/dashboard' ? '#7363D6' : '#fff'}`} />
                                                        <path id="Path_49705" data-name="Path 49705" d="M6.891,10H1.3A1.305,1.305,0,0,0,0,11.3v7.822a1.305,1.305,0,0,0,1.3,1.3H6.891a1.305,1.305,0,0,0,1.3-1.3V11.3A1.305,1.305,0,0,0,6.891,10Z" transform="translate(0 -2.43)" fill={`${activePage === '/dashboard' ? '#7363D6' : '#fff'}`} />
                                                        <path id="Path_49706" data-name="Path 49706" d="M19.891,16H14.3A1.305,1.305,0,0,0,13,17.3v3.352a1.305,1.305,0,0,0,1.3,1.3h5.587a1.305,1.305,0,0,0,1.3-1.3V17.3A1.305,1.305,0,0,0,19.891,16Z" transform="translate(-3.195 -3.96)" fill={`${activePage === '/dashboard' ? '#7363D6' : '#fff'}`} />
                                                        <path id="Path_49707" data-name="Path 49707" d="M19.891,0H14.3A1.305,1.305,0,0,0,13,1.3V9.126a1.305,1.305,0,0,0,1.3,1.3h5.587a1.305,1.305,0,0,0,1.3-1.3V1.3A1.305,1.305,0,0,0,19.891,0Z" transform="translate(-3.195)" fill={`${activePage === '/dashboard' ? '#7363D6' : '#fff'}`} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div>Dashboard</div>
                                    </div>
                                </Link>
                            </div> */}

                                <div className='pt-4'>
                                    <Link to={'/app/employeeStatus'} onClick={() => { onClickSideMenu('/employeeStatus', 'Employee Status') }}>
                                        <div className={`d-flex ${activePage === '/employeeStatus' ? 'bg-white' : 'text-white'} sidebarPadding`}>
                                            <div className='pe-2'>
                                                <svg id="glipy" viewBox="0 0 64 64" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m16.07 15.75a5.885 5.885 0 1 0 -5.89-5.88 5.89169 5.89169 0 0 0 5.89 5.88z" fill={`${activePage === '/employeeStatus' ? '#7363D6' : '#fff'}`} />
                                                    <path d="m16.07 18.12a11.856 11.856 0 0 0 -8.65 3.75 5.39229 5.39229 0 0 0 3.94 9.08h9.42a5.39229 5.39229 0 0 0 3.94-9.08 11.87235 11.87235 0 0 0 -8.65-3.75z" fill={`${activePage === '/employeeStatus' ? '#7363D6' : '#fff'}`} />
                                                    <circle cx="16.07" cy="38.84" r="5.89" transform="matrix(.987 -.16 .16 .987 -6.014 3.076)" fill={`${activePage === '/employeeStatus' ? '#7363D6' : '#fff'}`} />
                                                    <path d="m16.07 47.09a11.89443 11.89443 0 0 0 -8.65 3.75 5.40059 5.40059 0 0 0 3.94 9.09h9.42a5.40059 5.40059 0 0 0 3.94-9.09 11.91094 11.91094 0 0 0 -8.65-3.75z" fill={`${activePage === '/employeeStatus' ? '#7363D6' : '#fff'}`} />
                                                    <path d="m53.04 4.02h-17.99a4.00322 4.00322 0 0 0 -3.99 4v17.99a3.99465 3.99465 0 0 0 3.99 3.99h17.99a3.9947 3.9947 0 0 0 3.99-3.99v-17.99a4.00327 4.00327 0 0 0 -3.99-4zm-2.29 9.7-8 8a1.0011 1.0011 0 0 1 -.7.29 1.02418 1.02418 0 0 1 -.71-.29l-4-4a.99579.99579 0 0 1 0-1.41 1.0079 1.0079 0 0 1 1.42 0l3.29 3.29 7.28-7.29a1.0079 1.0079 0 0 1 1.42 0 .99581.99581 0 0 1 0 1.41z" fill={`${activePage === '/employeeStatus' ? '#7363D6' : '#fff'}`} />
                                                    <path d="m53.04 34h-17.99a3.99461 3.99461 0 0 0 -3.99 3.99v17.99a4.00318 4.00318 0 0 0 3.99 4h17.99a4.00323 4.00323 0 0 0 3.99-4v-17.99a3.99466 3.99466 0 0 0 -3.99-3.99zm-3.25 17.28a.995.995 0 0 1 -.7 1.7 1.00684 1.00684 0 0 1 -.71-.29l-4.29-4.29-4.29 4.29a1.02433 1.02433 0 0 1 -.71.29.995.995 0 0 1 -.7-1.7l4.29-4.29-4.29-4.29a.99588.99588 0 0 1 0-1.41.9784.9784 0 0 1 1.41 0l4.29 4.28 4.29-4.28a.997.997 0 1 1 1.41 1.41l-4.29 4.29z" fill={`${activePage === '/employeeStatus' ? '#7363D6' : '#fff'}`} />
                                                </svg>
                                            </div>
                                            Employee Status
                                        </div>
                                    </Link>
                                </div>

                                <div className='pt-4'>
                                    <Link to={'/app/incentive'} onClick={() => onClickSideMenu('/incentive', 'incentive')}>
                                        <div className={`d-flex ${activePage === '/incentive' ? 'bg-white' : 'text-white'} sidebarPadding`}>
                                            <div className='pe-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="Group_182341" data-name="Group 182341" transform="translate(4707 -12190)">
                                                        <rect id="Rectangle_149776" data-name="Rectangle 149776" width="24" height="24" transform="translate(-4707 12190)" fill="none" />
                                                        <g id="gift-wrap_11497362" transform="translate(-4704.961 12193)">
                                                            <path id="Path_49725" data-name="Path 49725" d="M1,133.635a1.477,1.477,0,0,0,1.475,1.476H7.654v-2.952H2.475A1.477,1.477,0,0,0,1,133.635Z" transform="translate(0 -127.078)" fill={`${activePage === '/incentive' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49726" data-name="Path 49726" d="M299.506,132.159h-5.179v2.952h5.179a1.476,1.476,0,0,0,0-2.952Z" transform="translate(-282.058 -127.078)" fill={`${activePage === '/incentive' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49727" data-name="Path 49727" d="M51.356,238.507a1.477,1.477,0,0,0,1.475,1.476H56.07v-9.139H51.356Z" transform="translate(-48.415 -221.983)" fill={`${activePage === '/incentive' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49728" data-name="Path 49728" d="M294.327,239.983h3.239a1.477,1.477,0,0,0,1.475-1.476v-7.663h-4.714Z" transform="translate(-282.058 -221.983)" fill={`${activePage === '/incentive' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49729" data-name="Path 49729" d="M250.772.571a1.957,1.957,0,0,0-2.762,0c-.5.5-.868,2.2-1.061,3.686h.983c2.071-.35,2.675-.768,2.839-.932a1.962,1.962,0,0,0,0-2.754Z" transform="translate(-236.496 -0.001)" fill={`${activePage === '/incentive' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49730" data-name="Path 49730" d="M110.543.57a1.95,1.95,0,0,0-2.762,2.754c.164.164.769.582,2.84.932h.983c-.193-1.484-.562-3.189-1.061-3.686Z" transform="translate(-102.135 0)" fill={`${activePage === '/incentive' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49731" data-name="Path 49731" d="M195.73,132.522h2.95V145.45h-2.95Z" transform="translate(-187.243 -127.45)" fill={`${activePage === '/incentive' ? '#7363D6' : '#fff'}`} />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            Incentive
                                        </div>
                                    </Link>
                                </div>
                                <div className='pt-4'>
                                    <Link to={'/app/promotion'} onClick={() => onClickSideMenu('/promotion', 'promotion')}>
                                        <div className={`d-flex ${activePage === '/promotion' ? 'bg-white' : 'text-white'} sidebarPadding`}>
                                            <div className='pe-2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <g id="Group_182342" data-name="Group 182342" transform="translate(4707 -12225)">
                                                        <rect id="Rectangle_149777" data-name="Rectangle 149777" width="24" height="24" transform="translate(-4707 12225)" fill="none" />
                                                        <g id="megaphone_3651862" transform="translate(-4714.135 12228)">
                                                            <path id="Path_49732" data-name="Path 49732" d="M12.912,247.447a.3.3,0,0,0-.414-.1l-1.559.962a1.69,1.69,0,0,0-.551,2.327l.958,1.553a1.693,1.693,0,0,0,2.327.551l1.559-.963a.3.3,0,0,0,.1-.414Zm-.595,3.416a.3.3,0,0,1-.167-.551l.451-.3a.3.3,0,0,1,.334.5l-.451.3A.3.3,0,0,1,12.317,250.863Z" transform="translate(0 -238.04)" fill={`${activePage === '/promotion' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49733" data-name="Path 49733" d="M219.47,12.571a1.517,1.517,0,0,1-2.088-.494l-6.024-9.763A1.517,1.517,0,1,1,213.94.721l6.024,9.762a1.519,1.519,0,0,1-.494,2.088Z" transform="translate(-193.48 0)" fill={`${activePage === '/promotion' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49734" data-name="Path 49734" d="M105.029,101.227a12.88,12.88,0,0,0-6.286,1.339.3.3,0,0,1-.389-.111L95.89,98.461a.3.3,0,0,1,.075-.4,12.873,12.873,0,0,0,4.016-5.019.3.3,0,0,1,.529-.033l4.787,7.758A.3.3,0,0,1,105.029,101.227Z" transform="translate(-82.504 -89.394)" fill={`${activePage === '/promotion' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49735" data-name="Path 49735" d="M360.757,100.2l-1.442-2.336a.3.3,0,0,1,.3-.455,2.124,2.124,0,0,1,1.683,2.727A.3.3,0,0,1,360.757,100.2Z" transform="translate(-336.051 -93.75)" fill={`${activePage === '/promotion' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49736" data-name="Path 49736" d="M125.666,363.492a1.406,1.406,0,0,1-.992-.411l-2.241-2.227a.3.3,0,0,1,.054-.469l1.546-.954c.309-.169.619-.323.934-.464a.3.3,0,0,1,.372.107l1.5,2.219A1.411,1.411,0,0,1,125.666,363.492Z" transform="translate(-108.005 -345.492)" fill={`${activePage === '/promotion' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49737" data-name="Path 49737" d="M432.484,83.962a.3.3,0,0,1-.172-.547l.541-.376a.3.3,0,0,1,.343.493l-.541.377A.3.3,0,0,1,432.484,83.962Z" transform="translate(-406.225 -79.873)" fill={`${activePage === '/promotion' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49738" data-name="Path 49738" d="M440.383,175.833a.3.3,0,0,1-.42.068l-.549-.395a.3.3,0,0,1,.351-.488l.549.4A.3.3,0,0,1,440.383,175.833Z" transform="translate(-413.064 -168.402)" fill={`${activePage === '/promotion' ? '#7363D6' : '#fff'}`} />
                                                            <path id="Path_49739" data-name="Path 49739" d="M372.788,26.308v.676a.3.3,0,1,1-.6,0v-.676a.3.3,0,1,1,.6,0Z" transform="translate(-348.477 -25.03)" fill={`${activePage === '/promotion' ? '#7363D6' : '#fff'}`} />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            Promotion
                                        </div>
                                    </Link>
                                </div>
                                <div className='pt-4'>
                                    <Link to={'/app/feedback'} onClick={() => onClickSideMenu('/feedback', 'feedback')}>
                                        <div className={`d-flex ${activePage === '/feedback' ? 'bg-white' : 'text-white'} sidebarPadding fontMedium align-items-center`}>
                                            <div className='pe-2'>
                                                <svg fill={`${activePage === '/feedback' ? '#7363D6' : '#fff'}`} height="20" width="24" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 477.655 477.655" >
                                                    <g>
                                                        <path d="M440.367,440.415l-10.173-29.91c-19.102-56.262-70.83-94.605-129.763-97.121c-5.187,4.03-10.655,7.493-16.322,10.521
                                                        c-8.449,22.185-36.836,28.195-53.468,11.205c-19.676-1.738-37.69-9.511-53.422-21.725c-58.933,2.508-110.647,40.851-129.763,97.121
                                                        L37.3,440.415c-2.936,8.603-1.522,18.084,3.774,25.469c5.279,7.391,13.821,11.771,22.906,11.771h349.693
                                                        c9.083,0,17.626-4.379,22.906-11.771C441.873,458.499,443.286,449.018,440.367,440.415z"/>
                                                        <path d="M277.758,290.619c34.212-24.047,58.141-77.151,58.141-128.491c0-145.907-194.133-145.752-194.133,0
                                                        c0,62.397,35.33,127.303,81.546,139.556c4.456-12.626,16.382-21.757,30.515-21.757C263.331,279.926,271.81,284.095,277.758,290.619
                                                        z"/>
                                                        <path d="M99.169,223.042c4.813,18.906,31.044,13.704,31.044-3.805c0-70.178,3.354-76.731-6.041-84.348
                                                        C145.679,2.361,330.946,3.355,353.495,134.904c-9.381,7.641-6.025,14.163-6.025,84.333c0,5.489,2.95,10.095,7.189,12.952
                                                        c0,54.594-22.145,51.402-88.736,69.052c-10.295-11.174-28.683-3.899-28.683,11.173c0,18.876,27.053,23.293,32.302,4.318
                                                        c53.762-14.256,101.018-18.752,101.018-72.484v-11.027c3.991-2.066,6.817-5.729,7.951-10.179c51.822-1.056,51.838-78.719,0-79.775
                                                        c-1.072-4.24-3.711-7.703-7.423-9.815c1.336-15.902-1.94-36.805-11.057-56.985C296.626-54.368,109.355-3.176,106.422,123.622
                                                        c-0.404,4.294-0.078,7.338,0.17,9.83c-3.712,2.112-6.351,5.575-7.423,9.815c-21.71,0.419-39.212,18.084-39.212,39.888
                                                        C59.957,204.958,77.459,222.622,99.169,223.042z"/>
                                                    </g>
                                                </svg>
                                            </div>
                                            Feedback
                                        </div>
                                    </Link>
                                </div>
                                <div className='pt-4'>
                                    <Link to={'/app/notifications'} onClick={() => onClickSideMenu('/notifications', 'notifications')}>
                                        <div className={`d-flex ${activePage === '/notifications' ? 'bg-white' : 'text-white'} sidebarPadding fontMedium align-items-center`}>
                                            <div className='pe-2'>
                                                <svg fill={`${activePage === '/notifications' ? '#7363D6' : '#fff'}`} xmlns="http://www.w3.org/2000/svg" width="16.66" height="19.992" viewBox="0 0 16.66 19.992">
                                                    <g id="bell_1827347" transform="translate(0)">
                                                        <path id="Path_63347" data-name="Path 63347" d="M16.143,13.871a5.459,5.459,0,0,1-1.982-4.2V7.381a5.775,5.775,0,0,0-5-5.675V.82A.833.833,0,0,0,7.5.82v.886a5.775,5.775,0,0,0-5,5.675V9.668A5.465,5.465,0,0,1,.51,13.877,1.424,1.424,0,0,0,0,14.967,1.448,1.448,0,0,0,1.458,16.4H15.2a1.448,1.448,0,0,0,1.458-1.435A1.429,1.429,0,0,0,16.143,13.871Zm0,0" transform="translate(0)" />
                                                        <path id="Path_63348" data-name="Path 63348" d="M138.25,450.692a3.371,3.371,0,0,0,3.3-2.692h-6.595A3.371,3.371,0,0,0,138.25,450.692Zm0,0" transform="translate(-129.92 -430.7)" />
                                                    </g>
                                                </svg>
                                            </div>
                                            Notifications
                                        </div>
                                    </Link>
                                </div>

                                {/* <div className='pt-4'>
                                <Link to={'/app/hierarchyManagement'} onClick={() => {
                                    onClickSideMenu('/hierarchyManagement', 'Hierarchy Management');
                                    dispatch(userManagementActions.setTableFilters({ type: {} }));
                                }}>
                                    <div className={`d-flex ${activePage === '/hierarchyManagement' ? 'bg-white' : 'text-white'} sidebarPadding`}>
                                        <div className='pe-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <defs>
                                                    <clipPath id="clip-path">
                                                        <path id="Path_49742" data-name="Path 49742" d="M0,0H18V18H0Z" fill="none" />
                                                    </clipPath>
                                                </defs>
                                                <g id="Group_182348" data-name="Group 182348" transform="translate(4705 -12162)">
                                                    <rect id="Rectangle_149778" data-name="Rectangle 149778" width="24" height="24" transform="translate(-4705 12162)" fill="none" />
                                                    <g id="network_8756745" transform="translate(-4702 12165)" clip-path="url(#clip-path)">
                                                        <path id="Path_49741" data-name="Path 49741" d="M15.993,12.819V9H9.458V7.617a3.824,3.824,0,1,0-1.071-.009V9H1.85v3.835a2.63,2.63,0,1,0,1.286-.03V10.286h5.25v2.509a2.63,2.63,0,1,0,1.072,0V10.286h5.25v2.533a2.63,2.63,0,1,0,1.286,0Z" transform="translate(0.049)" fill={`${activePage === '/hierarchyManagement' ? '#7363D6' : '#fff'}`} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        Hierarchy Management
                                    </div>
                                </Link>
                            </div> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    // }
}
export default Sidebar;