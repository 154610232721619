import React, { useEffect, useRef, useState } from 'react';
import ImportIcon from '../../../assets/svg/uploadIcon.svg';
import ExportIcon from '../../../assets/svg/downloadIcon.svg';
import CustomFilterDropDown from '../../../ReUsableComponent/CustomFilterDropdown';
import CustomTableWithFilter from '../../../ReUsableComponent/CustomTableWithFilter';
import CustomPagination from '../../../ReUsableComponent/Pagination';
import CustomModal from '../../../ReUsableComponent/CustomModal';
import CustomDropzone from '../../../ReUsableComponent/CustomDropzone';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import SecondaryButton from '../../../ReUsableComponent/SecondaryButton';
import ProgressBarForFileUpload from '../../../ReUsableComponent/ProgressBarForFileUpload';
import { brandTableHeader, ItemMasterHeader } from '../../TableHeaders';
import { brandManagementActions } from '../../../store/reducers/BrandManagementReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CloseIcon from '../../../assets/svg/closeIconofmodal.svg';
import SuccessPopup from '../../../ReUsableComponent/SuccessPopupMsg';
import Loader from '../../../ReUsableComponent/Loader';
import { DownaloadBrandItemMaster, GetBrandItemMaster, UploadBrandMasterList } from '../BrandManagement.service';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import dropDownIcon from '../../../assets/svg/dropDownIcon.svg';
import { GetBrandNameList } from '../../RuleManagement/RuleManagement.service';
import { saveAs } from 'file-saver';
import { DownloadTemplate } from '../../../Configurations/Core.service';
import CustomFilterSearchDropdown from '../../../ReUsableComponent/CustomFilterSearchDropdown';
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';
import moment from 'moment';
import { DownloadConsolidatedReportList } from '../../SalesOverview/SalesOverview.service';

const downloadType = [{ label: 'Template Download', value: 'Template' }, { label: 'Report Download', value: 'Report' }]
const uploadFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const SuccessUploadModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 390,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 835,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
};
const BrandMasterData = () => {

    const gridRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);

    const [anchorElForExport, setAnchorElForExport] = useState(null);
    const [brandTableData, setBrandTableData] = useState([]);
    const openDownloadType = Boolean(anchorElForExport);
    const [openFileUploadModal, setOpenFileUploadModal] = useState(false);
    const [openFileSelectedModal, setOpenFileSelectedModal] = useState(false);
    const [progress, setProgress] = useState(25);
    const [uploadSpeed, setUploadSpeed] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [uploadButtonDisabled, setUploadButtonDisabled] = useState(true);
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const selectedPage = useSelector(state => state.brandManagement.selectedPageForMatser);
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const [anchorElForBrand, setAnchorElForBrand] = useState(null);
    const openBrandFilter = Boolean(anchorElForBrand);
    // const selectedBrand = useSelector(state => state?.brandManagement?.defaultBrand)
    const [selectedUploadBrand, setSelectedUploadBrand] = useState({ label: 'Brand', value: '' });
    // const [brandList, setBrandList] = useState([]);
    const brandList = useSelector(state => state?.brandManagement?.brandDropdownList);
    const [brandUploadList, setBrandUploadList] = useState([]);
    const defaultBrand = useSelector(state => state?.brandManagement?.defaultBrand);
    const itemMasterFilter = useSelector(state => state?.brandManagement?.masterFilter);

    const [dropSearchValue, setdropSearchValue] = React.useState('');

    const userId = useSelector(state => state?.userAuthentication?.userId);

    const handleSearchChange = (event) => {
        setdropSearchValue(event.target.value);
    };

    useEffect(() => {
        setSelectedUploadBrand(defaultBrand);
    }, [defaultBrand])


    useEffect(() => {
        getAllBrand();
        dispatch(userAuthActions.setActivePage({ type: '/brandManagement' }));
        dispatch(userAuthActions.setHeaderName({ type: 'Brand Management' }));
    }, [userId])

    useEffect(() => {
        let param = '';
        if (itemMasterFilter?.barcode) {
            param = param + `&barcode=${itemMasterFilter?.barcode?.filter}`;
        }
        if (itemMasterFilter?.styleCode) {
            param = param + `&style_code=${itemMasterFilter?.styleCode?.filter}`;
        }
        if (itemMasterFilter?.categoryDivision) {
            param = param + `&category_division=${itemMasterFilter?.categoryDivision?.filter}`;
        }
        if (itemMasterFilter?.categoryGroup) {
            param = param + `&category_group=${itemMasterFilter?.categoryGroup?.filter}`;
        }
        if (itemMasterFilter?.categorySection) {
            param = param + `&category_presence=${itemMasterFilter?.categorySection?.filter}`;
        }
        getBrandMasterList(param);
    }, [selectedPage, defaultBrand, itemMasterFilter])

    const downloadItemMasterData = () => {
        let param = `?brand=${defaultBrand.value}&service_type=item_master`
        DownloadConsolidatedReportList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                window.open(response.data.presignedFileUrl, '_blank');
                ToastSuccessService('Item Master report downloaded successfully.');
            } else if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message);
            }
        }).catch(error => {
            ToastErrorService(error?.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }


    const onSelectDownloadCategory = (event) => {
        if (event?.target.value.toUpperCase() === 'Template'.toUpperCase()) {
            onClickDownloadTemplate();
        } else {
            downloadItemMasterData();
        }
        setAnchorElForExport(null);
    }

    const onClickDownloadTemplate = () => {
        setLoading(true);
        let param = `?template_type=item_master_templete`
        DownloadTemplate(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                window.location.href = response.data.data
                ToastSuccessService('Template Downloaded Successfully.')
            } else {
                ToastErrorService(response.data.message);
            }
            // setOpenUploadFileModal(false);
        }).catch(error => {
            ToastErrorService('Something went wrong, Please try again after sometime.');
        }).finally(() => {
            setLoading(false);
        })
    }
    const downloadBrandMasterReport = (body) => {
        let param = `?brand_id=${defaultBrand?.value}`
        DownaloadBrandItemMaster(param).then(response => {
            const responseInUnit = new Uint8Array(response.data.buffer.data);
            const blob = new Blob([responseInUnit], { type: 'application/octet-stream' });
            saveAs(blob, response.data.filename);
            if (response?.data?.buffer?.statusCode === 400) {
                ToastErrorService(response?.data?.buffer?.message);
            } else {
                ToastSuccessService('Report downloaded successfully.');
            }
        }).catch(error => {
            if (error.response.status === 400) {
                ToastErrorService(error?.response?.data?.message);
            } else {
                ToastErrorService('Something went wrong, Please try again after sometime.');
            }
            if (error.response.status === 401) {
                history.push('/')
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickDownloadReport = (event) => {
        setAnchorElForExport(event.currentTarget)
    }

    const handlePageChange = (pageValue) => {
        dispatch(brandManagementActions.setSelectedPageForMatser({ type: pageValue }));
    }

    const handleExportClose = () => {
        setAnchorElForExport(null);
    }

    const onClickImport = () => {
        setOpenFileUploadModal(true);
    }
    const onGridReady = (params) => {
        params.api.setFilterModel(itemMasterFilter);
        // gridRef.current = params.api;
        // gridRef.current.sizeColumnsToFit();
    };

    const handleFileDrop = (files) => {
        const fileUploaded = files[0];
        if (fileUploaded) {
            setUploadedFile(fileUploaded);
            setProgress(0);
            simulateUpload();
            setOpenFileSelectedModal(true);
            setOpenFileUploadModal(false);
        }
    };

    const uploadBulkList = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', uploadedFile);
        formData.append('brand_id', selectedUploadBrand?.value);
        formData.append('user_id', userId);
        UploadBrandMasterList(formData, USER_TOKEN).then(response => {
            if ((response?.status === 200 || response?.status === 201) && response?.data?.statusCode !== 400) {
                setOpenSuccessPopup(true);
                setOpenFileSelectedModal(false);
                setTimeout(() => {
                    setOpenSuccessPopup(false);
                }, 5000)
            } else if (response?.data?.statusCode === 400) {
                ToastErrorService(response?.data?.message)
            }

        }).catch(error => {
        }).finally(() => {
            setLoading(false);
        })
    };

    const simulateUpload = () => {
        setUploadButtonDisabled(true);
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + 25;
                if (nextProgress >= 100) {
                    clearInterval(interval);
                    setUploadSpeed(null);
                    setUploadButtonDisabled(false);
                    return 100;
                }
                if (!startTime) {
                    setStartTime(Date.now());
                }
                const elapsedTime = (Date.now() - startTime) / 1000;
                const speed = (nextProgress / elapsedTime).toFixed(2);
                setUploadSpeed(speed);
                return nextProgress;
            });
        }, 500);
    };

    const removeFile = () => {
        setUploadedFile([]);
    }

    const handleBrandClick = (event) => {
        setAnchorElForBrand(event.currentTarget);
    };
    const handleBrandClose = () => {
        setAnchorElForBrand(null);
    };

    const onSelectBrand = (event) => {
        brandList.forEach(brand => {
            if (event.target.value === brand?.value) {
                setAnchorElForBrand(null);
                dispatch(brandManagementActions.setDefaultBrand({ type: { label: brand?.label, value: event.target.value } }));
            }
        });

    }

    const onSelectUploadBrand = (event) => {
        brandUploadList.forEach(brand => {
            if (event.target.value === brand?.value) {
                setSelectedUploadBrand({ label: brand?.label, value: event.target.value })
                setAnchorElForBrand(null);
            }
        });
    }

    const getAllBrand = () => {
        let param = `?user_id=${userId}`
        GetBrandNameList(param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                let brands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element._id
                    }
                    brands.push(brandObject);
                });
                let uploadBrands = []
                response.data.data.forEach(element => {
                    let brandObject = {
                        label: element.brand_name,
                        value: element._id
                    }
                    uploadBrands.push(brandObject)
                });
                // setBrandList(brands);
                setBrandUploadList(uploadBrands);
                // dispatch(brandManagementActions.setDefaultBrand({ type: { label: brands[0]?.label, value: brands[0]?.value } }));
            }
        }).catch(error => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
        })
    }

    const valueCheck = (value) => {
        if (value && value !== 'null') {
            return value;
        } else {
            return '-';
        }
    }

    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

        return moment(dateString).format("DD MMM YYYY");
    }

    const getBrandMasterList = (params) => {
        setLoading(true);
        let param = `?page=${selectedPage}&limit=${20}&brand_id=${defaultBrand?.value}` + params;
        GetBrandItemMaster(param, USER_TOKEN).then(response => {
            setTotalCount(response?.data?.data?.total);
            let tableData = [];
            response?.data?.data?.data?.map(item => {
                let row = {
                    barcode: valueCheck(item?.barcode),
                    nameBrand: valueCheck(item?.brand_name),
                    categoryDivision: valueCheck(item?.category_division),
                    categoryGroup: valueCheck(item?.category_group),
                    categorySection: valueCheck(item?.category_presence),
                    styleCode: valueCheck(item?.style),
                    color: valueCheck(item?.color),
                    size: valueCheck(item?.size),
                    season: valueCheck(item?.season),
                    brandCode: valueCheck(item?.brand_code),
                    subBrand: valueCheck(item?.sub_brand),
                    department: valueCheck(item?.department),
                    catCode: valueCheck(item?.category_code),
                    merchandiseHierarchy: valueCheck(item?.merchandise_hierarchy),
                    ProSKUCode: valueCheck(item?.product_suk_code),
                    ProdSKUDesc: valueCheck(item?.product_suk_desc),
                    HSNCode: valueCheck(item?.hsn_code),
                    UOM: valueCheck(item?.uom),
                    productType: valueCheck(item?.product_type),
                    MH1Description: valueCheck(item?.mh_description1),
                    MH2Description: valueCheck(item?.mh_description2),
                    MH3Description: valueCheck(item?.mh_description3),
                    MH4Description: valueCheck(item?.mh_description4),
                    MH5Description: valueCheck(item?.mh_description5),
                    VARIANT1: valueCheck(item?.variant1),
                    VARIANT2: valueCheck(item?.variant2),
                    VARIANT3: valueCheck(item?.variant3),
                    ATTRIBUTE1: valueCheck(item?.attribute1),
                    ATTRIBUTE2: valueCheck(item?.attribute2),
                    ATTRIBUTE3: valueCheck(item?.attribute3),
                    ATTRIBUTE4: valueCheck(item?.attribute4),
                    ATTRIBUTE5: valueCheck(item?.attribute5),
                    ATTRIBUTE6: valueCheck(item?.attribute6),
                    ATTRIBUTE7: valueCheck(item?.attribute7),
                    seasonCode: valueCheck(item?.season_code),
                    seasonDesc: valueCheck(item?.season_description),
                    Cost: valueCheck(item?.cost),
                    Discount: valueCheck(item?.discount),
                    offerPrice: valueCheck(item?.offer_price),
                    lastUpdated: convertDate(item?.updated_at ? item?.updated_at : item?.created_at),
                    MRP: `₹${Number(item?.mrp ? item?.mrp : 0).toLocaleString('en-IN')}`,
                }
                tableData.push(row)
            })
            setBrandTableData(tableData);
        }).catch(error => {

        }).finally(() => {
            setLoading(false);
        })
    }

    const onFilterChanged = (event) => {
        let filterModel = event.api.getFilterModel();
        dispatch(brandManagementActions.setMasterFilter({ type: filterModel }))
        // getBrandMasterList(param);
    };

    return (
        <div className='notoSansFont'>
            <div className='d-flex justify-content-end'>
                <div className='d-flex align-items-center first-filter'>
                    <div className='position-relative min-w-150'>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleBrandClick}>
                            <p className='text-truncate' title={defaultBrand.label}>{defaultBrand?.label}</p>
                            <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterDropDown data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                            onChange={onSelectBrand} selectedValue={defaultBrand?.value} category={'Brand'} />
                    </div>
                </div>
                <button className='me-3 importExportFile cursorPointer' title='Upload' onClick={onClickImport} >
                    <div className="cursorPointer">
                        <img src={ImportIcon} alt="" />
                    </div>
                </button>
                <button className='me-3 importExportFile' title='Download' onClick={onClickDownloadReport}>
                    <div>
                        <img src={ExportIcon} alt="" />
                    </div>
                </button>
                <CustomFilterDropDown data={downloadType} handleClose={handleExportClose} open={openDownloadType} anchorEl={anchorElForExport}
                    onChange={onSelectDownloadCategory} category={'Category'} />
            </div>
            <div className='pt-3'>
                <div className='tableContainer'>
                    <CustomTableWithFilter onFilterChanged={onFilterChanged} totalRow={() => { }} column={ItemMasterHeader} tableRowData={brandTableData} onClickView={null} onGridReady={onGridReady} filterModel={null} customeHeightClass={'tableCustomeHeightClass'} />

                </div>
                <div className='paddingTop16 d-flex justify-content-end customPagination'>
                    <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalCount} />
                </div>
            </div>

            {/* Upload File Modal */}
            <CustomModal open={openFileUploadModal} style={uploadFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileUploadModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='position-relative' style={{ paddingTop: '20px', maxWidth: 'fit-content' }}>
                        <div className='d-flex statusFilter cursorPointer align-items-center justify-content-between me-3'
                            onClick={handleBrandClick}>
                            <p className='text-truncate' title={selectedUploadBrand.label}>{selectedUploadBrand?.label}</p>
                            <img className={`${openBrandFilter ? 'transform180 pe-2' : 'ps-2'}`} src={dropDownIcon} alt="" />
                        </div>
                        <CustomFilterSearchDropdown data={brandList} handleClose={handleBrandClose} open={openBrandFilter} anchorEl={anchorElForBrand}
                            onChange={onSelectUploadBrand} selectedValue={selectedUploadBrand?.value} category={'Brand'}
                            dropSearchValue={dropSearchValue} setdropSearchValue={setdropSearchValue} handleSearchChange={handleSearchChange} />
                    </div>
                    <div className='paddingTop24'>
                        <div className=''>
                            <div className='channelDisplayData'>
                                <CustomDropzone filesAccepted={['.xlsx', '.csv']} onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                        <div className='textColorGray fontSize12 pt-3'>Or</div>
                                    </div>
                                    <div className='pt-3'>
                                        <CustomBlueButton onClick={null} title={'Choose File'} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='pt-3 d-flex justify-content-between align-items-center'>
                                <div className='fontSize12 textColorGray'>Download excel file template</div>
                                <div>
                                    <SecondaryButton onClick={onClickDownloadTemplate} title={<div className='d-flex align-items-center'>
                                        <div>
                                            <img src={ExportIcon} alt="" />
                                        </div>
                                        <div className='fontBold'>
                                            Download Template
                                        </div>
                                    </div>} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* Upload file progress Modal */}
            <CustomModal classes="customeProgressModal" open={openFileSelectedModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Upload File</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => setOpenFileSelectedModal(false)}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='paddingTop24'>
                        <div className='dropZoneContentContainer'>
                            <div className='channelDisplayData'>
                                <CustomDropzone filesAccepted={['.xlsx', '.csv']} onFileDrop={handleFileDrop} displayContent={<div className='dropZoneContent uploadedFileDropZoneContent'>
                                    <div className='dropZoneInnerContainer'>
                                        <div className='dropZoneImport'>
                                            <img src={ImportIcon} alt="" width={29} height={29} />
                                        </div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <div className='textColorBlack fontSize14 fontMedium'>Drag File to Upload</div>
                                        <div className='textColorGray fontSize12'>Upload Excel & CSV File Only</div>
                                    </div>
                                    <div className='paddingTop24'>
                                        <SecondaryButton onClick={handleFileDrop} title={<span className='fontSemiBold textColorBlue'>Choose File</span>} />
                                    </div>
                                </div>} />
                            </div>
                            <div className='dropZoneContentRight'>
                                {uploadedFile?.path && <div>
                                    <p className='uploadingP'>Uploading</p>
                                    <div className='paddingTop24'>
                                        <ProgressBarForFileUpload onClickImageCloseIcon={removeFile} progress={progress} File={uploadedFile} uploadSpeed={uploadSpeed} />
                                    </div>
                                    <div className='pt-3 CustomBlueButtonConatiner' >
                                        <CustomBlueButton customCreatePropsBtnClass="customCreatePropsBtnClass" disabled={uploadButtonDisabled} onClick={uploadBulkList} title={'Upload'} />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>} />

            {/* File Uploaded Successfully Modal */}
            <SuccessPopup open={openSuccessPopup} closable={false} onClose={() => setOpenSuccessPopup(false)} content={<div>
                <div className='paddingTop32 text-center'>
                    <div className='textColorBlack fontSize24 montserratFont fontMedium'>File Uploaded Successfully!</div>
                    <div className='fontSize12 textColor333'>We will be processing the data in background, you can continue with your work.</div>
                </div>
            </div>} />
            {loading && <Loader />}
        </div>
    )
}

export default BrandMasterData