import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        const scrollableElement = document.getElementById("scrollable-content");
        if (scrollableElement) {
            scrollableElement.scrollTo({ top: 0, behavior: "instant" });
        }
    }, [location.pathname, location.hash]);

    return null;
};

export default ScrollToTop;
