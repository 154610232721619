import React from 'react';
import { Bar } from "react-chartjs-2";

const CategoryBarChart = ({ chartData, width, height, totalSale }) => {

    const calculatePercent = (saleByCategory, totalSale) => {
        let result = (saleByCategory / totalSale) * 100
        return result.toFixed(2) + '%'
    }

    const barChartOptions = {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        datalabels: {
            display: false,
            color: '#fff',
        },
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                anchor: "start",
                align: "end",
                formatter: (value, context) => calculatePercent(value, totalSale),
                color: "#181d1f",
                font: {
                    weight: "bold",
                    size: 12,
                    family: 'Noto Sans'
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    maxRotation: 0,
                    minRotation: 45,
                }
            }
        }
    }

    return (
        <div className='chart-wrapper' >
            <Bar id='barrr' height={500} data={chartData}
                options={barChartOptions} />
        </div>
    )
}

export default CategoryBarChart