import * as React from 'react';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TextField from '@mui/material/TextField';
import SecondaryButton from '../SecondaryButton';

const CustomFilterSearchDropdown = (props) => {

    const filteredData = props?.data?.filter(item =>
        item?.label?.toLowerCase()?.includes(props?.dropSearchValue?.toLowerCase())
    );

    return (
        <div>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                title={props.selectedValue}
                className={`customDropDownFilter ${props.customClass}`}
                anchorEl={props.anchorEl}
                open={props.open}
                onClose={props.handleClose}
                TransitionComponent={Fade}
            >
                {/* <div className='notoSansFont fontMedium fontSize12 textColorBlack pb-1 d-flex align-items-center'>
                    <span className='pe-2'>Select {props.category}</span>
                    <InfoOutlinedIcon fontSize='small' />
                </div> */}
                <TextField
                    value={props?.dropSearchValue}
                    onChange={props?.handleSearchChange}
                    placeholder="Search..."
                    variant="outlined"
                    size="small"
                    fullWidth
                    margin="dense"
                />
                {filteredData.map((item, index) => (
                    <RadioGroup onChange={props.onChange} id={item?.id} key={index} title={item?.label} value={props.selectedValue}>
                        <FormControlLabel value={item.value} label={item.label} control={<Radio />} />
                    </RadioGroup>
                ))}
                {props.onClickClear && <div className='d-flex justify-content-end pt-1'>
                    <SecondaryButton customClsName='resetBtn' title={'Clear'} onClick={props.onClickClear} />
                </div>}
            </Menu>
        </div>
    );
}

export default CustomFilterSearchDropdown;
