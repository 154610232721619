import React, { useEffect, useState } from 'react';
import './header.css';
import { useHistory } from 'react-router-dom';
import NotiFicationIcon from '../../assets/svg/bell_1827347.svg';
import ProfileIcon from '../../assets/svg/user_1077114.svg';
import HamburgerMenuRight from '../../assets/svg/burger_menu_right.svg';
import SearchBox from '../../ReUsableComponent/CustomSearch/indedx';
import { useDispatch, useSelector } from "react-redux";
import Loader from "./../../ReUsableComponent/Loader";
import CustomBlueButton from "./../../ReUsableComponent/CustomBlueButton";
import { ToastErrorService, ToastSuccessService } from "./../../ReUsableComponent/ToastNotification/ToastService";
import { LogOutUser } from "./header.service";
import { sidebarActions } from '../../store/reducers/SidebarReducer';
import { GetNotificationsList, UpdateNotificationStatus } from '../../Pages/Notifications/promotions.service';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import '../../Pages/Notifications/Notifications.css';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import CustomModal from '../../ReUsableComponent/CustomModal';
import CloseIcon from '../../assets/svg/closeIconofmodal.svg';
import { userAuthActions } from '../../store/reducers/UserAuthReducers';
import { notificationActions } from '../../store/reducers/NotificationsReducer';

const uploadedFileModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: '#F2F2F2',
    boxShadow: 24,
    p: '40px 32px',
    borderRadius: 6,
    border: '1px solid #3333330D'
}
function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

const checkNameVal = (name) => {
    if (name.split(' ').length > 1) {
        return `${name?.split(' ')[0][0]}${name?.split(' ')[1][0]}`
    } else {
        return `${name?.split(' ')[0][0]}`
    }
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: checkNameVal(name),
    };
}

const Header = () => {
    const currentUrlLastPath = window.location.href;
    const path = currentUrlLastPath.split("#/")[1];
    const pageName = path.split("/")[1];
    const history = useHistory();
    const dispatch = useDispatch();
    const backButtonActive = useSelector((state) => state.userAuthentication.isBackBtnActive);
    const headerName = useSelector((state) => state.userAuthentication.activePageHeaderName);
    const isSearchBtnActives = useSelector((state) => state.userAuthentication.isSearchBtnActive);
    const [openLogoutDrop, setOpenLogoutDrop] = useState(false);
    const USER_ID = useSelector(state => state.userAuthentication.userId);
    const USER_TOKEN = useSelector(state => state.userAuthentication.userToken);
    const [loading, setLoading] = useState(false);
    const [notidata, setNotidata] = useState([]);
    const [selectedNoti, setSelectedNoti] = useState({});
    const [open, setOpen] = React.useState(false);
    const [openNotiModal, setOpenNotiModal] = useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        getListNotifications();
    };

    useEffect(() => {
        getListNotifications();
        const interval = setInterval(getListNotifications, 1800000);
        return () => clearInterval(interval);
    }, [])

    const convertDateTime = (datestr) => {
        const utcDateStr = datestr;

        const utcDate = new Date(utcDateStr);

        // Convert to IST by setting the timezone offset
        const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30
        const istDate = new Date(utcDate.getTime() + istOffset);

        let hours = istDate.getUTCHours();
        const minutes = istDate.getUTCMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0)

        // Format time
        const formattedTime = `${String(hours).padStart(2, '0')} : ${String(minutes).padStart(2, '0')} ${ampm}`;

        // Format date in "dd mmm yyyy"
        const day = String(istDate.getUTCDate()).padStart(2, '0');
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[istDate.getUTCMonth()];
        const year = istDate.getUTCFullYear();
        const formattedDate = `${day} ${month} ${year}`;

        return (`${formattedTime}, ${formattedDate}`);
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const onClickBackButton = () => {
        history.goBack();
    }

    const renderBackButton = (btnActive) => {
        if (btnActive) {
            return <div className='backButton cursorPointer' onClick={backButtonActive ? onClickBackButton : null}>
                <svg xmlns="http://www.w3.org/2000/svg" width="8.402" height="14.805" viewBox="0 0 8.402 14.805">
                    <path id="Path_63354" data-name="Path 63354" d="M0,0,5.988,5.988,0,11.977" transform="translate(6.988 13.391) rotate(180)" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                </svg>
            </div>
        }
    }

    const renderHeaderName = () => {
        return headerName
    }

    // const handleNotiFicationIcon = () => {
    //     getListNotifications();
    // }

    const handleLogoutDropdown = () => {
        setOpenLogoutDrop(!openLogoutDrop);
    }

    const getListNotifications = () => {
        // setLoading(true);
        let param = `?page=${1}&limit=20&notification_seen=false&user_id=${USER_ID}`
        GetNotificationsList(param).then(response => {
            setNotidata(response.data.data)
        }).catch(error => {

        }).finally(() => {
            // setLoading(false);
        })
    }

    const handleLogout = () => {
        setLoading(true);
        let param = `?user_id=${USER_ID}`
        LogOutUser(param, USER_TOKEN).then(response => {
            if (response?.data?.statusCode === 200) {
                ToastSuccessService(response?.data?.message);
                history.push('/');
            } else if (response.data.statusCode === 400) {
                ToastErrorService(response.data.message);
            }
        }).catch(error => {
            if (error?.response?.data?.statusCode === 401) {
                history.push('/')
            }
            ToastErrorService(error?.response?.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleSidebarOpen = () => {
        dispatch(sidebarActions.setIsSidebarVisible({ type: true }));
    }
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }
    const randomBackgroundColor = () => {
        const colors = ['bg1', 'bg2', 'bg3', 'bg4', 'bg5', 'bg6', 'bg7', 'bg8', 'bg9'];
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        return randomColor;
    }

    const onClickNoti = (id) => {
        setOpenNotiModal(true);
        let param = `?id=${id}`
        GetNotificationsList(param, USER_TOKEN).then(response => {
            setSelectedNoti(response.data.data[0])
        }).catch(error => {

        }).finally(() => {
            setLoading(false);
        })
        let params = `?notification_id=${id}&notification_seen=true`;
        UpdateNotificationStatus(params, USER_TOKEN).then(res => {
        }).catch(error => {

        }).finally(() => { })
    }

    const onClickCreateNotification = () => {
        history.push('/app/createNotifications');
        setOpen(false);
        dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        let notidata = {
            notification: {
                title: '',
                body: ''
            },
            data: {
                emp_ids: []
            }
        }
        dispatch(notificationActions.setNotiFormData({ type: notidata }));
    }

    return (
        <div className=''>
            <div className='py-4 customHeader montserratFont d-flex'>
                <div className={`d-flex align-items-center`}>
                    <div className={`d-flex align-items-center mobileNone ${backButtonActive ? 'cursor-pointer' : ''}`} onClick={backButtonActive ? onClickBackButton : null}>
                        {renderBackButton(backButtonActive)}
                        <span className={`font32 textColorBlack text-capitalize ${renderHeaderName()}`}>
                            {renderHeaderName()}
                        </span>
                    </div>
                    <div className='d-flex align-items-center mobileBlock'>
                        <div className=''>LOGO</div>
                    </div>
                </div>
                <div className='d-flex justify-content-end align-items-center gap-3'>
                    {backButtonActive && < div >
                        {pageName === isSearchBtnActives ? '' :
                            <SearchBox />
                        }
                    </div>}
                    <div className='customDropdownCont'>
                        <div>
                            <div ref={anchorRef} className='bellIcon' onClick={handleToggle}>
                                <div>
                                    <img src={NotiFicationIcon} alt="" srcSet="" />
                                    {notidata?.length > 0 && <div className='red-dot'></div>}
                                </div>
                            </div>
                            <Popper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                placement="bottom-start"
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList
                                                    autoFocusItem={open}
                                                    id="composition-menu"
                                                    aria-labelledby="composition-button"
                                                    onKeyDown={handleListKeyDown}
                                                >
                                                    <div className='notification-list '>
                                                        <div className='noti-head'>
                                                            <div className='monteSerratFont fontSize14 textColor333'>
                                                                Notifications
                                                            </div>
                                                            <div className=''>
                                                                <CustomBlueButton customCreatePropsBtnClass={'customClsNameLogout'} title={<div className='d-flex align-items-center'>
                                                                    <span className='fontSize18 fontBold pr-13px'>+</span>
                                                                    <div>Create Notification</div>
                                                                </div>} onClick={onClickCreateNotification} />
                                                            </div>
                                                        </div>
                                                        {/* <div className='paddingTop14 notoSansFont textColorGray fontSize12'>Unread</div> */}
                                                        <div className='noti-content customScroll'>
                                                            {notidata.map(item => (<button className='pt-3 w-100' onClick={() => onClickNoti(item?._id)}>
                                                                <div className={`noti-detail ${randomBackgroundColor()}`}>
                                                                    <div className='pe-3'>
                                                                        <Stack direction="row" spacing={2}>
                                                                            <Avatar className='notoSansFont text-capitalize' {...stringAvatar(item?.user_name)} />
                                                                        </Stack>
                                                                    </div>
                                                                    <div className='fontSize12'>
                                                                        <div className='textColor333 pb-1 left-text'>{item?.notification_subject}</div>
                                                                        <div className='textColorGray'>{convertDateTime(item?.created_at)}</div>
                                                                    </div>
                                                                </div>
                                                            </button>))}
                                                        </div>
                                                    </div>
                                                    <div className='px-3 d-flex justify-content-end'>
                                                        <button className='viewMoreBtn fontSize12' onClick={() => { history.push('/app/notifications'); setOpen(false) }}>View All</button>
                                                    </div>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </div>
                    <div className='customDropdownCont' onClick={handleLogoutDropdown}>
                        <div className='bellIcon'>
                            <div>
                                <img src={ProfileIcon} alt="" srcSet="" />
                            </div>
                        </div>
                        <div className={`dropdownCont arrow-top ${openLogoutDrop ? 'active' : ''} `} >
                            <CustomBlueButton title={'Logout'} customCreatePropsBtnClass={'customClsNameLogout'} onClick={handleLogout} />
                        </div>
                    </div>
                    <div className='customDropdownCont mobileBlock'>
                        <div className='' style={{ cursor: 'pointer' }} onClick={handleSidebarOpen}>
                            <img src={HamburgerMenuRight} alt="" srcSet="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='customHeaderBredcum'>
                <div className={`d-flex align-items-center`}>
                    {renderBackButton(backButtonActive)}
                    <span className={`font32 textColorBlack text-capitalize ${renderHeaderName()}`}>
                        {renderHeaderName()}
                    </span>
                </div>
            </div>

            <CustomModal classes="customeProgressModal" open={openNotiModal} style={uploadedFileModalStyle} handleClose={null}
                content={<div>
                    <div className='d-flex align-items-center'>
                        <div className='montSerratFont fontSize18 textColorBlack position-relative d-flex w-100'>
                            <div>Notification Details</div>
                            <div className='uploadFileModalCloseIcon cursorPointer' onClick={() => { setOpenNotiModal(false); getListNotifications() }}>
                                <img src={CloseIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='pt-3 w-100' >
                        <div >
                            {/* <div className='pe-3'>
                                <Stack direction="row" spacing={2}>
                                    <Avatar className='notoSansFont text-capitalize' {...stringAvatar(selectedNoti?.user_name)} />
                                </Stack>
                            </div> */}
                            <div className=''>
                                <div className='textColor333 left-text fontSize16'>{selectedNoti?.notification_subject}</div>
                                <div className='textColorGray'>{convertDateTime(selectedNoti?.created_at)}</div>
                            </div>
                            <div className='fontSize18 pt-3'>{selectedNoti.notification_body}</div>
                        </div>
                    </div>
                </div>} />

            {loading && <Loader />}
        </div >
    );
}

export default Header