import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    brandId: '',
    viewBrandDetails: [],
    selectedTab: 0,
    selectedPage: 1,
    selectedPageForMatser: 1,
    viewBrandSelectedTabLabel: 'Brand Owner Details',
    tableFilters: {},
    selectedBrand: '',
    brandDropdownList: [],
    brandCreatedBy: '',
    createBrandOwnerDetails: {},
    createBrandDetails: {},
    createBrandChannelDetails: {},
    createBrandAddressesDetails: {},
    createBrandCompanyContactDetails: {},
    createBrandUploadedDocuments: [],
    stepActiveState: 0,
    stepProgress: 0,
    isEditFormVisible: true,
    selectedBrandLogo: '',
    defaultBrand: {},
    masterFilter: {},
    backActiveStep: false,
    startDateDisplay: null,
    endDateDisplay: null,
    startDate: null,
    endDate: null,
    defaultYear: {},
};

const brandManagementSlice = createSlice({
    name: 'brandManagement',
    initialState,
    reducers: {
        setBrandIs: (state, action) => {
            state.brandId = action.payload.type
        },
        setViewBrandDetails: (state, action) => {
            state.viewBrandDetails = action.payload.type
        },
        setViewBrandSelectedTabLabel: (state, action) => {
            state.viewBrandSelectedTabLabel = action.payload.type
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload.type
        },
        setSelectedPage: (state, action) => {
            state.selectedPage = action.payload.type
        },
        setSelectedPageForMatser: (state, action) => {
            state.selectedPageForMatser = action.payload.type
        },
        setTableFilters: (state, action) => {
            state.tableFilters = action.payload.type
        },
        setSelectedBrand: (state, action) => {
            state.selectedBrand = action.payload.type
        },
        setSelectedBrandLogo: (state, action) => {
            state.selectedBrandLogo = action.payload.type
        },
        setBrandDropdownList: (state, action) => {
            state.brandDropdownList = action.payload.type
        },
        setBrandCreatedBy: (state, action) => {
            state.brandCreatedBy = action.payload.type
        },
        setCreateBrandOwnerDetails: (state, action) => {
            state.createBrandOwnerDetails = action.payload.type
        },
        setCreateBrandDetails: (state, action) => {
            state.createBrandDetails = action.payload.type
        },
        setCreateBrandChannelDetails: (state, action) => {
            state.createBrandChannelDetails = action.payload.type
        },
        setCreateBrandAddressesDetails: (state, action) => {
            state.createBrandAddressesDetails = action.payload.type
        },
        setCreateBrandCompanyContactDetails: (state, action) => {
            state.createBrandCompanyContactDetails = action.payload.type
        },
        setcreateBrandUploadedDocuments: (state, action) => {
            state.createBrandUploadedDocuments = action.payload.type
        },
        setStepActiveState: (state, action) => {
            state.stepActiveState = action.payload.type
        },
        setStepProgress: (state, action) => {
            state.stepProgress = action.payload.type
        },
        setIsEditFormVisible: (state, action) => {
            state.isEditFormVisible = action.payload.type
        },
        setDefaultBrand: (state, action) => {
            state.defaultBrand = action.payload.type
        },
        setMasterFilter: (state, action) => {
            state.masterFilter = action.payload.type
        },
        setBackActiveStep: (state, action) => {
            state.backActiveStep = action.payload.type
        },
        setDefaultYear: (state, action) => {
            state.defaultYear = action.payload.type
        },
        setStartDateDisplay: (state, action) => {
            state.startDateDisplay = action.payload.type
        },
        setEndDateDisplay: (state, action) => {
            state.endDateDisplay = action.payload.type
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload.type
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload.type
        },
    }
});

export const brandManagementActions = brandManagementSlice.actions;
export default brandManagementSlice;
