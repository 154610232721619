import React, { useState, useEffect, useRef } from 'react'
import CustomFilterDropDown from '../../../ReUsableComponent/CustomFilterDropdown';
import moment from 'moment';
import ExportIcon from '../../../assets/svg/downloadIcon.svg';
import CustomTableWithFilter from '../../../ReUsableComponent/CustomTableWithFilter';
import CustomPagination from '../../../ReUsableComponent/Pagination';
import { PromotionDashboardTableHeader } from '../../TableHeaders';
import Loader from '../../../ReUsableComponent/Loader';
import CustomBlueButton from '../../../ReUsableComponent/CustomBlueButton';
import CalendarIcon from '../../../assets/svg/calendar_2_fill.svg';
import CustomDateRangePicker from '../../../ReUsableComponent/CustomDaterangePicker';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import './PromotionDashboard.css';
import { GetPromotionsList, UpdatePromotionsList, DownaloadPromotionList } from './../promotion.service';
import { ToastErrorService, ToastSuccessService } from '../../../ReUsableComponent/ToastNotification/ToastService';
import { promotionActions } from '../../../store/reducers/PromotionReducer';
import { saveAs } from 'file-saver';

// need to change Reducer
import { brandManagementActions } from '../../../store/reducers/BrandManagementReducer';
import { RefreshOutlined } from '@mui/icons-material';
import { userAuthActions } from '../../../store/reducers/UserAuthReducers';


const downloadType = [{ label: 'Report Download', value: 'Report' }];

const PromotionDashboard = () => {
    const gridRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const USER_TOKEN = useSelector((state) => state.userAuthentication.userToken);
    const selectedPage = useSelector(state => state.brandManagement.selectedPage);
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), (today.getMonth()));
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const convertDateStringToObj = (dateString) => {
        const dateInIST = moment.tz(dateString, 'Asia/Kolkata');
        const finalFormattedDate = new Date(dateInIST.format());
        return finalFormattedDate
    }
    // export State
    const [anchorElForExport, setAnchorElForExport] = useState(null);
    const openDownloadType = Boolean(anchorElForExport);

    // date Range Filter state
    const formatStartDate = useSelector(state => state.promotion.startDate);
    const formatEndDate = useSelector(state => state.promotion.endDate);

    const [startDate, setStartDate] = useState(formatStartDate ? convertDateStringToObj(formatStartDate) : firstDayOfMonth);
    const [endDate, setEndDate] = useState(formatEndDate ? convertDateStringToObj(formatEndDate) : lastDayOfMonth);
    const [startDateDisplay, setStartDateDisplay] = useState(startDate);
    const [endDateDisplay, setEndDateDisplay] = useState(endDate);
    const [selectedRange, setSelectedRange] = useState([startDate, endDate]);
    const [calendarOpen, setCalendarOpen] = useState(false);

    // table data state
    const [promotionTableData, setPromotionTableData] = useState([]);
    const [promotionGetAllData, setPromotionGetAllData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalPagesCount, setTotalPagesCount] = useState('');
    const tableFilterValue = useSelector((state) => state.promotion.filterModel);

    // Inital
    useEffect(() => {
        getPromotionAllList(startDate, endDate);
        dispatch(userAuthActions.setIsBackBtnActive({ type: false }));
    }, [selectedPage]);


    // Export Func
    const onClickExport = (event) => {
        setAnchorElForExport(event.currentTarget);
    }

    const handleExportClose = () => {
        setAnchorElForExport(null);
    }

    const onSelectDownloadUserPromotion = (event) => {
        let reqBody = {
            from_date: startDate,
            to_date: endDate,
            brand_name: tableFilterValue?.nameBrand ? tableFilterValue?.nameBrand?.filter : '',
            state: tableFilterValue?.state ? tableFilterValue?.state?.filter : '',
            region: tableFilterValue?.region ? tableFilterValue?.region?.filter : ''
        }
        downloadPromotionList(reqBody);
    }

    const downloadPromotionList = (reqBody) => {
        setLoading(true);
        DownaloadPromotionList(reqBody, USER_TOKEN).then(response => {
            const responseInUnit = new Uint8Array(response.data.buffer.data);
            const blob = new Blob([responseInUnit], { type: 'application/octet-stream' });
            saveAs(blob, response.data.filename);
            if (response?.data?.buffer?.statusCode === 400) {
                ToastErrorService(response?.data?.buffer?.message);
            } else {
                ToastSuccessService('Report downloaded successfully.');
            }
        }).catch(error => {
            ToastErrorService('Something went wrong, Please try again after sometime.');
            if (error.response.status === 401) {
                history.push('/')
            }
        }).finally(() => {
            setLoading(false);
        })
    }


    // Date Range Func
    const convertDate = (openingDate) => {
        let date = new Date(openingDate);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let dateString = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        return moment(dateString).format("DD MMM YYYY");
    }

    const openCalendar = () => {
        setCalendarOpen(prev => !prev)
    }

    const handleDateRangeSelect = (date) => {
        if (!startDate || (startDate && endDate)) {
            setStartDate(date);
            setEndDate('');
            setStartDateDisplay(date);
            setSelectedRange([]);
        } else if (startDate && !endDate && date >= startDate) {
            setEndDate(date);
            setEndDateDisplay(date);
            setSelectedRange([startDate, date]);
            setCalendarOpen(false);
            getPromotionAllList(startDate, date);
            dispatch(promotionActions.setStartDate({ type: startDate }));
            dispatch(promotionActions.setEndDate({ type: date }));
        } else if (startDate && !endDate && date < startDate) {
            setStartDate(date);
            setStartDateDisplay(date);
        }
    };

    // Create Form Open Func
    const onClickCreatePromotion = () => {

        dispatch(promotionActions.setStyleCodeType({
            type: {
                label: 'All',
                value: 'All'
            }
        }));
        history.push('/app/createpromotion');
    }

    // Promotion Table Func 
    const onGridReady = (params) => {
        params.api.setFilterModel(tableFilterValue);
        gridRef.current = params.api;
        gridRef.current.sizeColumnsToFit();
    };

    const changePromotionsStatus = (event) => {
        setLoading(true);
        let updatedStatus = !event.status;
        let param = `?id=${event.id}&status=${updatedStatus}`;
        let requestBody = {};
        UpdatePromotionsList(requestBody, param, USER_TOKEN).then(response => {
            if (response.data.statusCode === 200) {
                ToastSuccessService('Promotion status updated successfully.');
                getPromotionAllList(startDate, endDate);
            } else {
                ToastErrorService(response.data.message)
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                history.push('/')
            }
            ToastErrorService(error.response.data.message);
        }).finally(() => {
            setLoading(false);
        })
    }

    const onClickPromotionsEdit = (id) => {
        if (id) {
            const foundObject = promotionGetAllData?.find((item) => item?._id === id);
            history.push('/app/vieweditpromotion');
            dispatch(promotionActions.setIsEditFormVisible({ type: false }));
            dispatch(promotionActions.setPromotionId({ type: id }));
        }
    }

    const onClickPromotionsView = (id) => {
        if (id) {
            const foundObject = promotionGetAllData?.find((item) => item?._id === id);
            history.push('/app/vieweditpromotion');
            dispatch(promotionActions.setIsEditFormVisible({ type: true }));
            dispatch(promotionActions.setPromotionId({ type: id }));
            dispatch(userAuthActions.setIsBackBtnActive({ type: true }));
        }
    }

    // Pagination Func
    const handlePageChange = (pageValue) => {
        dispatch(brandManagementActions.setSelectedPage({ type: pageValue }));
    }


    const compareDate = (date) => {
        if (date > today) {
            return true
        } else if (date < today) {
            return false
        }
    }

    const getPromotionAllList = (start_date, end_date) => {
        setLoading(true);
        let param = `?page=${selectedPage}&limit=${20}&fromDate=${start_date}&toDate=${end_date}`
        GetPromotionsList(param, USER_TOKEN)
            .then(response => {
                let tableData = [];
                response?.data?.data?.map(item => {
                    let row = {
                        id: item?._id,
                        description: valueCheck(item?.description),
                        brand_data: valueCheck(item?.brand_data?.brand_name),
                        region: valueCheck(item?.region?.join(', ')),
                        state: valueCheck(item?.state?.join(', ')),
                        store_detail: valueCheck(item?.store_detail?.map(store => (store.store_trade_name && store.store_trade_name !== 'null') ? store.store_trade_name : store.store_name).join(', ')),
                        promotion_type_value: valueCheck(item?.promotion_type_value),
                        brandStatus: compareDate(new Date(item?.to_date)) ? 'Active' : 'Inactive',
                        status: compareDate(new Date(item?.from_date)),
                    }
                    tableData.push(row)
                })
                setPromotionTableData(tableData);
                setTotalPagesCount(response?.data?.totalRecords);
                setPromotionGetAllData(response?.data?.data);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    history.push('/')
                }
                ToastErrorService(error.response?.data?.message || 'An error occurred.');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const valueCheck = (value) => {
        if (value) {
            return value;
        } else {
            return '-';
        }
    }

    const resetDateRange = () => {
        setEndDate(lastDayOfMonth);
        setEndDateDisplay(lastDayOfMonth);
        setStartDate(firstDayOfMonth);
        setStartDateDisplay(firstDayOfMonth);
        setSelectedRange([firstDayOfMonth, lastDayOfMonth]);
        getPromotionAllList(firstDayOfMonth, lastDayOfMonth);
        dispatch(promotionActions.setStartDate({ type: firstDayOfMonth }));
        dispatch(promotionActions.setEndDate({ type: lastDayOfMonth }));
    }

    const onFilterChanged = (event) => {
        dispatch(promotionActions.setFilterModel({ type: event.api.getFilterModel() }));
    };

    const onClickReset = () => {
        const gridApi = gridRef.current;
        gridApi.setFilterModel(null); // Clears all the filters
        gridApi.onFilterChanged();    // Apply the changes
    }

    return (
        <div className='dashboard-container'>
            <div className='d-flex justify-content-between filter-cont'>
                <div className='d-flex align-items-center first-filter'>
                </div>
                <div className='d-flex align-items-center'>
                    <button onClick={onClickReset} className='me-2 reset_filters_button'>
                        <span className='me-1 reset_filters_text'>Reset Filters</span>
                        <RefreshOutlined className='RefreshOutlined' />
                    </button>
                    <div className='me-3 importExportFile' title='Download' onClick={onClickExport}>
                        <img src={ExportIcon} alt="" />
                    </div>
                    <CustomFilterDropDown data={downloadType} handleClose={handleExportClose} open={openDownloadType} anchorEl={anchorElForExport} onChange={onSelectDownloadUserPromotion} category={'Category'} />

                    <div className='lastFilterSubCont position-relative'>
                        <div className='d-flex dateRangeFilter cursorPointer align-items-center justify-content-between'
                            onClick={openCalendar} style={{ minWidth: '170px' }} >
                            <img src={CalendarIcon} alt="" style={{ marginRight: '10px' }} />
                            <div>{startDateDisplay && endDateDisplay ? `${convertDate(startDateDisplay)} - ${convertDate(endDateDisplay)}` : 'Select date range'}</div>
                        </div>
                        <div className=''>
                            <CustomDateRangePicker startDate={startDate} endDate={endDate} calendarOpen={calendarOpen} selectedRange={selectedRange} handleSelect={(date) => handleDateRangeSelect(date)}
                                closeOnOutSide={() => setCalendarOpen(false)} />
                        </div>
                    </div>
                    <button onClick={resetDateRange} className='me-2'>
                        <RefreshOutlined style={{ fill: '#4169E1' }} />
                    </button>

                    <div>
                        <CustomBlueButton title={<div className='d-flex align-items-center'>
                            <span className='fontSize18 fontBold pr-13px'>+</span>
                            <div>Create Promotion</div>
                        </div>} onClick={onClickCreatePromotion} />
                    </div>
                </div>
            </div>


            <div className='paddingTop24'>
                <div className='tableContainer'>
                    <CustomTableWithFilter column={PromotionDashboardTableHeader} totalRow={() => { }} onFilterChanged={onFilterChanged} tableRowData={promotionTableData} onGridReady={onGridReady} handleActiveToggle={changePromotionsStatus} onClickEdit={onClickPromotionsEdit} onClickView={onClickPromotionsView} customeHeightClass={'tableCustomeHeightClass'} />
                </div>
                <div className='paddingTop16 d-flex justify-content-end customPagination'>
                    <CustomPagination onClick={handlePageChange} activePage={selectedPage} pageSize={20} currentPage={selectedPage} totalItems={totalPagesCount} />
                </div>
            </div>

            {/* Loader */}
            {loading && <Loader />}
        </div>
    )
}

export default PromotionDashboard